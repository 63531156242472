import { observer } from 'mobx-react-lite';
import useRootStore from '../../../../Shared/Hooks/useRootStore';
import UnreadMessages from '../../../../Shared/Components/UnreadMessagesIcon';
import {
	DataCollectionProjectPreview,
	DataCollectionProjectReport,
	DataCollectionStatusEnum,
	ReportType,
} from '../../../../Models/API/DataCollection/project-preview';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../../Routes';
import ScrollableList from '../../../../Shared/ScrollableList/index.style';
import { IC_DOC_PDF, IC_DOC_PDF_GREY, IC_INFO_PRIMARY, IC_INFO_PRIMARY_LIGHT } from '../../../../Assets';
import ProjectStatus, { ProjectStatusList } from '../ProjectStatus';
import Image from '../../../../Shared/Components/Image';
import { getDataCollectionReports, getDataCollectionStatus, getStatusTitle } from '../../helpers/utils';
import Flex from '../../../../Shared/Components/Layout/Flex';
import { css } from '@emotion/css';
import appConfig from '../../../../config/config';
import classNames from 'classnames';

const Style = css({
	label: 'ProjectsList',

	'&__project-name': {
		'&:hover': {
			transition: 'all .2s',
			cursor: 'pointer',
			color: appConfig.style.colors.color1,
			textDecoration: 'underline',
		},
	},
});

const ProjectsList = () => {
	const { dataCollectionStore } = useRootStore();
	const history = useHistory();

	const onNavToProject = (project: DataCollectionProjectPreview) => {
		dataCollectionStore.valuationProjectId = project.valuationProjectId;
		// dataCollectionStore.currentStatus = getDataCollectionStatus(project.status).currentStatus;
		history.push(`${Routes.dataCollection.form}/${project.valuationProjectId}/0`);
	};

	const areProjectsExists = !!dataCollectionStore.projects?.length;

	const onPreviewDocument = async (dataCollectionId: string, documentId: string) => {
		const res = await dataCollectionStore.getDocument(dataCollectionId, documentId);
		console.log('TCL: onPreviewDocument -> res', res);
	};

	return (
		<ScrollableList.Container className={Style}>
			<ScrollableList.Row isHeader>
				<ScrollableList.Cell wide>Project Name</ScrollableList.Cell>
				<ScrollableList.Cell>Data collection</ScrollableList.Cell>
				<ScrollableList.Cell data-col-type="status">Project status</ScrollableList.Cell>
				<ScrollableList.Cell data-col-type="drafts">Drafts</ScrollableList.Cell>
				<ScrollableList.Cell data-col-type="final">Final Report</ScrollableList.Cell>
			</ScrollableList.Row>
			<ScrollableList.Scroll>
				{areProjectsExists ? (
					dataCollectionStore.projects?.map((project) => {
						const reports = getDataCollectionReports(project.reports);
						const { currentStatus, statusList } = getDataCollectionStatus(project.status);
						return (
							<ScrollableList.Row key={project.valuationProjectId}>
								<ScrollableList.Cell wide onClick={() => onNavToProject(project)} className={classNames('pointer', `${Style}__project-name`)}>
									{project.projectName}
								</ScrollableList.Cell>
								<ScrollableList.Cell>
									<UnreadMessages count={project.unreadMessages} onClick={() => onNavToProject(project)} width="3rem" />
								</ScrollableList.Cell>
								<ScrollableList.Cell data-col-type="status">
									<span style={{ marginRight: '1rem' }}>{getStatusTitle(currentStatus)}</span>
									<Image
										pointer
										src={IC_INFO_PRIMARY_LIGHT}
										srcHover={IC_INFO_PRIMARY}
										tooltip={<ProjectStatus statusList={statusList} currentStatus={currentStatus} />}
										tooltipPlacement="top"
									/>
								</ScrollableList.Cell>
								<ScrollableList.Cell data-col-type="drafts">
									<Flex justify="start" gap="1.2rem">
										{reports.drafts?.map((draft) => (
											<Image
												src={IC_DOC_PDF_GREY}
												key={draft.id}
												width="2.8rem"
												tooltip="Preview"
												onClick={() => onPreviewDocument(draft.valuationProjectId, draft.id)}
											/>
										))}
									</Flex>
								</ScrollableList.Cell>
								<ScrollableList.Cell data-col-type="final">
									{!!reports.final?.id && <Image src={IC_DOC_PDF} width="2.8rem" tooltip="Preview" />}
								</ScrollableList.Cell>
							</ScrollableList.Row>
						);
					})
				) : (
					<ScrollableList.Row>No data to display</ScrollableList.Row>
				)}
			</ScrollableList.Scroll>
		</ScrollableList.Container>
	);
};

export default observer(ProjectsList);
