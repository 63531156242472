import Flex from '../../../../Shared/Components/Layout/Flex';
import { SelectedPlanStyle } from './SelectedPlan.Style';
import useRootStore from '../../../../Shared/Hooks/useRootStore';
import Image from '../../../../Shared/Components/Image';
import { IC_PAYMENT_PLAN_CHECK, IMG_PAYMENT_CHECKOUT_ALTSHARE_LOGO, STAKEHOLDERS } from '../../../../Assets';
import { observer } from 'mobx-react-lite';
import { getPlanData } from '../../Pricing/utils/utils';
import Tooltip from '../../../../Shared/Components/Tooltip';
import { formatNumber, isNullOrUndefined } from '../../../../Shared/Utilities';
import React from 'react';
import { PreviewInvoice } from '../../../../Models/App/PaymentModels';

type Props = {
	invoice?: PreviewInvoice;
};

const SelectedPlan = ({ invoice }: Props) => {
	const { paymentStore } = useRootStore();

	const plan = getPlanData(paymentStore.selectPlanDetails.code);

	return (
		<div className={SelectedPlanStyle}>
			<Flex className="topContainer">
				<Image className="topContainer__logo" src={plan.img} alt="altshare logo" />
				<span className="topContainer__title">{paymentStore.selectedPlan.name} Plan</span>
				<Flex className="priceContainer">
					<span className="priceContainer__price">${paymentStore.selectedPlan.price}</span>
					<span className="priceContainer__length">/month</span>
				</Flex>
				<span className="topContainer__subTitle">Annual subscription</span>
				<span className="topContainer__subTitle comment">Excluding sales taxes</span>
			</Flex>
			<hr className="divider" />
			<Flex className="bottomContainer">
				<div className="stakeholdersContainer">
					<Image className="stakeholdersContainer__icon" src={STAKEHOLDERS} alt="stakeholders" />
					<span>{`${paymentStore.selectPlanDetails.maxNumOfStakeholders} stakeholders included`}</span>
					<Tooltip className="stakeholdersContainer__tooltip" title={'Any holder of shares, options, RSU, warrants, SAFE and convertible note'} />
				</div>
				<span className="bottomContainer__title">Plan details</span>
				<span className="bottomContainer__subTitle">{plan.title}</span>
				{plan.features.map((feature, i) =>
					feature.custom ? (
						<React.Fragment key={i}>{feature.custom()}</React.Fragment>
					) : (
						<Flex key={i} className="featureContainer" flex={0}>
							<Image src={IC_PAYMENT_PLAN_CHECK} alt="check" className="featureContainer__icon" />
							<span className="featureContainer__text">{feature.label}</span>
							{!isNullOrUndefined(feature.tooltip) && <Tooltip className="featureContainer__tooltip" title={feature.tooltip} />}
						</Flex>
					)
				)}
			</Flex>
			{!!invoice && (
				<>
					<hr className="divider" />
					<div className="invoiceContainer">
						<span className="invoiceContainer__title">Payment summary</span>
						<div className="invoiceContainer__paymentsContiner">
							{invoice.summary.map((payment) => (
								<div className="invoiceContainer__payment" key={payment.description}>
									<span className="desc">{payment.description}</span>
									<span className="amount">
										{payment.amount < 0 ? `-$${formatNumber(Math.abs(payment.amount))}` : `$${formatNumber(payment.amount)}`}
									</span>
								</div>
							))}
							<hr className="divider clean" />
							<div className="invoiceContainer__payment total">
								<span className="desc">Total</span>
								<span className="amount">${formatNumber(invoice.summary.reduce((acc, payment) => acc + payment.amount, 0))}</span>
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default observer(SelectedPlan);
