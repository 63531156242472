import { css } from '@emotion/css';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { createRef, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IC_ATTACHMENT, IC_DOC_PDF, IC_DOC_PDF_GREY, IC_EDIT2, IC_EYE2, IC_INFO_PRIMARY, IC_INFO_PRIMARY_LIGHT } from '../../../../Assets';
import appConfig from '../../../../config/config';
import { ValuationFormSteps } from '../../../../Models/API/Valuation/steps-enum';
import { ValuationProject } from '../../../../Models/API/Waterfall/IForm';
import { Routes } from '../../../../Routes';
import Image from '../../../../Shared/Components/Image';
import Flex from '../../../../Shared/Components/Layout/Flex';
import OverflowText from '../../../../Shared/Components/OverflowText';
import UnreadMessages from '../../../../Shared/Components/UnreadMessagesIcon';
import useRootStore from '../../../../Shared/Hooks/useRootStore';
import ScrollableList from '../../../../Shared/ScrollableList/index.style';
import { convertBase64ToBlob, fileToBase64, isNullOrUndefined, isNumber } from '../../../../Shared/Utilities';
import ProjectStatus from '../../../DataCollection/Components/ProjectStatus';
import { getDataCollectionReports, getDataCollectionStatus, getStatusTitle } from '../../../DataCollection/helpers/utils';
import { sortProjectsListWithChildren } from '../../utils';
import StyledSelect from '../../../../Shared/Components/Select/Select.Style';
import { ClickAwayListener, Fade } from '@mui/material';
import Spinner from '../../../../Shared/Components/Spinner/Spinner';
import ReactDOM from 'react-dom';
import { ReportType } from '../../../../Models/API/DataCollection/project-preview';
import { acceptByType } from '../../../../Shared/Components/UploadFile';
import { UploadReportPayload } from '../../../../Models/API/DataCollection/upload-report-payload';
import useGeneralModal from '../../../../Shared/Hooks/useGeneralModal';
import { toJS } from 'mobx';
import { FourONinePermissions } from '../../../../Models/API/UsersAndPermissions/permissions-enum';

const Style = css({
	label: 'ProjectsList',

	'&__row': {
		'&.parent': {
			'&:hover': {
				'.valuator-name': {
					cursor: 'pointer',
					color: appConfig.style.colors.color1,
					textDecoration: 'underline',
				},
			},
		},
	},
	'&__project-name': {
		'&:hover': {
			transition: 'all .2s',
			cursor: 'pointer',
			color: appConfig.style.colors.color1,
			textDecoration: 'underline',
		},
	},
	'.selected': {
		color: appConfig.style.colors.color1,
	},
});

const ProjectsList = () => {
	const { valuationStore, appState, dataCollectionStore } = useRootStore();
	const history = useHistory();
	const headerRef = useRef<HTMLDivElement>(null);
	const [visibleMenuIdx, setVisibleMenuIdx] = useState<number | null>(null);
	const [menuPosition, setMenuPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });
	const containerRef = useRef<HTMLDivElement>(null);
	const inputRef = createRef<HTMLInputElement>();
	const attachPayloadRef = useRef<UploadReportPayload>({} as UploadReportPayload);
	const { showErrorModal } = useGeneralModal();
	const [isLoading, setIsLoading] = useState<Record<string, boolean>>({});

	useEffect(() => {
		if ((valuationStore.projects || []).length < 4 || !headerRef.current) return;
		headerRef.current.style.paddingRight = '6px';
	}, [valuationStore.projects]);

	useEffect(() => {
		if (containerRef.current === null) return;
		const onScrollHandler = () => {
			setVisibleMenuIdx(null);
		};
		containerRef.current.addEventListener('scroll', onScrollHandler);
		return () => {
			containerRef.current?.removeEventListener('scroll', onScrollHandler, true);
		};
	}, [containerRef.current]);

	const toggleMenu = (id: number, event: React.MouseEvent<HTMLDivElement>) => {
		if (visibleMenuIdx === id) {
			setVisibleMenuIdx(null);
		} else {
			const rect = (event.target as HTMLElement).getBoundingClientRect();
			setMenuPosition({
				top: rect.bottom + window.scrollY + 10,
				left: rect.left + window.scrollX,
			});
			setVisibleMenuIdx(id);
		}
	};

	const onNavToClientProject = (project: ValuationProject) => {
		if (project.valuationProjectId === null) return;
		dataCollectionStore.valuationProjectId = project.valuationProjectId;
		// dataCollectionStore.currentStatus = getDataCollectionStatus(project.status).currentStatus;
		history.push(`${Routes.dataCollection.form}/${project.valuationProjectId}/0`);
	};

	const onOpen = async (project: ValuationProject, maxStep: ValuationFormSteps = ValuationFormSteps.preferencesTerms) => {
		if (appState.isLoading || project.waterfallId === null) return;
		const step = Math.min(project.unfulfillmentStep - 1, maxStep);
		appState.isLoading = true;
		try {
			valuationStore.setLastAllowedStep(project.unfulfillmentStep);
			const path = `${Routes.dataCollection.valuation}/${project.waterfallId}${
				step ? `/${step}` : valuationStore.lastAllowedStep ? `/${valuationStore.lastAllowedStep}` : ''
			}`;
			valuationStore.setProjectName(project.projectName);
			valuationStore.isNewProject = false;
			// to check if owner
			valuationStore.setProjectViewMode(!project.isOwner);
			// setShowVersionsDialog(false);
			history.push(path);
		} catch (error: any) {
			console.log(error);
		}
		appState.isLoading = false;
	};

	const onUploadDocument = (valuationProjectId: string | null, type: ReportType) => {
		if (valuationProjectId === null || isLoading[valuationProjectId]) return;
		inputRef?.current?.click();
		attachPayloadRef.current.reportType = type;
		attachPayloadRef.current.valuationProjectId = valuationProjectId;
		attachPayloadRef.current.fileBase64 = '';
	};

	const onFileUploadHandler = async (file: File | undefined) => {
		if (isNullOrUndefined(file)) return;

		const isFileSupported = acceptByType.pdf.includes(file.type);
		if (!isFileSupported) return showErrorModal('File not supported, please upload a PDF file only.');

		const fileBase64 = await fileToBase64(file);
		if (fileBase64 === undefined) return;
		attachPayloadRef.current.fileBase64 = fileBase64;
		setIsLoading((prev) => ({ ...prev, [attachPayloadRef.current.valuationProjectId]: true }));
		await valuationStore.uploadReport(attachPayloadRef.current);
		setIsLoading((prev) => ({ ...prev, [attachPayloadRef.current.valuationProjectId]: false }));
	};

	const areProjectsExists = !!valuationStore.projects?.length;
	const sortedProject = useMemo(() => sortProjectsListWithChildren(valuationStore.projects || []), [valuationStore.projects]);

	return (
		<>
			<ScrollableList.Container className={Style}>
				<ScrollableList.Row isHeader ref={headerRef}>
					<ScrollableList.Cell style={{ minWidth: '20rem' }}>Project Name</ScrollableList.Cell>
					<ScrollableList.Cell>Data collection</ScrollableList.Cell>
					<ScrollableList.Cell data-col-type="status">Project status</ScrollableList.Cell>
					<ScrollableList.Cell>Valuator</ScrollableList.Cell>
					<ScrollableList.Cell data-col-type="model">409A Model</ScrollableList.Cell>
					<ScrollableList.Cell data-col-type="drafts">Drafts</ScrollableList.Cell>
					<ScrollableList.Cell data-col-type="final">Final Report</ScrollableList.Cell>
				</ScrollableList.Row>
				<ScrollableList.Scroll ref={containerRef}>
					{isNullOrUndefined(areProjectsExists) ? (
						<Spinner />
					) : areProjectsExists ? (
						sortedProject?.map((project, idx) => {
							const reports = getDataCollectionReports(project.reports || []);
							const { currentStatus, statusList } = getDataCollectionStatus(project.status);
							const isChild = isNumber(project.parentWaterfallId);
							const isParent =
								(sortedProject[idx + 1]?.parentWaterfallId === project.waterfallId ||
									isNullOrUndefined(sortedProject[idx + 1]?.parentWaterfallId)) &&
								project.parentWaterfallId === null;
							return (
								<ScrollableList.Row
									isChild={isChild}
									isParent={isParent}
									key={idx}
									className={classNames(`${Style}__row`, { parent: isParent })}
								>
									<ScrollableList.Cell
										onClick={() => onNavToClientProject(project)}
										className={classNames('pointer', `${Style}__project-name`)}
										style={{ minWidth: '20rem' }}
									>
										<OverflowText>{project.projectName}</OverflowText>
									</ScrollableList.Cell>
									<ScrollableList.Cell>
										{isParent && (
											<UnreadMessages count={project.unreadMessages} onClick={() => onNavToClientProject(project)} width="3rem" />
										)}
									</ScrollableList.Cell>
									<ScrollableList.Cell data-col-type="status">
										{isParent && (
											<>
												<span style={{ marginRight: '1rem' }}>{getStatusTitle(currentStatus)}</span>
												<Image
													pointer
													src={IC_INFO_PRIMARY_LIGHT}
													srcHover={IC_INFO_PRIMARY}
													tooltip={<ProjectStatus statusList={statusList} currentStatus={currentStatus} />}
													tooltipPlacement="top"
												/>
											</>
										)}
									</ScrollableList.Cell>
									<ScrollableList.Cell
										data-col-type="valuator-name"
										onClick={(e) => valuationStore.currentRole === FourONinePermissions.Chief && toggleMenu(idx, e)}
									>
										{isParent && (
											<>
												<OverflowText
													className={classNames({ 'valuator-name': valuationStore.currentRole === FourONinePermissions.Chief })}
												>
													{project.valuatorFullName}
												</OverflowText>
												{visibleMenuIdx === idx &&
													ReactDOM.createPortal(
														<div>
															<ClickAwayListener onClickAway={() => setVisibleMenuIdx(null)}>
																<StyledSelect.OptionsContainer
																	style={{
																		top: menuPosition.top,
																		left: menuPosition.left,
																		maxWidth: '15rem',
																	}}
																>
																	{valuationStore.economicUsers.map((user) => (
																		<StyledSelect.Option
																			className={classNames({ selected: user.defaultName === project.valuatorFullName })}
																			onClick={() =>
																				project.valuationProjectId &&
																				valuationStore.changeProjectOwner(user.userId, project.valuationProjectId)
																			}
																			key={user.userId}
																		>
																			<span className="text-ellipsis">{user.defaultName}</span>
																		</StyledSelect.Option>
																	))}
																</StyledSelect.OptionsContainer>
															</ClickAwayListener>
														</div>,
														document.body
													)}
											</>
										)}
									</ScrollableList.Cell>
									<ScrollableList.Cell className="flex align-center gap-2" data-col-type="model">
										{isNumber(project.waterfallId) && (
											<>
												<Image
													src={IC_EYE2}
													height="1.3rem"
													className="pointer"
													onClick={() => onOpen(project, ValuationFormSteps.summary)}
													tooltip="View"
												/>
												<Image
													src={IC_EDIT2}
													height="1.6rem"
													className="pointer"
													onClick={() => onOpen(project, ValuationFormSteps.preferencesTerms)}
													tooltip="Edit"
												/>
											</>
										)}
									</ScrollableList.Cell>
									<ScrollableList.Cell data-col-type="drafts">
										{/* {reports.drafts?.map((draft) => (
										<div>{draft.id}</div>
									))} */}
										{isParent && (
											<Flex justify="start" gap="1.2rem">
												{reports.drafts?.map((draft) => (
													<Image src={IC_DOC_PDF_GREY} width="2.8rem" tooltip="Preview" key={draft.id} />
												))}

												{project.valuationProjectId !== null && (
													<Image
														src={IC_ATTACHMENT}
														width="2.8rem"
														tooltip="Attach"
														onClick={() => onUploadDocument(project.valuationProjectId, ReportType.Draft)}
														className={classNames({ disabled: isLoading[project.valuationProjectId] })}
													/>
												)}
											</Flex>
										)}
									</ScrollableList.Cell>
									<ScrollableList.Cell data-col-type="final">
										{isParent && (
											<Flex justify="start" gap="1.2rem">
												{!!reports.final?.id && <Image src={IC_DOC_PDF} width="2.8rem" tooltip="Preview" />}
												{project.valuationProjectId !== null && (
													<Image
														src={IC_ATTACHMENT}
														width="2.8rem"
														tooltip="Attach"
														onClick={() => onUploadDocument(project.valuationProjectId, ReportType.Final)}
														className={classNames({ disabled: isLoading[project.valuationProjectId] })}
													/>
												)}
											</Flex>
										)}
									</ScrollableList.Cell>
								</ScrollableList.Row>
							);
						})
					) : (
						<ScrollableList.Row>No data to display</ScrollableList.Row>
					)}
				</ScrollableList.Scroll>
			</ScrollableList.Container>
			<input
				ref={inputRef}
				id="fileUpload"
				className="box__file"
				hidden
				type="file"
				data-multiple-caption="{count} files selected"
				onChange={(e) => onFileUploadHandler(e.target.files?.[0])}
				multiple={false}
				accept={acceptByType.pdf}
			/>
		</>
	);
};

export default observer(ProjectsList);

const testProjects: ValuationProject[] = [
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		isOwner: false,
		isSummary: false,
		parentWaterfallId: null,
		projectName: 'Test_1733814239916',
		summaryStatus: null,
		unfulfillmentStep: 0,
		unreadMessages: 0,
		valuationProjectId: 'f9cdcdf9-7845-4367-9326-7d5833c5da55',
		valuatorContactId: 14303,
		valuatorFullName: 'yoav elitsedek',
		waterfallId: 1,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		isOwner: false,
		isSummary: false,
		parentWaterfallId: null,
		projectName: 'Test_1733926198887',
		summaryStatus: null,
		unfulfillmentStep: 0,
		unreadMessages: 0,
		valuationProjectId: '6ecf8ea3-2f65-4eff-9db5-d546dfd06249',
		valuatorContactId: 14303,
		valuatorFullName: 'yoav elitsedek',
		waterfallId: 2,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		isOwner: false,
		isSummary: false,
		parentWaterfallId: null,
		projectName: 'Test_1733907984634',
		summaryStatus: null,
		unfulfillmentStep: 0,
		unreadMessages: 0,
		valuationProjectId: 'e5d15876-6779-4cd1-a9a9-e4bb93edc363',
		valuatorContactId: 14303,
		valuatorFullName: 'yoav elitsedek',
		waterfallId: 3,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		isOwner: false,
		isSummary: false,
		parentWaterfallId: null,
		projectName: 'Test_1733815628007',
		summaryStatus: null,
		unfulfillmentStep: 0,
		unreadMessages: 0,
		valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
		valuatorContactId: 14303,
		valuatorFullName: 'yoav elitsedek',
		waterfallId: 4,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 2002,
		parentWaterfallId: 4,
		valuationProjectId: null,
		isOwner: true,
		isSummary: true,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'TEST DLOM1',
		unfulfillmentStep: 7,
		summaryStatus: {
			timeStamp: 0,
			status: 3,
			waterfallId: 2002,
			estimatedTimeDTO: {
				estimatedTime: 20000,
				extraTime: 50000,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1957,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: true,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 1 - Tsachi',
		unfulfillmentStep: 7,
		summaryStatus: {
			timeStamp: 0,
			status: 3,
			waterfallId: 1957,
			estimatedTimeDTO: {
				estimatedTime: 20000,
				extraTime: 120000,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1919,
		parentWaterfallId: 4,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'T',
		unfulfillmentStep: 1,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1919,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1916,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'asdasd',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1916,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1900,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 179-1',
		unfulfillmentStep: 1,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1900,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1878,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'adsdasadsadsdas',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1878,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1901,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'test 179-2',
		unfulfillmentStep: 1,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1901,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1902,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'TEST GGGOOO',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1902,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1914,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 229',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1914,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1915,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 239-1',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1915,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1917,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 269-1',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1917,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
		unreadMessages: 0,
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1897,
		parentWaterfallId: 2,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'testtt',
		unfulfillmentStep: 3,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1897,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1918,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 299-1',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1918,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1920,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 299-2',
		unfulfillmentStep: 1,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1920,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1921,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 29-2',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1921,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1922,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 309-1',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1922,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1923,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 309-2',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1923,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1924,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 309-3',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1924,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1925,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'sss',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1925,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1926,
		parentWaterfallId: 1925,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'TEST 0110',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1926,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1928,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 0110-1',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1928,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1929,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 0610-1',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1929,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1930,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 0710-1',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1930,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1931,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 0710-2',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1931,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1932,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 0810-1',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1932,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1933,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 0810-2',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1933,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1934,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 0810-3',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1934,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1935,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 0410-2024',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1935,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1936,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 0810-5',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1936,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1937,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 0810-7',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1937,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1938,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 0910-1',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1938,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1939,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 0910-2',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1939,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1940,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 09-10-3',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1940,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1941,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 0910-4',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1941,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1942,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'New 0910-6',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1942,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1943,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 0910-9',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1943,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1946,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 1010-1',
		unfulfillmentStep: 3,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1946,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1948,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 1410-1',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1948,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1949,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'TEST 121',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1949,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1950,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'asdasd222',
		unfulfillmentStep: 1,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1950,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1951,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 1510-1',
		unfulfillmentStep: 4,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1951,
			estimatedTimeDTO: {
				estimatedTime: 20000,
				extraTime: 50000,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1952,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 1510-2',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1952,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1953,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 1510-3',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1953,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1955,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test for refi',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1955,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1958,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 2 - tsachi',
		unfulfillmentStep: 6,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1958,
			estimatedTimeDTO: {
				estimatedTime: 20000,
				extraTime: 120000,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1959,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: true,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'test apple',
		unfulfillmentStep: 7,
		summaryStatus: {
			timeStamp: 0,
			status: 3,
			waterfallId: 1959,
			estimatedTimeDTO: {
				estimatedTime: 20000,
				extraTime: 50000,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1965,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: true,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'new daniel 1',
		unfulfillmentStep: 7,
		summaryStatus: {
			timeStamp: 0,
			status: 3,
			waterfallId: 1965,
			estimatedTimeDTO: {
				estimatedTime: 30000,
				extraTime: 60000,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1966,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test ',
		unfulfillmentStep: 5,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1966,
			estimatedTimeDTO: {
				estimatedTime: 20000,
				extraTime: 50000,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1967,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: true,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 0611-1',
		unfulfillmentStep: 7,
		summaryStatus: {
			timeStamp: 0,
			status: 3,
			waterfallId: 1967,
			estimatedTimeDTO: {
				estimatedTime: 20000,
				extraTime: 120000,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1927,
		parentWaterfallId: 1925,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 101',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1927,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1968,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: true,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Tsachik',
		unfulfillmentStep: 7,
		summaryStatus: {
			timeStamp: 0,
			status: 3,
			waterfallId: 1968,
			estimatedTimeDTO: {
				estimatedTime: 20000,
				extraTime: 120000,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1969,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test ref1',
		unfulfillmentStep: 3,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1969,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1970,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test ref14111',
		unfulfillmentStep: 7,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1970,
			estimatedTimeDTO: {
				estimatedTime: 20000,
				extraTime: 50000,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1974,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test ref1411-2',
		unfulfillmentStep: 5,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1974,
			estimatedTimeDTO: {
				estimatedTime: 20000,
				extraTime: 50000,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1975,
		parentWaterfallId: 1,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test ref1411-3',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1975,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1976,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: true,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'asdasdasd',
		unfulfillmentStep: 7,
		summaryStatus: {
			timeStamp: 0,
			status: 3,
			waterfallId: 1976,
			estimatedTimeDTO: {
				estimatedTime: 30000,
				extraTime: 60000,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1977,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test fin1711-1',
		unfulfillmentStep: 7,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1977,
			estimatedTimeDTO: {
				estimatedTime: 30000,
				extraTime: 60000,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1978,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: true,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test bugs1811-1',
		unfulfillmentStep: 7,
		summaryStatus: {
			timeStamp: 0,
			status: 3,
			waterfallId: 1978,
			estimatedTimeDTO: {
				estimatedTime: 1200000,
				extraTime: 1200000,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1979,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test peers 1911-1',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1979,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1980,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test peers 1911-2',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1980,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1981,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test peers 1911-3',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1981,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1982,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test peers 1911-4',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1982,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1983,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test peers 1911-5',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1983,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1984,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test peers 1911-6',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1984,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1986,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test peers 1911-7',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1986,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1987,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: true,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 2011-1',
		unfulfillmentStep: 7,
		summaryStatus: {
			timeStamp: 0,
			status: 3,
			waterfallId: 1987,
			estimatedTimeDTO: {
				estimatedTime: 30000,
				extraTime: 60000,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1989,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: true,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test hadas1',
		unfulfillmentStep: 7,
		summaryStatus: {
			timeStamp: 0,
			status: 3,
			waterfallId: 1989,
			estimatedTimeDTO: {
				estimatedTime: 30000,
				extraTime: 60000,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1990,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: true,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'test222',
		unfulfillmentStep: 7,
		summaryStatus: {
			timeStamp: 0,
			status: 3,
			waterfallId: 1990,
			estimatedTimeDTO: {
				estimatedTime: 20000,
				extraTime: 120000,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1991,
		parentWaterfallId: 1989,
		valuationProjectId: null,
		isOwner: true,
		isSummary: true,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'yoav hadas1',
		unfulfillmentStep: 7,
		summaryStatus: {
			timeStamp: 0,
			status: 3,
			waterfallId: 1991,
			estimatedTimeDTO: {
				estimatedTime: 20000,
				extraTime: 120000,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1992,
		parentWaterfallId: 1989,
		valuationProjectId: null,
		isOwner: true,
		isSummary: true,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'tset duplicated',
		unfulfillmentStep: 7,
		summaryStatus: {
			timeStamp: 0,
			status: 3,
			waterfallId: 1992,
			estimatedTimeDTO: {
				estimatedTime: 30000,
				extraTime: 60000,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1993,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: true,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 2611-1',
		unfulfillmentStep: 7,
		summaryStatus: {
			timeStamp: 0,
			status: 3,
			waterfallId: 1993,
			estimatedTimeDTO: {
				estimatedTime: 20000,
				extraTime: 120000,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1994,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test many1',
		unfulfillmentStep: 4,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1994,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1995,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: true,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test DLOM',
		unfulfillmentStep: 7,
		summaryStatus: {
			timeStamp: 0,
			status: 3,
			waterfallId: 1995,
			estimatedTimeDTO: {
				estimatedTime: 20000,
				extraTime: 120000,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1996,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test big1',
		unfulfillmentStep: 5,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1996,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1998,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'zak12',
		unfulfillmentStep: 3,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1998,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 1999,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Tset3455',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 1999,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
	{
		dateModified: new Date('2024-12-11T15:02:07.770Z'),
		waterfallId: 2000,
		parentWaterfallId: null,
		valuationProjectId: null,
		isOwner: true,
		isSummary: false,
		valuatorFullName: 'daniel  yona',
		valuatorContactId: 14305,
		projectName: 'Test 0512-1',
		unfulfillmentStep: 2,
		summaryStatus: {
			timeStamp: 0,
			status: 1,
			waterfallId: 2000,
			estimatedTimeDTO: {
				estimatedTime: 0,
				extraTime: 0,
			},
		},
		unreadMessages: 0,
		status: [
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: '0001-01-01T00:00:00',
				status: 1,
				id: '3f1e86b2-93cd-4286-907b-08dd18e8d307',
			},
			{
				valuationProjectId: '7a15672d-8d3e-42e7-b6c6-eb4a24b6af59',
				date: null,
				status: 2,
				id: '928caae8-f130-4b55-907c-08dd18e8d307',
			},
		],
	},
] as ValuationProject[];
