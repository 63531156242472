import { css } from '@emotion/css';
import { IC_DOUBLE_ARROW_WHITE, IC_WARNING_CIRCLE } from '../../../../../Assets';
import Button from '../../../Button/Button';
import Image from '../../../Image';
import Flex from '../../../Layout/Flex';
import Title from '../../../Layout/Title';

const Style = css({
	label: 'OptionsGroupsWarning',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	'.title': {
		marginTop: '2rem',
		marginBottom: '1rem',
	},
	'.text-container': {
		textAlign: 'center',
		marginBottom: '2.4rem',
	},
});

type Props = {
	onContinue: () => void;
	onConfirm: () => void;
	treshhold: number;
};

const OptionsGroupsWarning = ({ onConfirm, onContinue, treshhold }: Props) => {
	return (
		<div className={Style}>
			<Image width="6.4rem" src={IC_WARNING_CIRCLE} />
			<Title warning className="title">
				Please note
			</Title>
			<span className="text-container">
				The amount of options groups is more than {treshhold}.
				<br />
				Please try to merge groups which have the same exercise price.
			</span>
			<Flex gap="4rem">
				<Button qaid="" label="Continue" cancel inverse onClick={onContinue} />
				<Button qaid="" label="Merge" onClick={onConfirm} />
			</Flex>
		</div>
	);
};

export default OptionsGroupsWarning;
