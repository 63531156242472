import { observer } from "mobx-react-lite";
import { useState } from "react";
import { EditGrantErrorField } from "../../../../../Models/API/enums";
import { GrantTypes } from "../../../../../Models/App/EquityPlans/Grants";
import Button from "../../../../../Shared/Components/Button/Button";
import DatePickerInput from "../../../../../Shared/Components/Input/DatePickerInput";
import NumberInput from "../../../../../Shared/Components/Input/NumberInput";
import TextInput from "../../../../../Shared/Components/Input/TextInput";
import Flex from "../../../../../Shared/Components/Layout/Flex";
import Select from "../../../../../Shared/Components/Select/Select";
import { InputValidationRef } from "../../../../../Shared/Hooks/useFormValidation";
import useRootStore from "../../../../../Shared/Hooks/useRootStore";
import { addDaysToDate, getCurrency, isNullOrUndefined, isNumber } from "../../../../../Shared/Utilities";
import { GrantDetailsStyle } from "./GrantDetails.style";
// import NewGrantDetailsStyle from "./NewGrant/NewGrantDetails.style";
import useGrant from "./useGrant";
import Clickable from "../../../../../Shared/Components/Clickable/Clickable";
import useEsopModals from "../../../useEsopModals";
import { IC_EDIT2, IC_TRASH2, IC_USER_CIRCLE, IC_USER_PROFILE_PURPLE } from "../../../../../Assets";
import Menu from "../../../../../Shared/Components/Menu/index";
import Title from "../../../../../Shared/Components/Layout/Title";
import { TypeDto } from "../../../../../Models/App/EquityPlans/General";
import { toJS } from "mobx";

interface GrantDetailsProps {
    minimized?: boolean;
    blocked?: boolean;
    grantBtn?: () => void;
    isEdit?: boolean;
}

const GrantDetails = (props: GrantDetailsProps) => {
    const { equityPlansStore } = useRootStore();
    const [isEdit, setIsEdit] = useState(props.isEdit ?? false);
    const [isLoading, setIsLoading] = useState(false);
    const { showDeleteGrantModal, openBeneficiaryModal, showSuccessModal, showErrorModal } = useEsopModals();
    const [gNumberExst, setGNumberExst] = useState<boolean>();
    const { currenciesOptions, formValidationState, grantNumberRef, onInputHandler, plans, validateForm, grantTypes, inputRefs, vests, grant, setApiErrors, grantMinDate, cancelChanges } = useGrant("beneficiaryGrantData");
    const activeDocument = grant.documentDetailsDto?.find(doc => !doc.isCancelled);
    const canceledDocument = grant.documentDetailsDto?.find(doc => doc.isCancelled);

    // useEffect(() => {
    //     equityPlansStore.beneficiaryGrantData = {} as BenGrant;
    //     return () => {
    //         equityPlansStore.beneficiaryGrantData = {} as BenGrant;
    //     }
    // }, []);

    const onUpdateGrant = async () => {
        try {
            if (!validateForm()) return;
            setIsLoading(true);
            const res = await equityPlansStore.UpdateGrant();
            if (res.data?.grantId) {
                showSuccessModal('Grant data succesfully updated')
            } else {
                showErrorModal(res)
            }
            setIsEdit(false);
            // removeCurrentModal();
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    const onApiValidate = async () => {
        // const res = await equityPlansStore.ValidateGrant();
        // if (res.status === 204) return true;
        // const errorObj = res.data.errors.reduce((acc: any, error: any) => {
        // 	return {
        // 		...acc,
        // 		[EditGrantErrorField[error.editGrantField]]: error.errorMessage,
        // 	};
        // }, {});
        // setApiErrors(errorObj);
        // return false;
    };

    const onValidateGrantNumber = async (e: any) => {
        // console.log('grantNumberRef.current: ', grantNumberRef.current, ' grant.grantNumber: ', grant.grantNumber)
        if (grantNumberRef.current === grant.grantNumber) return;

        const res = await equityPlansStore.CheckIfGrantNumberExists(e);
        setGNumberExst(res.data);
        grantNumberRef.current = undefined;
    };

    return (
		<div className={GrantDetailsStyle}>
			<div className={`${GrantDetailsStyle}__title-container`}>
				<img className="img-sh-type" src={IC_USER_PROFILE_PURPLE} alt="" />
				<Title className="flex gap-1">
					<Clickable
						qaid=""
						margin={0}
						justify="start"
						className="ben-modal"
						onClick={() => !isNullOrUndefined(grant.beneficiaryId) && openBeneficiaryModal(grant.beneficiaryId)}
						applyStyle
					>
						{`${grant.fullName?.firstName} ${grant.fullName?.lastName}`}
					</Clickable>
					({grant.grantNumber})
				</Title>
				<Menu
					items={[
						{
							label: 'Edit',
							icon: IC_EDIT2,
							qaid: 'EditShareholder.Button.Edit',
							onClick: () => setIsEdit(true),
							disabled: !!activeDocument,
							customTooltip: !!activeDocument ? 'Grant cannot be edit since there is a grant letter linked to it' : 'Edit',
						},
						{
							label: 'Delete',
							icon: IC_TRASH2,
							qaid: 'EditShareholder.Button.DeleteShareholder',
							onClick: () => grant.grantId && showDeleteGrantModal(grant.grantId),
							disabled: !!activeDocument || !!canceledDocument,
							customTooltip:
								!!activeDocument || !!canceledDocument ? 'Grant cannot be deleted since there is a grant letter linked to it' : 'Delete',
						},
					]}
				/>
			</div>
			<div className={`${GrantDetailsStyle}__details-section`}>
				<div className={`${GrantDetailsStyle}__grant-details`}>
					<div className={`${GrantDetailsStyle}__inputs-container`}>
						{/* Plan */}
						<Select
							label="Equity plan"
							qaid="EditGrant.Select.EquityPlan"
							options={plans}
							name="planId"
							onChange={(value, name) => {
								if (isNumber(value)) onInputHandler(value, name!);
							}}
							value={grant.planId}
							error={formValidationState?.planId?.message}
							ref={(el: InputValidationRef) => (inputRefs.current.planId = el)}
							disabled={props.blocked}
							isViewMode={!isEdit}
							className="mb-0"
						/>

						{/* Grant Type */}
						<Select
							label="Award type"
							qaid="EditGrant.Select.AwardType"
							options={grantTypes}
							name="grantType"
							onChange={(value, name) => {
								if (isNumber(value)) onInputHandler(value, name!);
							}}
							value={grant.grantType}
							error={formValidationState?.grantType?.message}
							ref={(el: InputValidationRef) => (inputRefs.current.grantType = el)}
							disabled={props.blocked}
							isViewMode={!isEdit}
							className="mb-0"
						/>

						{/* Grant Date */}
						<DatePickerInput
							label="Grant date"
							qaid="EditGrant.Input.GrantDate"
							name="grantDate"
							minDate={grantMinDate}
							maxDate={grant.experationDate}
							value={grant.grantDate}
							onChange={(value, name) => value && onInputHandler(value.toISOString(), name!)}
							onBlur={onApiValidate}
							error={formValidationState?.grantDate?.message}
							ref={(el: InputValidationRef) => (inputRefs.current.grantDate = el)}
							disabled={props.blocked}
							isViewMode={!isEdit}
							containerClassName="mb-0"
						/>

						{/* Grant Number */}
						<TextInput
							label="Grant number"
							qaid="EditGrant.Input.GrantNumber"
							name="grantNumber"
							value={grant?.grantNumber}
							// onChange={onInputHandler}
							onChange={(e) => {
								onValidateGrantNumber(e);
								grant.grantNumber = e;
							}}
							// onBlur={onApiValidate}
							// onFocus={() => (grantNumberRef.current = grant?.grantNumber)}
							// error={formValidationState?.grantNumber?.message}
							error={gNumberExst ? 'Grant number already exists' : undefined}
							// ref={(el: InputValidationRef) => (inputRefs.current.grantNumber = el)}
							disabled={!!canceledDocument || equityPlansStore.companyData?.autoGenerateGrantNumber}
							isViewMode={!isEdit}
							containerClassName="mb-0"
							info={equityPlansStore.companyData?.autoGenerateGrantNumber ? 'Auto-generated by altshare' : undefined}
						/>

						{/* Granted */}
						<NumberInput
							label="Amount granted"
							qaid="EditGrant.Input.GrantNumber"
							name="granted"
							value={grant?.granted}
							onChange={(value, name) => {
								isNumber(value) ? onInputHandler(value, name!) : onInputHandler(0, name!);
							}}
							onBlur={onApiValidate}
							error={formValidationState?.granted?.message}
							ref={(el: InputValidationRef) => (inputRefs.current.granted = el)}
							disabled={props.blocked}
							isViewMode={!isEdit}
							containerClassName="mb-0"
						/>

						{grant.grantType === GrantTypes.Options && (
							<>
								{/* Exp. Date */}
								<DatePickerInput
									label="Expiration date"
									qaid="EditGrant.Input.ExpirationDate"
									name="experationDate"
									value={grant?.experationDate}
									onChange={(value, name) => value && onInputHandler(value.toISOString(), name!)}
									onBlur={onApiValidate}
									minDate={grant.grantDate ? addDaysToDate(grant.grantDate, 1) : undefined}
									error={formValidationState?.experationDate?.message}
									ref={(el: InputValidationRef) => (inputRefs.current.experationDate = el)}
									disabled={props.blocked}
									isViewMode={!isEdit}
									containerClassName="mb-0"
								/>

								{/* Ex. Price */}
								{isEdit ? (
									<NumberInput
										label="Exercise price"
										qaid="EditGrant.Input.ExPrice"
										value={grant?.exercisePrice}
										name="exercisePrice"
										number="float"
										onChange={(value, name) => {
											if (isNumber(value)) onInputHandler(value, name!);
										}}
										onBlur={onApiValidate}
										error={formValidationState?.exercisePrice?.message || formValidationState?.currency?.message}
										ref={(el: InputValidationRef) => (inputRefs.current.exercisePrice = el)}
										options={currenciesOptions}
										selectedValue={isEdit ? grant?.exercisePriceCurrency : undefined}
										onOptionSelect={(value) => {
											if (isNumber(value)) onInputHandler(value, 'exercisePriceCurrency');
										}}
										disabled={props.blocked}
										isViewMode={!isEdit}
										containerClassName="mb-0"
										ratio={0.6}
									/>
								) : (
									<TextInput
										label="Exercise price"
										qaid="EditGrant.Input.ExPrice"
										name="exercisePrice"
										value={`${grant?.exercisePriceCurrency && getCurrency(grant.exercisePriceCurrency).symbol} ${grant.exercisePrice}`}
										containerClassName="mb-0"
										isViewMode
									/>
								)}
							</>
						)}

						{/* Tax track */}
						<Select
							label="Tax track"
							qaid="EditGrant.Input.TaxTrack"
							options={equityPlansStore.taxTracks}
							value={grant?.taxTrack}
							name="taxTrack"
							onChange={(value, name) => {
								if (isNumber(value)) onInputHandler(value, name!);
							}}
							isViewMode={!isEdit}
							className="mb-0"
						/>

						{/* Department */}
						<Select
							label="Department"
							qaid="EditGrant.Input.Department"
							options={equityPlansStore.departments}
							value={grant?.departmentDto?.key}
							name="departmentDto.key"
							onChange={(val, name) => {
								// grant.department = e;
								// if (isNumber(val)) onInputHandler(val, name!);
								grant.departmentDto = { key: val, value: 'Yevgeny the king' } as TypeDto;
								setTimeout(() => {
									console.log('DTO: Grant', val, toJS(grant));
								}, 500);
							}}
							isViewMode={!isEdit}
							className="mb-0"
						/>

						{/* Site */}
						<Select
							label="Site"
							qaid="EditGrant.Input.Site"
							options={equityPlansStore.sites}
							value={grant?.siteDto?.key}
							name="siteDto.key"
							onChange={(val) => {
								grant.siteDto = { key: val, value: 'Yevgeny the king' } as TypeDto;
							}}
							isViewMode={!isEdit}
							className="mb-0"
						/>

						{/* Vest Schedj */}
						<Select
							label="Vesting schedule"
							value={grant.vestingId}
							onChange={(val) => isNumber(val) && (grant.vestingId = val)}
							options={equityPlansStore.companyActiveVests ?? []}
							qaid=""
							isViewMode={!isEdit}
							disabled={props.blocked}
							className="mb-0"
						/>

						{/* Vest Start Date */}
						<DatePickerInput
							label="Vesting start date"
							value={grant.vestStartDate}
							// maxDate={grant.vestStartDate}
							onChange={(val) => (grant.vestStartDate = val)}
							qaid=""
							isViewMode={!isEdit}
							disabled={props.blocked}
							containerClassName="mb-0"
						/>
					</div>

					{isEdit && !props.blocked && (
						<Flex gap={10} style={{}} justify="end" margin="6rem 0 0 0">
							<Button
								label="Cancel"
								qaid="EditGrant.Button.Cancel"
								square
								inverse
								cancel
								onClick={() => [setIsEdit(false), cancelChanges(grant.grantId)]}
							/>
							<Button label="Save Changes" qaid="EditGrant.Button.Save" isLoading={isLoading} onClick={onUpdateGrant} disabled={gNumberExst} />
						</Flex>
					)}
				</div>
			</div>
		</div>
	);
};

export default observer(GrantDetails);
