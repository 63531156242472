import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { ServerResponseApi } from '../Models/API/All/server-response';
import { DataCollectionCommentPayload } from '../Models/API/DataCollection/comment-payload';
import { DataCollectionComment, DataCollectionFieldTypeEnum } from '../Models/API/DataCollection/comment-response';
import { DataCollectionPayload } from '../Models/API/DataCollection/data-collection-payload';
import { DataCollectionProjectResponse } from '../Models/API/DataCollection/data-collection-project-response';
import { DataCollectionProjectPreview, DataCollectionStatusEnum } from '../Models/API/DataCollection/project-preview';
import { UploadDocumentPayload } from '../Models/API/DataCollection/upload-document-payload';
import { ValuationDocument } from '../Models/API/DataCollection/valuation-document';
import { FourONinePermissions } from '../Models/API/UsersAndPermissions/permissions-enum';
import { HigherLevelResponse, HighLevelErrorResponse } from '../Services/Axios';
import { DataCollectionService } from '../Services/DataCollectionService';
import { RootStore } from './RootStore';
import { getDataCollectionStatus } from '../Screens/DataCollection/helpers/utils';
import { formatDate } from '../Shared/Utilities';

type CommentsByQuestionType = Record<DataCollectionFieldTypeEnum, DataCollectionComment[]>;

export default class DataCollectionStore {
	dataCollectionService: DataCollectionService = new DataCollectionService();
	projects: DataCollectionProjectPreview[] | undefined = undefined;
	projectId: string | null = null;
	dcProjectId: string | null = null;
	dataCollection: DataCollectionPayload = {} as DataCollectionPayload;
	comments: DataCollectionComment[] | undefined = undefined;
	documents: ValuationDocument[] | undefined = undefined;
	dataCollectionId: string = '';
	isOwner: boolean = false;
	currentStatus: DataCollectionStatusEnum = DataCollectionStatusEnum.DataCollection;
	projectName: string = '';

	constructor(private rootStore: RootStore) {
		makeAutoObservable(this);
		makePersistable(this, {
			name: 'DataCollectionStore',
			properties: ['projects', 'projectId', 'comments', 'dataCollection', 'currentStatus', 'projectName'],
			storage: window.sessionStorage,
			expireIn: 10800000, // 3 hours
		});
		// rootStore.companyStore.onCompanyChange.subscribe(() => {
		// 	if (!this.rootStore.companyStore.companyId) return;
		// 	// on change
		// });
	}

	get currentRole() {
		return this.rootStore.auth.permissions?.fourONinePermission ?? FourONinePermissions.NoAccess;
	}

	set valuationProjectId(id: string | null) {
		this.projectId = id;
	}

	get valuationProjectId() {
		return this.projectId;
	}

	set dataCollectionProjectId(id: string | null) {
		this.dcProjectId = id;
	}

	get dataCollectionProjectId() {
		return this.dcProjectId;
	}

	get isProjectDisabled() {
		return ![DataCollectionStatusEnum.DataCollection, DataCollectionStatusEnum.DataReview].includes(this.currentStatus);
	}

	get commentsByQuestion() {
		const defaultObj = {
			[DataCollectionFieldTypeEnum.None]: [],
			[DataCollectionFieldTypeEnum.AboutUs]: [],
			[DataCollectionFieldTypeEnum.CapTable]: [],
			[DataCollectionFieldTypeEnum.CompanyProfitable]: [],
			[DataCollectionFieldTypeEnum.InvestmentExist]: [],
			[DataCollectionFieldTypeEnum.InvestmentType]: [],
			[DataCollectionFieldTypeEnum.NextRoundValuation]: [],
			[DataCollectionFieldTypeEnum.ValuationDate]: [],
			[DataCollectionFieldTypeEnum.IssuanceDocument]: [],
			[DataCollectionFieldTypeEnum.ArticleOfAssociation]: [],
			[DataCollectionFieldTypeEnum.FinancialStatement]: [],
			[DataCollectionFieldTypeEnum.DiscountCashFlow]: [],
			[DataCollectionFieldTypeEnum.Dcf]: [],
			[DataCollectionFieldTypeEnum.SafeOrConvertible]: [],
			[DataCollectionFieldTypeEnum.TimeToLiquidity]: [],
			[DataCollectionFieldTypeEnum.CompanyProfitableInFuture]: [],
			[DataCollectionFieldTypeEnum.GrantStatus]: [],
		} as CommentsByQuestionType;
		return (this.comments || [])?.reduce((acc, comment) => {
			acc[comment.fieldType].push(comment);
			return acc;
		}, defaultObj);
	}

	setDataCollection = <K extends keyof DataCollectionPayload>(key: K, value: DataCollectionPayload[K], isUpdate?: boolean) => {
		if (this.dataCollection) this.dataCollection[key] = value;
		else this.dataCollection = { [key]: value } as DataCollectionPayload;

		isUpdate && this.updateDataCollection();
	};

	resetValuationProject() {
		// this.currentProjectId = null;
		// this.comments = undefined;
		// this.dataCollection = {} as DataCollectionPayload;
	}

	resetStoreToDefaultValues() {
		this.valuationProjectId = null;
		this.comments = undefined;
		this.dataCollection = {} as DataCollectionPayload;
		this.currentStatus = DataCollectionStatusEnum.DataCollection;
	}

	// Valuation Project

	async createProject() {
		const res = await this.dataCollectionService.createProject();
		if (res.data?.data) {
			this.projects?.push(res.data.data);
		}
		return res;
	}

	async getProjects() {
		const res = await this.dataCollectionService.getProjects();
		this.projects = res.data?.data;
	}

	takeProjectOwnership(valuationProjectId: string) {
		return this.dataCollectionService.takeProjectOwnership(valuationProjectId);
	}

	// Data Collection

	getDataCollection(valuationProjectId: string) {
		return this._dataCollectionHandler(() => this.dataCollectionService.getDataCollection(valuationProjectId));
	}

	createDataCollection(data: Partial<DataCollectionPayload>) {
		return this._dataCollectionHandler(() => this.dataCollectionService.createDataCollection(data));
	}
	updateDataCollection(data: DataCollectionPayload = this.dataCollection) {
		return this._dataCollectionHandler(() => this.dataCollectionService.updateDataCollection(data));
	}

	submitDataCollection(dataCollectionId: string = this.dataCollectionId) {
		return this.dataCollectionService.submitDataCollection(dataCollectionId);
	}

	async markAsRead(dataCollectionId: string = this.dataCollectionId, ids: string[]) {
		const res = await this.dataCollectionService.markAsRead(dataCollectionId, ids);
		if (res.isSuccess) {
			this.comments = this.comments?.map((comment) => {
				if (ids.includes(comment.id)) {
					comment.isRead = true;
				}
				return comment;
			});
		}
	}

	sendNotification(valuationProjectId: string) {
		return this.dataCollectionService.sendNotification(valuationProjectId);
	}

	approveProject(valuationProjectId: string) {
		return this.dataCollectionService.approveProject(valuationProjectId);
	}

	private _dataCollectionHandler = async (
		callback: () => Promise<HighLevelErrorResponse | HigherLevelResponse<ServerResponseApi<DataCollectionProjectResponse>>>
	) => {
		const res = await callback();
		if (res.data?.data) {
			const { dataCollectionId, issues, comments, documents, ...rest } = res.data.data;
			this.dataCollection = {
				...rest,
				valuationProjectId: this.valuationProjectId,
			};
			this.dataCollectionId = res.data.data.dataCollectionId;
			this.comments = comments;
			this.documents = documents.sort((a) => (a.type === DataCollectionFieldTypeEnum.GrantStatus ? 1 : -1));
			this.dataCollectionProjectId = res.data.data.dataCollectionId;
			this.isOwner = !!res.data.data.isOwner;
			this.currentStatus = res.data.data.valuationProjectStatus;
			this.projectName = `409A valuation as of ${formatDate(res.data.data.valuationDate) || '<valuation date>'}`;
		}
		return res;
	};

	// Comment

	async addComment(data: DataCollectionCommentPayload) {
		const res = await this.dataCollectionService.addComment(data);
		res.data && this.comments?.push(res.data.data);
		return res;
	}

	// Document

	async uploadDocument(data: UploadDocumentPayload, documentId: string) {
		const res = await this.dataCollectionService.uploadDocument(data, documentId);
		const docIdx = this.documents?.findIndex((doc) => doc.id === res.data?.data.documentId) ?? -1;
		if (res.data && docIdx !== -1 && this.documents) {
			const splittedName = res.data.data.fileName.split('.');
			this.documents[docIdx] = {
				...this.documents[docIdx],
				fileName: res.data.data.fileName,
				filePath: res.data.data.filePath,
				id: res.data.data.documentId,
				extension: `.${splittedName[splittedName.length - 1]}`,
			};
		}
		return res;
	}

	getDocument(dataCollectionId: string, documentId: string) {
		return this.dataCollectionService.getDocument(dataCollectionId, documentId);
	}
}
