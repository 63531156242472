import { useTranslation } from 'react-i18next';
import StyledRadioButton from './RadioButton.Style';
import { isString } from '../../Utilities';
import { isValidElement } from 'react';
import classNames from 'classnames';

export interface RadioButtonOptions extends Omit<React.InputHTMLAttributes<HTMLElement>, 'type' | 'onChange' | 'value' | 'checked' | 'onSelect'> {
	name: string;
	value: any;
	qaid: string;
	onChange?: (value: any, name?: string) => void;
	label?: string;
	autoTranslate?: boolean;
	checked?: boolean;
	children?: React.ReactChild | React.ReactChild[] | false | Element;
}

const RadioButton: React.FC<RadioButtonOptions> = ({ onChange, name, label, autoTranslate = true, value, children, qaid, ...props }) => {
	const { t } = useTranslation();
	return (
		<StyledRadioButton.Label {...props} htmlFor="" onClick={() => !props.disabled && onChange?.(value, name)} data-qaid={qaid}>
			<StyledRadioButton.Box isLabeled={isString(label) || isValidElement(children)} isChecked={props.checked} isDisabled={props.disabled} />
			{isString(label) && (
				<div className={classNames('label')}>
					<span>{autoTranslate ? t(label) : label}</span>
				</div>
			)}
			{isValidElement(children) && children}

			<StyledRadioButton.Input
				{...props}
				className={classNames(props.className, { disabled: props.disabled })}
				name={name}
				type="radio"
				onChange={() => {}}
				value={value || false}
			/>
		</StyledRadioButton.Label>
	);
};

export default RadioButton;
