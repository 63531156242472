import { css } from '@emotion/css';
import { CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Routes } from '../../../../Routes';
import useRootStore from '../../../../Shared/Hooks/useRootStore';
import { isNumber, TIMING_MS } from '../../../../Shared/Utilities';

export type RouteParams = {
	token: string;
	valuationProjectId: string;
};

const Style = css({
	label: 'ProjectOwnership',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
});

const ProjectOwnership = () => {
	const params = useParams<RouteParams>();
	const { auth, dataCollectionStore } = useRootStore();
	const history = useHistory();

	useEffect(() => {
		(async () => {
			const searchParams = new URLSearchParams(window.location.search);
			const paramsObject: Record<string, string> = {};
			searchParams.forEach((value, key) => {
				paramsObject[key] = value;
			});
			const companyIdParam = searchParams.get('companyId') ?? '';
			const companyId = !isNaN(+companyIdParam) && isNumber(+companyIdParam) ? +companyIdParam : undefined;

			const res = await auth.chiefLogin(params.token, params.valuationProjectId, companyId);
			const redirectUrl = res.data?.data?.jwttoken ? `${Routes.dataCollection.form}/${params.valuationProjectId}/0` : Routes.account.login;
			history.push(redirectUrl);
		})();
	}, [params]);

	return (
		<section className={Style}>
			<CircularProgress size={36} />
			<h1>Please wait while connecting you to data collection...</h1>
		</section>
	);
};

export default ProjectOwnership;
