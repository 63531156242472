import { css } from '@emotion/css';
import appConfig from '../../../../../../../config/config';

import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import Button from '../../../../../../../Shared/Components/Button/Button';
import useRootStore from '../../../../../../../Shared/Hooks/useRootStore';
import { formatDecimal } from '../../../../../../../Shared/Utilities';
import { ScatterChartProps } from '../../../../../../Waterfall/Components/AddEditWaterfall/Steps/Summary/deps/types';
import ValuationCardScatterChart from './ValuationCardScatterChart';
import { VolatilityChartPointType } from '../../../../../../../Models/API/Valuation/summary';

const Style = css({
	label: 'ValuationSummary',
	borderBottom: `1px solid ${appConfig.style.colors.table}`,
	paddingBottom: '3rem',
	'&__title': {
		fontWeight: 700,
		fontSize: '1.8rem',
		borderBottom: `1px solid ${appConfig.style.colors.table}`,
		paddingBottom: '1rem',
		marginBottom: '2.5rem',
	},
	'&__container': {
		display: 'flex',
		gap: '6rem',
	},
	'&__innerContainer': {
		display: 'flex',
		flexDirection: 'column',
		'&.data': {
			borderRight: `1px solid ${appConfig.style.colors.table}`,
			paddingRight: '4rem',
			'.bold': {
				marginBottom: '5.4rem',
			},
			'>span:not(.bold):not(:last-child)': {
				marginBottom: '2rem',
			},
			'.pricePerShare': {
				fontWeight: 500,
				fontSize: '1.8rem',
			},
		},
		'&.graph': {
			flex: 1,
			width: '100%',
			'.bold': {
				marginBottom: '2rem',
			},
		},
	},
});

type Props = { onEditVolatility: () => void };

const ValuationSummary = ({ onEditVolatility }: Props) => {
	const {
		valuationStore: { summaryData, generalForm },
	} = useRootStore();

	const chartData: ScatterChartProps = useMemo(() => {
		const exitValuePointData = summaryData?.commonShareFairValues?.find((value) => value.type === VolatilityChartPointType.ChosenVolatility);
		return {
			data: summaryData?.commonShareFairValues?.map((value) => ({ x: value.volatility * 100, y: value.commonShareFairValue })) ?? [],
			yAxisLabel: 'Common share fair value ($)',
			xAxisLabel: 'Volatility (%)',
			exitValuePoint: exitValuePointData ? { x: exitValuePointData.volatility * 100, y: exitValuePointData.commonShareFairValue } : undefined,
		};
	}, [summaryData?.commonShareFairValues]);

	if (!summaryData) return <></>;

	return (
		<div className={Style}>
			<div className={`${Style}__title`}>Valuation Summary</div>
			<div className={`${Style}__container`}>
				<div className={`${Style}__innerContainer data`}>
					<span className="bold">Company's common share fair value</span>
					<span className="pricePerShare">${formatDecimal(summaryData.commonShareFairValuePerShare)} Per share</span>
					<span>Suggested DLOM - {formatDecimal(summaryData.DLOM)}%</span>
					<span>Common share fair value (after discount) - {formatDecimal(summaryData.commonShareFairValueAfterDiscount)}</span>
				</div>
				<div className={`${Style}__innerContainer graph`}>
					<span className="bold">Common share fair value by volatility</span>
					<ValuationCardScatterChart {...chartData} graphTreshhold={{ x: 0.02, y: 1 }} />
					<Button qaid="ValuationSummary.Button.EditVolatility" label="Edit Volatility" position="end" className="mt-3" onClick={onEditVolatility} />
				</div>
			</div>
		</div>
	);
};

export default observer(ValuationSummary);
