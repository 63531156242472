import { Switch } from "@mui/material";
import { observer } from "mobx-react-lite";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { EquityPlan, IEquityPlan, IPlanVest, IPool, IPoolParams, IVestingSchedule } from "../../../../../../Models/App/EquityPlans/Company";
import Button from "../../../../../../Shared/Components/Button/Button";
import CheckBox from "../../../../../../Shared/Components/CheckBox/CheckBox";
import DatePickerInput from "../../../../../../Shared/Components/Input/DatePickerInput";
import NumberInput from "../../../../../../Shared/Components/Input/NumberInput";
import TextInput from "../../../../../../Shared/Components/Input/TextInput";
import Flex from "../../../../../../Shared/Components/Layout/Flex";
import { TableColumn } from "../../../../../../Shared/Components/Table/Table";
import { InputRefs, InputValidationRef, useFormValidation } from "../../../../../../Shared/Hooks/useFormValidation";
import useModal from "../../../../../../Shared/Hooks/useModal";
import { MultiStepElementProps, ForwardedRef } from "../../../../../../Shared/Hooks/useMultiStepForm";
import useRootStore from "../../../../../../Shared/Hooks/useRootStore";
import { commonValidators } from "../../../../../../Shared/ObjectValidator";
import { isNullOrUndefined } from "../../../../../../Shared/Utilities";
import { StepDivider, StepTitle } from "../../../Registration/Registration.Style";
import NewVestingSchedule from "../../VestingSchedule/NewVestingSchedule/NewVestingSchedule";
import NewPlanStyle from "./NewEquityPlan.style";
import { Comment } from "../../../../../../Shared/Components/Input/Input.Style";
import useEsopModals from "../../../../useEsopModals";
import Title from '../../../../../../Shared/Components/Layout/Title';

interface IProps extends MultiStepElementProps {
	readonly registration?: boolean;
	onSave?: () => unknown;
	data?: EquityPlan;
}

const NewEquityPlan = forwardRef<ForwardedRef, IProps>((props, forwardedRef) => {
	const { equityPlansStore } = useRootStore();
	const { showModal } = useModal();

	const [poolAmount, setPoolAmount] = useState(0);
	const [aprvDate, setAprvDate] = useState<Date | string>();
	const [vestsArr, setVestsArr] = useState<IPlanVest[]>();
	const isDisabled = !!props.data;
	const [isAwardError, setIsAwardError] = useState<boolean>(false);
	const { showSuccessModal, showErrorModal } = useEsopModals();

	useImperativeHandle(forwardedRef, () => ({
		async onValidate() {
			if (isDisabled) return true;
			let isValidated = validateForm();
			return poolValidateForm() && isValidated && !isAwardError;
		},
	}));

	useEffect(() => {
		if (isNullOrUndefined(props.data)) return;
		equityPlansStore.newEquityPlan = props.data;
	}, [props.data]);

	const plan = equityPlansStore.newEquityPlan;

	const formSchema = {
		name: [
			commonValidators.required(),
			// commonValidators.minLength(1),
			// commonValidators.regex(/^[a-zA-Z ]+$/, "Only letters or space allowed")
		],
		planApprovalDateByBoard: [commonValidators.required()],
	};

	const poolSchema = {
		amount: [commonValidators.required()],
		// boardApprovalDate: [commonValidators.required()],
	};

	const {
		formValidationState: poolFormValidationState,
		validateForm: poolValidateForm,
		inputRefs: poolInputRefs,
	} = useFormValidation({
		form: plan.poolParams,
		schema: poolSchema,
	});

	const { formValidationState, validateForm, inputRefs } = useFormValidation({
		form: plan,
		schema: formSchema,
	});

	useEffect(() => {
		// equityPlansStore.newEquityPlan = {
		// 	...equityPlansStore.newEquityPlan,
		// 	poolParams: {} as IPoolParams,
		// 	allowRsu: true,
		// 	allowSop: true,
		// 	allowAga: false,
		// 	allowBspce: false,
		// 	isCancellationsReturnToPool: false,
		// };

		return () => {
			equityPlansStore.newEquityPlan = {};
		};
	}, []);

	const onInputHandler = (value: Date | string | number | boolean | undefined, name?: string) => {
		if (isNullOrUndefined(name)) return;
		equityPlansStore.newEquityPlan = {
			...equityPlansStore.newEquityPlan,
			[name]: value,
		};

		setIsAwardError(!equityPlansStore.newEquityPlan.allowRsu && !equityPlansStore.newEquityPlan.allowSop);
	};

	const newVestHandler = () => {
		showModal({
			title: 'Create New Vesting Schedule',
			body: <NewVestingSchedule />,
			isMust: true,
			width: '60%',
		});
	};

	const createNewPool = (pid: number) => {
		return {
			amount: poolAmount,
			boardApprovalDate: aprvDate,
			name: `${plan.name} Pool`,
			planId: pid,
			plans: [pid],
			stepType: 0,
		} as IPool;
	};

	const createPlanHandler = async () => {
		if (props?.onSave) {
			props.onSave();
		} else {
			const res = await equityPlansStore.AddEquityPlan();
			console.log('newPlan Comp RES,', res);
			if (res?.data?.planId) {
				showSuccessModal();
			} else {
				showErrorModal(res);
			}
		}

		// if (!planId) throw new Error("Crate Plan Faild");
		// const mPool = createNewPool(planId);
		// await equityPlansStore.createPoolForPlan(mPool);
	};

	const mapVests = (vest: IVestingSchedule): IPlanVest => {
		return {
			companyId: 135,
			isFixed: !vest.isManual,
			planId: vest.planId,
			vestingCompanyId: 0,
			vestingId: vest.vestingId,
		};
	};

	const addVestScg = (vestRow: IVestingSchedule) => {
		if (isNullOrUndefined(vestRow)) return;
		const mappedRow = mapVests(vestRow);
		if (isNullOrUndefined(plan.vestings) || plan.vestings.length < 1) {
			plan.vestings = [mappedRow];
		}
		const arr = plan.vestings;
		arr.push(mappedRow);
		plan.vestings = arr;
	};

	const removeVestScg = async (vestRow: IVestingSchedule) => {
		if (isNullOrUndefined(vestRow)) return;
		if (isNullOrUndefined(plan.vestings) || plan.vestings.length < 1) {
			plan.vestings = [];
		}

		console.log('removing');
		const arr = plan.vestings.filter((vst) => +vst.vestingId !== +vestRow.vestingId);
		plan.vestings = arr;
	};

	const vestsColumns: TableColumn<IVestingSchedule>[] = [
		{
			label: 'Use In This Plan',
			defaultCellSize: 80,
			name: 'actions',
			align: 'center',
			render: (row) => (
				<>
					<Switch
						value={plan.allowRsu}
						onChange={(e) => {
							e.target.checked ? addVestScg(row) : removeVestScg(row);
						}}
						size="small"
					/>
				</>
			),
		},
		{ label: 'Name', defaultCellSize: 200, name: 'vestingName' },
		{ label: 'Description', defaultCellSize: 400, name: 'description' },
		// { label: '', name: "description" },

		// { label: '', defaultCellSize: 100, name: 'menu', menuItems: [{ onClick: (row) => handleVestingModal(row.vestingId), label: 'Edit' }, { onClick: () => console.log('first'), label: 'Delete' }] }
	];

	const getPoolAmount = async () => {
		if (isNullOrUndefined(props.data)) return;

		const fata = await equityPlansStore.GetEquityPlan(props.data.planId);
		console.log('fata', fata);

		if (!fata) return;

		const totalPool = fata.poolSteps.map((pls) => pls?.amount)?.reduce((acc, crr) => acc + crr);
		setPoolAmount(totalPool);
	};

	useEffect(() => {
		getPoolAmount();
	}, []);

	return (
		<div className={NewPlanStyle}>
			<Title className={`${NewPlanStyle}__title`}>{props.data ? 'Equity Plan' : 'New Equity Plan'}</Title>
			<span className={`${StepTitle}__subTitle`}>Plan settings</span>
			<hr className={StepDivider} />
			<Flex direction="column" width={'100%'} align="start">
				<div className={`${StepTitle}__award-title`}>
					<span>Award type *</span>
					{isAwardError && (
						<Comment absolute={false} error>
							Required field
						</Comment>
					)}
				</div>
				<Flex width={'fit-content'}>
					<CheckBox
						className="checkboxContainer"
						isChecked={!!plan.allowRsu}
						label="RSU"
						qaid="NewEquityPlan.CheckBox.Rsu"
						name="allowRsu"
						disabled={isDisabled}
						onClick={onInputHandler}
					/>
					<CheckBox
						disabled={isDisabled}
						isChecked={!!plan.allowSop}
						label="Options"
						qaid="NewEquityPlan.CheckBox.Options"
						name="allowSop"
						onClick={onInputHandler}
					/>
				</Flex>
			</Flex>
			<hr className={StepDivider} />
			<Flex justify="start" align="center" margin="0 0 0.5rem 0">
				<TextInput
					containerClassName="mb-0 inputContainer"
					label="Plan name"
					ref={(el: InputValidationRef) => (inputRefs.name = el)}
					error={formValidationState?.name?.message}
					required
					qaid="NewEquityPlan.Input.PlanName"
					value={plan.name}
					name="name"
					onChange={onInputHandler}
					isViewMode={isDisabled}
				/>
				<DatePickerInput
					containerClassName="mb-0 inputContainer"
					label="Plan board approval date"
					required
					qaid="NewEquityPlan.Input.PlanApprovalDateByBoard"
					name="planApprovalDateByBoard"
					placeholder="Select date"
					ref={(el: InputValidationRef) => (inputRefs.planApprovalDateByBoard = el)}
					error={formValidationState?.planApprovalDateByBoard?.message}
					value={plan.planApprovalDateByBoard}
					isViewMode={isDisabled}
					// error={formValidationState?.planApprovalDateByBoard?.message}allowSopallowSop
					onChange={onInputHandler}
				/>
			</Flex>
			<hr className={StepDivider} />
			<Flex align="center" justify="start" margin="0 0 0.5rem 0">
				<NumberInput
					containerClassName="mb-0 inputContainer"
					label="Pool amount"
					required
					// ref={(el: InputValidationRef) => (inputRefs.current.poolParams = el)}
					qaid="NewEquityPlan.Input.PoolAmount"
					value={plan.poolParams?.amount ?? poolAmount}
					// error={formValidationState?.name?.message}
					onChange={(value) => {
						equityPlansStore.newEquityPlan = {
							...equityPlansStore.newEquityPlan,
							poolParams: {
								...equityPlansStore.newEquityPlan.poolParams,
								amount: value,
							},
						};
					}}
					isViewMode={isDisabled}
					ref={(el: InputValidationRef) => (poolInputRefs.amount = el)}
					error={poolFormValidationState?.amount?.message}
					// name="name"
					// onChange={onInputHandler}
				/>
				{/* <DatePickerInput
					containerClassName="mb-0 inputContainer"
					label="Pool board approval date"
					required
					qaid="NewEquityPlan.Input.PoolDate"
					name="splitDate"
					placeholder="Select date"
					value={plan.poolParams?.boardApprovalDate}
					onChange={(value) => {
						equityPlansStore.newEquityPlan = {
							...equityPlansStore.newEquityPlan,
							poolParams: {
								...equityPlansStore.newEquityPlan.poolParams,
								boardApprovalDate: value,
							},
						};
					}}
					ref={(el: InputValidationRef) => (poolInputRefs.boardApprovalDate = el)}
					error={poolFormValidationState?.boardApprovalDate?.message}
					isViewMode={isDisabled}
					// error={formValidationState?.planApprovalDateByBoard?.message}
				/> */}
			</Flex>
			<hr className={StepDivider} />
			<Flex justify="start" align="center" gap={30}>
				<span>Return canceled options/units to pool</span>
				<CheckBox
					isChecked={plan.isCancellationsReturnToPool}
					qaid="NewEquityPlan.Checkbox.ReturnCanceledOptions"
					name="isCancellationsReturnToPool"
					onClick={onInputHandler}
					disabled={isDisabled}
					// defaultValue={true}
				/>
			</Flex>

			{!props.registration && (
				<Flex gap={8} align="center" className="mt-5" justify="end">
					{!isDisabled && <Button label="Create plan" qaid="NewPlan.Button.CreatePlan" onClick={createPlanHandler} />}
				</Flex>
			)}
		</div>
	);
});
export default observer(NewEquityPlan);
