import { observer } from 'mobx-react-lite';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { add } from 'date-fns';
import { useTranslation } from 'react-i18next';
import InputLabel from '../../../../../../config/style/components/InputLabel/InputLabel';
import { EquityValueEnum } from '../../../../../../Models/API/Valuation/equity-value-enum';
import { GeneralForm } from '../../../../../../Models/App/Valuation/general-form';
import { Routes } from '../../../../../../Routes';
import ExpandToggle from '../../../../../../Shared/Components/ExpandToggle';
import Hr from '../../../../../../Shared/Components/HorizontalRule/Hr';
import DatePickerInput from '../../../../../../Shared/Components/Input/DatePickerInput';
import { TextAreaInput } from '../../../../../../Shared/Components/Input/Input.Style';
import NumberInput from '../../../../../../Shared/Components/Input/NumberInput';
import TextInput from '../../../../../../Shared/Components/Input/TextInput';
import RadioButton from '../../../../../../Shared/Components/RadioButton/RadioButton';
import Spinner from '../../../../../../Shared/Components/Spinner/Spinner';
import { useReviewers } from '../../../../../../Shared/Components/Waterfall/Steps/Reviewers/useReviewers';
import useEffectOnce from '../../../../../../Shared/Hooks/useEffectOnce';
import { InputValidationRef, useFormValidation } from '../../../../../../Shared/Hooks/useFormValidation';
import useModal from '../../../../../../Shared/Hooks/useModal';
import { ForwardedRef } from '../../../../../../Shared/Hooks/useMultiStepForm';
import useRootStore from '../../../../../../Shared/Hooks/useRootStore';
import { commonValidators } from '../../../../../../Shared/ObjectValidator';
import { eliminateTimeZone, formatDate, isNullOrUndefined, isNumber } from '../../../../../../Shared/Utilities';
import { WaterfallStep } from '../../../../../Waterfall/Components/AddEditWaterfall/index.style';
import WaterfallSubtitle from '../../../../../Waterfall/Components/WaterfallSubtitle/WaterfallSubtitle';
import { EquitySettingsStyle, GeneralInfoStyle } from './index.style';
import { Collapse, Fade } from '@mui/material';
import { RiskFreeRate } from '../../../../../../Models/API/Waterfall/IForm';
import ReviewRfr from './ReviewRfr';
import { ValuationFormSteps } from '../../../../../../Models/API/Valuation/steps-enum';

export function calculateEffectiveExitDate(valuationDate: Date, timeToExit: number) {
	const numOfYears = Math.floor(timeToExit);
	const numOfMonths = Math.round((timeToExit - numOfYears) * 12);
	return add(new Date(valuationDate), { years: numOfYears, months: numOfMonths });
}

const GeneralInfo = React.forwardRef<ForwardedRef, unknown>((props, forwardedRef) => {
	const {
		valuationStore,
		valuationStore: { createProject, isViewMode, generalForm: form },
		companyStore: { companyId },
	} = useRootStore();
	const [isCreating, setIsCreating] = useState<boolean>(false);
	const [isFetching, setIsFetching] = useState<boolean>(false);
	const [isRfrLoading, setIsRfrLoading] = useState<boolean>(false);
	const [isRFROpen, setIsRFROpen] = useState<boolean>(false);
	const [nameError, setNameError] = useState<string>('');
	const [showDesc, setShowDesc] = useState<boolean>(false);
	const [effectiveExitDate, setEffectiveExitDate] = useState<string | undefined>(undefined);
	const history = useHistory();
	const { t } = useTranslation();
	const { showAsyncModal } = useModal();
	const formRef = useRef<GeneralForm>();
	const [rfrData, setRfrData] = useState<RiskFreeRate[]>();
	// const [form, setForm, onInputHandler] = useAppendState<GeneralForm>({
	// 	equityType: EquityValueEnum.AccordingToLastInvestmentRound,
	// });

	const { ReviewersComponent } = useReviewers({
		getProjectReviewers: valuationStore.getProjectReviewers,
		toggleProjectReviewer: valuationStore.toggleProjectReviewer,
		waterfallId: valuationStore.currentWaterfallId,
	});

	useEffect(() => {
		if (!form.valuationDate || !form.timeToExit) return;
		const res = calculateEffectiveExitDate(form.valuationDate, form.timeToExit);
		const newEffectiveExitDate = formatDate(res);
		setEffectiveExitDate(newEffectiveExitDate);
	}, [form.valuationDate, form.timeToExit]);

	useEffect(() => {
		getReviewRfrList();
	}, [form.valuationDate]);

	const getReviewRfrList = async () => {
		if (!form.valuationDate) return;
		const res = await valuationStore.getRiskFreeRate(form.valuationDate);
		setRfrData(res.data?.data);
	};

	const formSchema = {
		projectName: [
			commonValidators.maxLength(200),
			commonValidators.regex(/^[a-zA-Z0-9 \-&:+@().]*$/, 'Only letters, numbers, spaces or "- & : + @ ( ) ." allowed'),
			commonValidators.customValidation(!nameError, nameError),
		],
		valuationDate: [commonValidators.requiredIf(() => false)],
		timeToExit: [commonValidators.range2(0, 100, { includeMax: true, includeMin: true })],
		exitValue: [commonValidators.graterThan(0)],
	};

	const { validateForm, formValidationState, inputRefs } = useFormValidation({
		form: form,
		schema: formSchema,
		deps: [nameError],
	});

	const [tempName, setTempName] = useState<string>('');

	useEffect(() => {
		// initialStateRef.current = scenarios;

		if (!valuationStore.currentWaterfallId) return;
		(async () => {
			// setIsFetching(true);
			setShowDesc(!!form.description);
			// setIsFetching(false);
		})();

		return () => {
			setShowDesc(false);
		};
	}, [valuationStore.currentWaterfallId, form]);

	useEffect(() => {
		(async () => {
			if (form.riskFreeRate) return;
			getRiskFreeRate(form.valuationDate, form.timeToExit);
		})();
	}, [form.valuationDate, form.timeToExit]);

	const getRiskFreeRate = async (valuationDate?: Date | null, timeToExit?: number | null) => {
		if (isNullOrUndefined(valuationDate) || isNullOrUndefined(timeToExit) || !formValidationState?.timeToExit?.isValid) return;

		setIsRfrLoading(true);
		const res = await valuationStore.getRiskFreeRateValue(valuationDate, timeToExit);
		if (isNumber(res.data?.riskFreeRate)) {
			onInputHandler(res.data.riskFreeRate * 100, 'riskFreeRate');
		}
		setIsRfrLoading(false);
	};

	useEffectOnce(
		() => {
			if (!valuationStore.currentWaterfallId) return;
			formRef.current = JSON.parse(JSON.stringify(form));
		},
		[form],
		!!valuationStore.currentWaterfallId
	);

	useImperativeHandle(forwardedRef, () => ({
		async onValidate() {
			if (!valuationStore.waterfallId) return false;

			const schemaValidation = {
				...formSchema,
				projectName: [commonValidators.required(), ...formSchema.projectName],
				valuationDate: [commonValidators.required()],
				timeToExit: [commonValidators.required(), ...formSchema.timeToExit],
				exitValue: [commonValidators.required(), ...formSchema.exitValue],
			};

			const isValid = validateForm({ schemaValidation });
			if (!isValid) return false;

			try {
				const { exitValue, timeToExit, description, valuationDate, equityType, riskFreeRate } = form;
				let isApproved = true;

				if (valuationDate && shouldShowWarning(valuationDate)) {
					isApproved = await showAsyncModal({
						title: 'Warning!',
						type: 'confirm',
						body: "Changing valuation date will erase the current analysis data including cap table and classes's rights, do you want to proceed?",
						confirmButton: {
							label: 'Proceed',
						},
					});
				}

				const isDataChanged = JSON.stringify(formRef.current) !== JSON.stringify(form);
				if (isDataChanged) {
					await valuationStore.updateScenarioStep({
						companyId,
						waterfallId: valuationStore.currentWaterfallId,
						exitValue: exitValue as number,
						timeToExit: timeToExit || 0.000001,
						valuationDate: valuationDate || new Date(),
						description: description as string,
						riskFreeRate: riskFreeRate as number,
						equityType: equityType,
					});
				}
				await valuationStore.getSteps();
				return true;
			} catch (error) {
				console.error('An error occurred while validating the form:', error);
				return false;
			}
		},
	}));

	const shouldShowWarning = (valuationDate: Date) => {
		return (
			valuationStore.lastAllowedStep > 1 &&
			valuationDate &&
			valuationStore.originalValuationDate &&
			+new Date(valuationDate) !== +new Date(valuationStore.originalValuationDate)
		);
	};

	const onCreateScenario = async () => {
		if (!form.projectName || tempName === form.projectName || !formValidationState?.projectName?.isValid) return;
		setNameError('');

		setIsCreating(true);
		const res = await createProject({
			companyId: companyId,
			waterfallId: valuationStore.currentWaterfallId,
			projectName: form.projectName,
		});
		setIsCreating(false);

		if (res?.errorMessage) {
			setNameError(res.errorMessage);
			return;
		}

		history.replace(`${Routes.dataCollection.valuation}/${valuationStore.currentWaterfallId}/${ValuationFormSteps.general}`);

		setNameError('');
	};

	const onInputHandler = (value: string | Date | number | undefined, name?: string) => {
		if (name === undefined) return;
		valuationStore.setGeneralForm({
			...valuationStore.generalForm,
			[name]: value,
		});
	};

	return (
		// <div className="waterfall_step waterfall_step--general-info layout layout--primary" ref={forwardedRef}>
		<WaterfallStep small>
			<div className="layout__main">
				{isFetching ? (
					<Spinner incorporated center />
				) : (
					<>
						<WaterfallSubtitle>{t('waterfall.generalInfo')}</WaterfallSubtitle>
						<div className={GeneralInfoStyle}>
							<div className="scenario-name">
								<TextInput
									disabled={isViewMode}
									error={formValidationState?.projectName?.message}
									forceValidation={false}
									info="waterfall.analysisNameInfo"
									isLoading={isCreating}
									label="Valuation project name"
									name="projectName"
									onBlur={onCreateScenario}
									onChange={onInputHandler}
									onFocus={() => {
										setTempName(form.projectName as string);
										setNameError('');
									}}
									qaid="AnalysisSettings.Input.ProjectName"
									ref={(el: InputValidationRef) => (inputRefs.projectName = el)}
									value={form.projectName}
									required
								/>
							</div>

							<div className="scenario-valuation-date">
								<DatePickerInput
									label="Valuation date"
									value={form.valuationDate ? eliminateTimeZone(form.valuationDate) : undefined}
									name="valuationDate"
									onChange={(value, name) => {
										onInputHandler(value, name);
										getRiskFreeRate(form.valuationDate, form.timeToExit);
									}}
									disabled={isViewMode}
									maxDate={new Date()}
									info="waterfall.valuationDateInfo"
									error={formValidationState?.valuationDate?.message}
									ref={(el: InputValidationRef) => (inputRefs.valuationDate = el)}
									qaid="AnalysisSettings.Input.ValidationDate"
									required
								/>
							</div>

							<div className="scenario-time-to-exit">
								<NumberInput
									label="Time to liquidity event"
									value={form.timeToExit}
									onChange={(value, name) => {
										onInputHandler(value, name);
									}}
									onBlur={() => {
										getRiskFreeRate(form.valuationDate, form.timeToExit);
									}}
									disabled={isViewMode}
									name="timeToExit"
									info="waterfall.timeToExitInfo"
									error={formValidationState?.timeToExit?.message}
									ref={(el: InputValidationRef) => (inputRefs.timeToExit = el)}
									qaid="AnalysisSettings.Input.TimeToExit"
									number="float"
									required
									placeholder="In years"
								/>
							</div>

							<div className="scenario-effective-time-to-exit">
								<NumberInput
									label="Risk Free Rate (RFR)"
									value={form.riskFreeRate}
									onChange={onInputHandler}
									disabled={isViewMode}
									name="riskFreeRate"
									info="waterfall.timeToExitInfo"
									error={formValidationState?.riskFreeRate?.message}
									ref={(el: InputValidationRef) => (inputRefs.riskFreeRate = el)}
									qaid="AnalysisSettings.Input.RiskFreeRate"
									percentage
									required
									isLoading={isRfrLoading}
								/>
							</div>

							<div className="review-rfr-toggle pointer flex align-center gap-1" onClick={() => setIsRFROpen((prev) => !prev)}>
								<div>Review RFR</div>
								<ExpandToggle isOpen={isRFROpen} />
							</div>
							<Collapse in={isRFROpen} unmountOnExit className="review-rfr-details">
								<ReviewRfr data={rfrData} />
							</Collapse>

							<div className="scenario-description">
								<InputLabel
									ap={{
										htmlFor: 'description',
										text: `${t('waterfall.description')} (optional)`,
									}}
								/>
								<TextAreaInput
									style={{ resize: 'none', height: '100%' }}
									rows={5}
									maxLength={200}
									name="description"
									value={form.description || undefined}
									onChange={(e: any) => {
										onInputHandler(e.target.value, e.target.name);
									}}
									data-qaid="AnalysisSettings.Input.Description"
									placeholder="Write something..."
								/>
							</div>
						</div>
						<div className={EquitySettingsStyle}>
							<InputLabel
								ap={{
									htmlFor: 'equityValue',
									text: `Company equity value *`,
								}}
							/>
							<Hr
								ap={{
									spacing: 'xs',
								}}
							/>
							<div className="data-container">
								<RadioButton
									name="equityType"
									qaid="AnalysisSettings.Radio.LastInvestment"
									value={EquityValueEnum.AccordingToLastInvestmentRound}
									label="According to the last investment round"
									onChange={onInputHandler}
									checked={form.equityType === EquityValueEnum.AccordingToLastInvestmentRound}
								/>
								<RadioButton
									name="equityType"
									qaid="AnalysisSettings.Radio.BusinessValue"
									value={EquityValueEnum.AccordingToBusinessValueAnalyses}
									label="According to business value analysis"
									onChange={onInputHandler}
									checked={form.equityType === EquityValueEnum.AccordingToBusinessValueAnalyses}
								/>
								<NumberInput
									qaid="AnalysisSettings.Input.ExitValue"
									label={
										form.equityType === EquityValueEnum.AccordingToLastInvestmentRound
											? 'According to the last investment round'
											: 'According to business value analysis'
									}
									value={form.exitValue}
									name="exitValue"
									number="float"
									onChange={onInputHandler}
									containerClassName="b"
									placeholder="$M"
									error={formValidationState?.exitValue?.message}
									ref={(el: InputValidationRef) => (inputRefs.exitValue = el)}
									width="28.7rem"
								/>
							</div>
						</div>
					</>
				)}

				<Hr
					ap={{
						spacing: 'sm',
					}}
				/>

				<WaterfallSubtitle className="mt-5">{t('waterfall.projectReviewers')}</WaterfallSubtitle>
				{ReviewersComponent}
			</div>
		</WaterfallStep>
	);
});

export default observer(GeneralInfo);
