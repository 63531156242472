import { css } from '@emotion/css';
import { DragEventHandler, EventHandler, LegacyRef, createRef, useEffect, useState } from 'react';
import { FileDrop } from 'react-file-drop';
import appConfig from '../../../config/config';
import { formatDecimal, getExtensionByType, isNullOrUndefined, isString } from '../../Utilities';
import Spinner from '../Spinner/Spinner';
import Clickable from '../Clickable/Clickable';
import { IC_EXCEL2, IC_UPLOAD_FILE } from '../../../Assets';
import classNames from 'classnames';

const Style = css({
	label: 'UploadFile',
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	position: 'relative',
	'&__container': {
		borderWidth: 1,
		borderStyle: 'dashed',
		borderColor: appConfig.style.colors.border1,
		borderRadius: 8,
		background: appConfig.style.colors.background3,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '2.4rem 1.6rem',
		color: appConfig.style.colors.text2,
		position: 'relative',
		'&.dropping': {
			borderStyle: 'solid',
			borderColor: appConfig.style.colors.color1,
			outlineWidth: 1,
			outlineColor: appConfig.style.colors.color1,
			outlineStyle: 'solid',
			boxShadow: appConfig.style.boxShadow.card4,
		},
		'&.blocked': {
			background: `${appConfig.style.colors.background3}80`,
		},
	},
	'.file-drop-target': {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	'&__upload-img': {
		width: '4.5rem',
		height: 'auto',
		marginRight: '2rem',
	},
	'&__drag-area': {
		display: 'flex',
	},
	'&__text': {
		display: 'flex',
		flexDirection: 'column',
		gap: '0.2rem',
	},
	'&__text-action': {
		display: 'flex',
		gap: '0.4rem',
	},
	'&__comment': {
		fontSize: '1.2rem',
		marginTop: 'auto',
		textAlign: 'center',
	},
	'&__error': {
		marginTop: '1rem',
		fontSize: '1.4rem',
		color: appConfig.style.colors.danger,
		textAlign: 'center',
		position: 'absolute',
		top: '100%',
		width: '100%',
	},
	'&__loading': {
		display: 'flex',
		flexDirection: 'column',
		gap: '1rem',
		alignItems: 'center',
		'> span': {
			color: appConfig.style.colors.text2,
			letterSpacing: 1.05,
			fontWeight: 400,
		},
	},
});

type FileType = 'pdf' | 'word' | 'excel' | 'docx';

interface Props {
	accept: FileType | FileType[];
	limit?: 1;
	file?: File;
	onFileUpload: (file: File) => void;
	error?: string;
	extensionError?: string;
	isLoading?: boolean;
	className?: string;
	onPreview?: (file: File) => Promise<void> | void;
	disabled?: boolean;
}

export interface SelectedFile {
	file: File;
	isValid: boolean;
	status: string;
	key: string;
	documentId?: number;
}

export const acceptByType = {
	word: 'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	excel: 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	pdf: 'application/pdf',
};

const extensionsByType: Record<FileType, string> = {
	word: '.doc, .docx',
	docx: '.docx',
	excel: '.xls, .xlsx',
	pdf: '.pdf',
};

const UploadFile = ({
	accept,
	file: uploadedFile,
	limit = 1,
	onFileUpload,
	error,
	extensionError,
	isLoading,
	className = '',
	onPreview,
	disabled = false,
}: Props) => {
	const [errorMsg, setErrorMsg] = useState<string>();
	const inputRef = createRef<HTMLInputElement>();
	const containerRef = createRef<FileDrop>();
	const [cachedFile, setCachedFile] = useState<File>();

	useEffect(() => {
		setCachedFile(uploadedFile);
	}, [uploadedFile]);

	useEffect(() => {
		setErrorMsg(error);
	}, [error]);

	const getComment = () => {
		const getType = (type: FileType) => {
			switch (type) {
				case 'pdf':
					return 'PDF';
				case 'excel':
					return 'Excel';
				case 'word':
				case 'docx':
					return 'Word';
				default:
					return '';
			}
		};

		let text = isString(accept)
			? `${getType(accept)} only`
			: accept.map((type, idx) => `${idx ? 'or ' : ''}${getType(type)}${idx === accept.length - 1 ? ' only' : ''}`).join(' ');
		if (limit > 1) text += ` (Limited to ${limit} files)`;
		return text;
	};

	const onFileUploadHandler = (file: File | undefined) => {
		if (isNullOrUndefined(file) || isLoading || disabled) return;
		setErrorMsg(undefined);

		const isFileSupported = isString(accept)
			? acceptByType[accept].includes(file.type)
			: accept.some((acceptedType) => acceptByType[acceptedType].includes(file.type));

		if (isFileSupported) {
			onFileUpload(file);
			setCachedFile(file);
		} else setErrorMsg(extensionError || 'File not supported');
	};

	const filesTypeUpload = isString(accept) ? acceptByType[accept] : accept.map((type) => acceptByType[type]).join(', ');

	const uploadedFileName = uploadedFile
		? uploadedFile.name.substring(0, 25) + (uploadedFile.name.length > 25 ? `... ${getExtensionByType(uploadedFile.type)}` : '')
		: undefined;
	const uploadedFileSize = uploadedFile
		? uploadedFile.size > 1000000
			? `${formatDecimal(uploadedFile.size / 1000000)}MB`
			: `${formatDecimal(uploadedFile.size / 1000)}KB`
		: undefined;

	// const onDragHandler = (e: any, isOver: boolean) => {
	//     console.log("TCL: onDragHandler -> e", e);
	//     const isElement = e.target instanceof HTMLDivElement;
	//     if (!isElement || !e.target.className.endsWith("__container")) return;
	//     e.target.classList[isOver ? "add" : "remove"]("dropping");
	// };

	return (
		<div className={classNames(Style, { [className]: !!className })}>
			<FileDrop
				targetClassName={classNames(`${Style}__container`, { blocked: disabled })}
				ref={containerRef}
				onDrop={(files) => onFileUploadHandler(files?.[0])}
				// onFrameDragEnter={(e: any) => onDragHandler(e, false)}
				// onFrameDragLeave={(e: any) => onDragHandler(e, true)}
				draggingOverTargetClassName="dropping"
			>
				{!isLoading && (
					<>
						<input
							ref={inputRef}
							id="fileUpload"
							className="box__file"
							hidden
							type="file"
							data-multiple-caption="{count} files selected"
							onChange={(e) => onFileUploadHandler(e.target.files?.[0])}
							multiple={false}
							accept={filesTypeUpload}
						/>
					</>
				)}
				{isLoading ? (
					<div className={`${Style}__loading`}>
						<Spinner
							size="2.8rem"
							incorporated
							// style={{ textAlign: "center" }}
						/>
						<span>Uploading...</span>
					</div>
				) : uploadedFile ? (
					<div className={`${Style}__drag-area`}>
						<img
							className={classNames(`${Style}__upload-img`, { disabled })}
							src={uploadedFile.name.endsWith('.xlsx') || uploadedFile.name.endsWith('.xls') ? IC_EXCEL2 : IC_UPLOAD_FILE}
							alt="file"
						/>
						<div className={`${Style}__text`}>
							<span className={classNames({ link: !!onPreview && cachedFile })} onClick={() => cachedFile && onPreview?.(cachedFile)}>
								{uploadedFileName}
							</span>
							<span>File size: {uploadedFileSize}</span>
							{!disabled && (
								<span className={`${Style}__text-action`}>
									<Clickable
										qaid="UploadFile.Button.ChooseFile"
										style={{ color: appConfig.style.colors.color1, textDecoration: 'underline' }}
										onClick={() => inputRef?.current?.click()}
										justify="start"
										flex="0 0 fit-content"
										gap="0.6rem"
									>
										Change
									</Clickable>
								</span>
							)}
						</div>
					</div>
				) : (
					<>
						<div className={classNames(`${Style}__drag-area`, { disabled })}>
							<img className={`${Style}__upload-img`} src={IC_UPLOAD_FILE} alt="file" />
							<div className={`${Style}__text`}>
								<span>Drag & Drop or</span>
								<span className={`${Style}__text-action`}>
									{disabled ? (
										'Select a file '
									) : (
										<Clickable
											qaid="UploadFile.Button.ChooseFile"
											style={{ color: appConfig.style.colors.color1, textDecoration: 'underline' }}
											onClick={() => inputRef?.current?.click()}
											justify="start"
											flex="0 0 fit-content"
											gap="0.6rem"
										>
											Select a file
										</Clickable>
									)}
									to upload
								</span>
								<span>
									supported file type: {isString(accept) ? extensionsByType[accept] : accept.map((type) => extensionsByType[type]).join(', ')}
								</span>
							</div>
						</div>
					</>
				)}
				{/* <div className={`${Style}__comment`}>{getComment()}</div> */}
			</FileDrop>
			{!!errorMsg && <div className={`${Style}__error`}>{errorMsg}</div>}
		</div>
	);
};

export default UploadFile;
