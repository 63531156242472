import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import CircularSwitchToggle from '../../../../../../Shared/Components/CircularSwitchToggle/CircularSwitchToggle';
import Select from '../../../../../../Shared/Components/Select/Select';
import Table from '../../../../../../Shared/Components/Table/Table';
import useModal from '../../../../../../Shared/Hooks/useModal';
import { ForwardedRef } from '../../../../../../Shared/Hooks/useMultiStepForm';
import useRootStore from '../../../../../../Shared/Hooks/useRootStore';
import appConfig from '../../../../../../config/config';
import { breakpoints } from '../../../../../../config/style/theme';
import { WaterfallStep } from '../../index.style';
import AnalysisSettings from './AnalysisSettings';
import PayoffBarChart from './components/PayoffBarChart';
import ProgressCard from './components/ProgressCard';
import ValuationCard from './components/ValuationCard/ValuationCard';
import { IEquityHolderParsed, SummaryStatus, SummaryStatusCode } from './deps/types';
import useValuationData from './deps/useValuationData';
import { isNumber } from '../../../../../../Shared/Utilities';
import { useSummary } from './deps/useSummary';

const Style = css({
	label: 'Summary',
	flex: 1,
	padding: '4.4rem 12rem',
	'&__container': {
		marginTop: '0.8rem',
		borderColor: appConfig.style.colors.boxShadowColor1,
		borderWidth: 1,
		borderStyle: 'solid',
		borderRadius: '1.6rem',
		padding: '4.4rem 6.4rem',
	},
	'&__header': {
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'space-between',
		'.data-values': {
			display: 'flex',
			alignItems: 'flex-end',
			gap: '2rem',
			flexBasis: '32.6rem',
		},
	},
	'&__content': {
		marginTop: '3.6rem',
		maxWidth: breakpoints.lg,
		marginInline: 'auto',
	},
	'&__table': {
		marginTop: '2.4rem',
		"div[data-row-type='row']": {
			svg: {
				height: '3.3rem',
			},
			'&:hover': {
				'.valuation-actions': {
					transition: 'all .3s',
					path: {
						stroke: appConfig.style.colors.color1,
					},
					rect: {
						fill: appConfig.style.colors.purple,
					},
				},
			},
		},
		// [`@media screen and (min-width: ${breakpoints.lg})`]: {
		//     marginInline: "16rem",
		// },
	},
});

const Summary = forwardRef<ForwardedRef, unknown>((props, forwardedRef) => {
	const { waterfallStore } = useRootStore();
	const [exitValue, setExitValue] = useState<number>(0);
	const [isShareclass, setIsShareclass] = useState<boolean>(false);
	const { setIsTimeout, handleSummaryStatus, status } = useSummary({
		onGetSummary: () => waterfallStore.getWaterfallSummary(),
		onGetSummaryStatus: async () => {
			const res = await waterfallStore.getSummaryStatus();
			return res.data;
		},
		onSetSummary: () => waterfallStore.setSummary(null),
		onRunSummary: async () => {
			const res = await waterfallStore.runSummary();
			return res.data;
		},
	});

	const { tableColumns, exitValues, payoffChartData, tableData, valuationData } = useValuationData({
		isShareclass,
		data: waterfallStore.summaryData,
		exitValue,
	});
	const { showModal } = useModal();

	useImperativeHandle(forwardedRef, () => ({
		async onValidate() {
			return true;
		},
	}));

	useEffect(() => {
		setExitValue((prevState) => waterfallStore.summaryData?.businessValue || exitValues[0]?.value || prevState);
	}, [exitValues]);

	return (
		<WaterfallStep className={Style}>
			{waterfallStore.summaryData ? (
				<>
					<div className="bold">Exit Distribution</div>
					<div className={`${Style}__container`}>
						<div className={`${Style}__header`}>
							<CircularSwitchToggle
								value={isShareclass}
								actions={[
									{ value: false, label: 'Shareholders' },
									{ value: true, label: 'Share Classes' },
								]}
								onChange={(val) => setIsShareclass(val)}
								className="type-selection"
							/>
							<div className="data-values">
								<Select
									label={`Company Exit Value ($M)`}
									name="exitValuePayoff"
									qaid="Summary.Select.ExitValuePayoff"
									value={exitValue}
									onChange={(exitValue) => isNumber(exitValue) && setExitValue(exitValue)}
									options={exitValues}
									className="exitValue"
								/>
								<AnalysisSettings exitValue={exitValue} />
							</div>
						</div>
						<div className={`${Style}__content`}>
							{!!payoffChartData && <PayoffBarChart {...payoffChartData} />}

							<Table
								className={`${Style}__table`}
								headerSize={1.75}
								rowSize={1.1}
								onRowClick={(shareholder) => {
									const equiltyHolder = valuationData?.[isShareclass ? 'shareClassesTableData' : 'shareHoldersTableData'].find(
										(sh: IEquityHolderParsed) => sh.id === shareholder.id
									);
									if (!equiltyHolder) return;
									showModal({
										body: <ValuationCard isShareClass={isShareclass} equityholder={equiltyHolder} exitValue={exitValue} />,
										maxWidth: '140rem',
										maxHeight: '90rem',
										width: '90%',
										height: '90%',
										isFrameless: true,
										isMust: true,
									});
								}}
								// border={{ bottom: true, top: true }}
								columns={tableColumns}
								rows={tableData}
								showTotal
							/>
						</div>
					</div>
				</>
			) : (
				<ProgressCard
					startTime={(status?.timeStamp ?? 0) * 1000}
					estimated={status?.estimatedTimeDTO}
					isDataReceived={status?.status === SummaryStatusCode.FINISHED}
					setIsTimeout={setIsTimeout}
					onRetry={() => handleSummaryStatus(true)}
					onCancel={waterfallStore.cancelSummary}
				/>
			)}
		</WaterfallStep>
	);
});

export default observer(Summary);
