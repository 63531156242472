import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { useTranslation } from "react-i18next";
import { checkQaidConvention } from "../../Utilities";
import StyledButton from "./Button.Style";
import Tooltip from "../Tooltip";

export interface IButtonProps extends React.ButtonHTMLAttributes<HTMLElement> {
	label?: string;
	inverse?: boolean;
	disabled?: boolean;
	prepend?: string;
	append?: string;
	className?: string;
	width?: number | string;
	height?: number | string;
	isLoading?: boolean;
	cancel?: boolean;
	fullWidth?: boolean;
	position?: 'start' | 'center' | 'end' | 'topRight';
	small?: boolean;
	qaid: string;
	square?: boolean;
	autoWidth?: boolean;
	tooltip?: string;
	disableHoverEffect?: boolean;
}

const Button = React.forwardRef<any, IButtonProps>(
	(
		{
			label,
			onClick,
			inverse = false,
			prepend,
			append,
			className,
			children,
			width,
			height,
			isLoading,
			cancel,
			fullWidth = false,
			position,
			small = false,
			qaid,
			type = 'button',
			square = false,
			autoWidth = false,
			tooltip,
			...rest
		}: IButtonProps,
		forwardedRef
	) => {
		checkQaidConvention(qaid);
		const { t } = useTranslation();

		return (
			<Tooltip title={tooltip ?? ''}>
				<StyledButton
					{...rest}
					width={width}
					height={height}
					inverse={inverse || cancel}
					onClick={isLoading ? () => {} : onClick}
					className={className}
					isLoading={isLoading}
					cancel={cancel}
					position={position}
					type={type}
					small={small}
					fullWidth={fullWidth}
					ref={forwardedRef}
					data-qaid={qaid}
					tabIndex={0}
					square={square}
					autoWidth={autoWidth}
				>
					{isLoading && <CircularProgress size={14} color="inherit" style={{ position: 'absolute' }} />}
					{prepend && <img className="icon-prepend" style={{ opacity: isLoading ? 0 : 1 }} src={prepend} alt={label} />}
					<span style={{ opacity: isLoading ? 0 : 1 }}>{label ? t(label) : children}</span>
					{append && <img className="icon-append" style={{ opacity: isLoading ? 0 : 1 }} src={append} alt={label} />}
				</StyledButton>
			</Tooltip>
		);
	}
);

export default Button;
