import { useEffect, useState } from 'react';
import { IC_CLOSED, IC_FILTER, IC_FILTER_CLICKED } from '../../../Assets';
import Image from '../../../Shared/Components/Image';
import appConfig from '../../../config/config';
import { ElectronicSignature, SignerDetails } from '../../../Models/API/Document/eletronics-signature';
import useRootStore from '../../../Shared/Hooks/useRootStore';
import { observer } from 'mobx-react-lite';
import { css } from '@emotion/css';
import { ClickAwayListener, Fade } from '@mui/material';
import CheckBox from '../../../Shared/Components/CheckBox/CheckBox';
import Select from '../../../Shared/Components/Select/Select';
import Button from '../../../Shared/Components/Button/Button';
import { NumberOption } from '../../../Models/API/All/NumberOption';
import { formatDate, getUniqueId, isDate, isNumber } from '../../../Shared/Utilities';
import { TemplateTypeEnum, templateTypeOptions } from '../../../Models/API/Document/template-type-enum';
import { SignatureStatus } from '../../../Models/API/Document/signature-status-enum';
import DatePickerInput from '../../../Shared/Components/Input/DatePickerInput';
import AutoCompleteInput from '../../../Shared/Components/Input/AutoCompleteInput';
import { toJS } from 'mobx';
import { SigninRuleEnum } from '../../../Models/API/Document/signin-rule-enum';

const Style = css({
	order: 1,
	position: 'relative',
	borderLeft: `1px solid ${appConfig.style.colors.table}`,
	paddingLeft: '1.2rem',
	'&__filter-window': {
		position: 'absolute',
		top: 'calc(100% + 1.2rem)',
		boxShadow: '0px 3px 17px 0px #C9CAD066',
		padding: '2rem',
		right: 0,
		zIndex: 999,
		background: '#ffffff',
		width: 'max-content',
		display: 'flex',
		flexDirection: 'column',
		borderRadius: 8,
		'.actions-container': {
			display: 'flex',
			gap: '1.6rem',
			alignItems: 'center',
		},
		'.filters-list': {
			paddingBottom: '1rem',
			marginBottom: '2rem',
			borderBottom: `1px solid ${appConfig.style.colors.table}`,
			'.filter': {
				display: 'flex',
				alignItems: 'center',
				gap: '1.2rem',
				whiteSpace: 'break-spaces',
				'&:not(:last-child)': {
					marginBottom: '1rem',
				},
				'.btn-remove': {
					display: 'flex',
					cursor: 'pointer',
					width: '2.8rem',
					height: '2.8rem',
					boxShadow: `0px 1px 4px 0px ${appConfig.style.colors.field}`,
					justifyContent: 'center',
					alignItems: 'center',
					borderRadius: '50%',
				},
			},
		},
	},
	'&__filter-by': {
		width: '21rem !important',
	},
});

enum FilterByEnum {
	Type,
	Date,
	Status,
	Signatories,
}

const OPTIONS: NumberOption[] = [
	{
		value: FilterByEnum.Type,
		label: 'Type',
	},
	{
		value: FilterByEnum.Date,
		label: 'Date',
	},
	{
		value: FilterByEnum.Status,
		label: 'Status',
	},
	{
		value: FilterByEnum.Signatories,
		label: 'Signatories',
	},
];

enum ConditionEnum {
	Below,
	Above,
	Equals,
}

enum StatusEnum {
	Pending,
	Partially,
	Completed,
}

const CONDITION_OPTIONS: NumberOption[] = [
	{
		value: ConditionEnum.Below,
		label: 'Below',
	},
	{
		value: ConditionEnum.Above,
		label: 'Above',
	},
	{
		value: ConditionEnum.Equals,
		label: 'Equals',
	},
];

const STATUS_OPTIONS: NumberOption[] = [
	{
		value: StatusEnum.Pending,
		label: 'Pending signature',
	},
	{
		value: StatusEnum.Partially,
		label: 'Partially signed',
	},
	{
		value: StatusEnum.Completed,
		label: 'All signed',
	},
];

type TypeFilter = {
	type: FilterByEnum.Type;
	id: string;
	value?: TemplateTypeEnum;
};

type DateFilter = {
	type: FilterByEnum.Date;
	id: string;
	condition?: ConditionEnum;
	value?: Date;
};

type StatusFilter = {
	type: FilterByEnum.Status;
	id: string;
	value?: StatusEnum;
};

type SignatoriesFilter = {
	type: FilterByEnum.Signatories;
	id: string;
	value?: number;
};

type FilterByState = TypeFilter | DateFilter | StatusFilter | SignatoriesFilter;

const isTypeFilter = (data?: FilterByState): data is TypeFilter => data?.type === FilterByEnum.Type;
const isDateFilter = (data?: FilterByState): data is DateFilter => data?.type === FilterByEnum.Date;
const isStatusFilter = (data?: FilterByState): data is StatusFilter => data?.type === FilterByEnum.Status;
const isSignatoriesFilter = (data?: FilterByState): data is SignatoriesFilter => data?.type === FilterByEnum.Signatories;

type Props = {
	docs?: ElectronicSignature[];
	onFilter?: (docs: ElectronicSignature[]) => void;
};

const Filter = ({ docs = [], onFilter }: Props) => {
	const { contactStore, auth } = useRootStore();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [isSelfAssigned, setIsSelfAssigned] = useState<boolean>(true);
	const [filterBy, setFilterBy] = useState<FilterByState>();
	const [filters, setFilters] = useState<FilterByState[]>([]);

	useEffect(() => {
		onFilter?.(filterResult());
	}, [filters, isSelfAssigned]);

	const renderFilterOptionsByType = () => {
		switch (filterBy?.type) {
			case FilterByEnum.Type:
				return (
					<Select
						qaid=""
						className={`${Style}__filter-by`}
						onChange={(value) =>
							setFilterBy((prev) => {
								if (isTypeFilter(prev)) {
									return {
										...prev,
										value,
									} as TypeFilter;
								}
								return prev;
							})
						}
						value={filterBy.value}
						options={templateTypeOptions}
					/>
				);
			case FilterByEnum.Date:
				return (
					<>
						<Select
							qaid=""
							className={`${Style}__filter-by`}
							onChange={(condition) =>
								setFilterBy((prev) => {
									if (isDateFilter(prev)) {
										return {
											...prev,
											condition,
										} as DateFilter;
									}
									return prev;
								})
							}
							value={filterBy.condition}
							options={CONDITION_OPTIONS}
						/>
						<DatePickerInput
							qaid=""
							containerClassName={`${Style}__filter-by`}
							value={filterBy.value}
							defaultValue="Select date..."
							onChange={(value) => {
								setFilterBy((prev) => {
									if (isDateFilter(prev)) {
										return {
											...prev,
											value,
										} as DateFilter;
									}
									return prev;
								});
							}}
						/>
					</>
				);
			case FilterByEnum.Status:
				return (
					<Select
						qaid=""
						className={`${Style}__filter-by`}
						onChange={(value) =>
							setFilterBy((prev) => {
								if (isStatusFilter(prev)) {
									return {
										...prev,
										value,
									} as StatusFilter;
								}
								return prev;
							})
						}
						value={filterBy.value}
						options={STATUS_OPTIONS}
					/>
				);
			case FilterByEnum.Signatories:
				const contacts: NumberOption[] = docs.reduce((acc, obj) => {
					obj.signers.forEach((signer) => {
						if (!acc.some((c) => c.value === signer.contactId)) {
							acc.push({
								value: signer.contactId,
								label: contactStore.getContactFullNameById(signer.contactId),
							});
						}
					});
					return acc;
				}, [] as NumberOption[]);

				return (
					<AutoCompleteInput
						qaid=""
						containerClassName={`${Style}__filter-by`}
						options={contacts}
						value={filterBy.value}
						onChange={(value) => {
							setFilterBy((prev) => {
								if (isSignatoriesFilter(prev)) {
									return {
										...prev,
										value,
									} as SignatoriesFilter;
								}
								return prev;
							});
						}}
					/>
				);
			default:
				return <></>;
		}
	};

	const closeWindow = () => {
		setIsOpen(false);
		setFilterBy(undefined);
	};

	const checkIfInvalid = (): boolean => {
		if (isTypeFilter(filterBy) || isStatusFilter(filterBy) || isSignatoriesFilter(filterBy)) {
			return isNumber(filterBy.value);
		}

		if (isDateFilter(filterBy)) {
			return isNumber(filterBy.condition) && isDate(filterBy.value);
		}

		return false;
	};

	const getFilterText = (filter: FilterByState) => {
		switch (filter.type) {
			case FilterByEnum.Date:
				if (!isNumber(filter.condition)) return '';
				return `Date   ${ConditionEnum[filter.condition]}   ${formatDate(filter.value)}`;
			case FilterByEnum.Status:
				return `Status   ${STATUS_OPTIONS.find((opt) => opt.value === filter.value)?.label}`;
			case FilterByEnum.Signatories:
				return `Signatory   ${contactStore.getContactFullNameById(filter.value)}`;
			case FilterByEnum.Type:
				return `Status   ${templateTypeOptions.find((opt) => opt.value === filter.value)?.label}`;
			default:
				return '';
		}
	};

	const isPending = (signers: SignerDetails[], rule: SigninRuleEnum) => {
		if (rule === SigninRuleEnum.Parallel) {
			return signers.some((signer) => signer.contactId === auth.currentUserInfo.contactId && signer.status === SignatureStatus.SentForSignature);
		}

		const nextSigner = signers.find((signer) => signer.status === SignatureStatus.SentForSignature);
		return nextSigner?.contactId === auth.currentUserInfo.contactId;
	};

	const filterResult = () => {
		if (!filters.length) {
			return isSelfAssigned ? docs.filter((doc) => doc.signers.some((signer) => signer.contactId === auth.currentUserInfo.contactId)) : docs;
		}

		let result: ElectronicSignature[] = [];
		function isSameDay(d1: Date, d2: Date) {
			return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
		}

		filters?.forEach((filter, idx: number) => {
			const isAnd = true;
			console.log(toJS(docs));
			console.log(auth.currentUserInfo.contactId);
			const arrayToFilter = isSelfAssigned
				? docs.filter((doc) => doc.signers.some((signer) => signer.contactId === auth.currentUserInfo.contactId))
				: docs;

			const filteredArray: ElectronicSignature[] = arrayToFilter.filter((doc) => {
				if (filter.value === undefined) return false;

				if (isTypeFilter(filter)) {
					// if (isObject(fieldValue)) {
					// 	fieldValue = Object.values(fieldValue).join(" ");
					// }

					// In case filter type is date, convert to timestamp

					return doc.type === filter.value;
				}

				if (isStatusFilter(filter)) {
					const completed = doc.signers.reduce((acc, signer) => {
						const val = signer.status === SignatureStatus.Signed ? 1 : 0;
						return acc + val;
					}, 0);

					if (filter.value === StatusEnum.Completed) {
						return completed === doc.signers.length;
					}
					if (filter.value === StatusEnum.Partially) {
						return completed >= 0;
					}
					if (filter.value === StatusEnum.Pending) {
						return isPending(doc.signers, doc.signingRule);
					}
				}

				if (isSignatoriesFilter(filter)) {
					return doc.signers.some((signer) => signer.contactId === filter.value);
				}

				if (isDateFilter(filter)) {
					const docDate = new Date(doc.createTimeStamp);
					switch (filter.condition) {
						case ConditionEnum.Equals:
							return isSameDay(docDate, filter.value);
						case ConditionEnum.Above:
							return +docDate > +(filter.value ?? -1);
						case ConditionEnum.Below:
							return +docDate < +(filter.value ?? -1);
						default:
							return false;
					}
				}
				return false;
			});
			result = isAnd ? filteredArray : [...result, ...filteredArray];
		});
		return result;
	};

	return (
		<ClickAwayListener onClickAway={closeWindow}>
			<div
				className={Style}
				style={{
					borderRight: 'none',
					paddingRight: 0,
				}}
			>
				<Image src={isOpen ? IC_FILTER_CLICKED : IC_FILTER} width="2rem" className="pointer" onClick={() => setIsOpen((prev) => !prev)} />
				<Fade in={isOpen} unmountOnExit>
					<div className={`${Style}__filter-window`}>
						{!!filters.length && (
							<div className="filters-list">
								{filters.map((filter) => (
									<div className="filter" key={filter.id}>
										<div className="btn-remove" onClick={() => setFilters((prev) => prev.filter((f) => f.id !== filter.id))}>
											<Image src={IC_CLOSED} width="1.4rem" />
										</div>
										{getFilterText(filter)}
									</div>
								))}
							</div>
						)}
						<div className="actions-container">
							<CheckBox qaid="" isChecked={isSelfAssigned} onClick={() => setIsSelfAssigned((prev) => !prev)} label="Assigned to me" />
							<Select
								qaid=""
								width="50rem"
								options={OPTIONS}
								value={filterBy?.type}
								className={`${Style}__filter-by`}
								defaultValue="Filter by..."
								onChange={(type) => isNumber(type) && setFilterBy({ type, id: getUniqueId() })}
							/>
							{renderFilterOptionsByType()}
							<Button
								qaid=""
								label="Add"
								cancel
								disabled={!checkIfInvalid()}
								inverse
								onClick={() => {
									if (!filterBy) return;
									setFilters((prev) => [...prev, filterBy]);
									closeWindow();
								}}
							/>
						</div>
					</div>
				</Fade>
			</div>
		</ClickAwayListener>
	);
};

export default observer(Filter);
