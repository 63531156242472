import { DateFormatsEnum, formatDate, formatDecimal, getPercentrage, getRandomNumberBetween } from '../../../Shared/Utilities';
import { Multiple, MultipleData, Volatility, WaterfallPeer } from '../../../Models/API/Waterfall/IForm';
import * as ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import appConfig from '../../../config/config';
import { useCallback, useMemo } from 'react';
import { ShareClassValuationTable } from '../../../Models/API/Valuation/summary';

type Peer = Pick<WaterfallPeer, 'companyName' | 'ticker' | 'description'>;

type VolatilityProps = {
	volatilities: Volatility[];
	peers: Peer[];
};

type PeersMultipleProps = {
	multiples: Multiple[];
	multiplesData: MultipleData[];
};

type ValuationSummaryProps = {
	shareClasses: ShareClassValuationTable[];
	valuationDate: Date;
	commonShareFairValue?: number;
};

export type ExportVolatilityProps = {
	projectName: string;
	valuationDate: Date;
	volatility: VolatilityProps;
	peersMultiple: PeersMultipleProps;
};

export type ExportSummaryProps = {
	summary: ValuationSummaryProps;
};

const borderStyle: Partial<ExcelJS.Border> = {
	style: 'thin',
	color: {
		argb: '000000',
	},
};

const border = {
	left: borderStyle,
	bottom: borderStyle,
	top: borderStyle,
	right: borderStyle,
};

const borderSecondaryStyle: Partial<ExcelJS.Border> = {
	style: 'thin',
	color: {
		argb: appConfig.style.colors.table.replace('#', ''),
	},
};

const borderSecondary = {
	left: borderSecondaryStyle,
	bottom: borderSecondaryStyle,
	top: borderSecondaryStyle,
	right: borderSecondaryStyle,
};

const defaultWorkSheetProperties: Partial<ExcelJS.AddWorksheetOptions> = {
	pageSetup: {
		horizontalCentered: true,
		verticalCentered: true,
		fitToPage: true,
		orientation: 'landscape',
	},
	views: [{ showGridLines: false }],
};

const setCellWithPrimaryBackground = (cell: ExcelJS.Cell, value: ExcelJS.CellValue, border?: Partial<ExcelJS.Borders>) => {
	cell.value = value;
	cell.fill = {
		...cell.fill,
		type: 'pattern',
		pattern: 'solid',
		fgColor: {
			argb: appConfig.style.colors.color1.replace('#', ''),
		},
	};

	cell.font = {
		...cell.font,
		color: {
			argb: 'ffffff',
		},
		bold: true,
	};

	if (border) {
		cell.border = border;
	}
};

const setCellWithSecondaryBackground = (cell: ExcelJS.Cell, value: ExcelJS.CellValue, border?: Partial<ExcelJS.Borders>) => {
	cell.value = value;
	cell.fill = {
		...cell.fill,
		type: 'pattern',
		pattern: 'solid',
		fgColor: {
			argb: 'F0F2FF',
		},
	};

	setBold(cell);

	if (border) {
		cell.border = border;
	}
};

const setBold = (cell: ExcelJS.Cell) => {
	cell.font = {
		...cell.font,
		bold: true,
	};
};

const setFontSize = (cell: ExcelJS.Cell, size: number) => {
	cell.font = {
		...cell.font,
		size,
	};
};

const setNumberFormat = (cell: ExcelJS.Cell, format: string = '#,##0.000') => {
	cell.numFmt = format;
};

const buildVolatilitySheet = (wb: ExcelJS.Workbook, { volatilities, peers }: VolatilityProps) => {
	const worksheetName = 'Volatility';
	const ws = wb.addWorksheet(worksheetName, defaultWorkSheetProperties);

	ws.getColumn(1).width = 14;
	ws.getColumn(3).width = 28;
	ws.getColumn(4).width = 16;
	ws.getColumn(5).width = 60;

	(() => {
		const mainTitle = ws.getCell(1, 1);
		mainTitle.value = 'Volatility Data';
		mainTitle.font = {
			bold: true,
			size: 12,
		};
		mainTitle.alignment = {
			vertical: 'middle',
		};

		const peersTitle = ws.getCell(3, 3);
		peersTitle.value = 'Peers Volatility';
		peersTitle.font = {
			bold: true,
		};

		// Company table
		const titleCell = ws.getCell(4, 3);
		titleCell.alignment = {
			vertical: 'middle',
		};

		const subTitleCell = ws.getCell(4, 4);
		subTitleCell.alignment = {
			vertical: 'middle',
			horizontal: 'center',
		};

		setCellWithPrimaryBackground(titleCell, 'Comparable Companies', {
			...border,
			right: undefined,
		});
		setCellWithPrimaryBackground(subTitleCell, `${formatDecimal(volatilities[0]?.data?.[0].expectedTermsInYears)} Years`, {
			...border,
			left: undefined,
		});

		volatilities.forEach((volatility, idx) => {
			const isTotal = volatility.companyName === 'median' || volatility.companyName === 'average';
			const rowIdx = 5 + idx;
			const nameCell = ws.getCell(rowIdx, 3);
			const valueCell = ws.getCell(rowIdx, 4);

			if (isTotal) {
				setCellWithPrimaryBackground(nameCell, volatility.companyName.slice(0, 1).toUpperCase() + volatility.companyName.slice(1));
				setCellWithPrimaryBackground(valueCell, volatility.data[0]?.volatility);
			} else {
				nameCell.value = volatility.companyName;
				valueCell.value = volatility.data[0].volatility;
			}
			valueCell.numFmt = '0.00%';

			nameCell.border = {
				...border,
				right: undefined,
			};
			valueCell.border = {
				...border,
				left: undefined,
			};
			nameCell.alignment = {
				vertical: 'middle',
			};
			valueCell.alignment = {
				horizontal: 'center',
				vertical: 'middle',
			};
		});
	})();

	// Peers

	(() => {
		const rowStartIdx = 8 + volatilities.length;

		const setTableTitle = (cell: ExcelJS.Cell, value: string) => {
			setCellWithPrimaryBackground(cell, value, border);
			cell.alignment = {
				horizontal: 'center',
				vertical: 'middle',
			};
			cell.font = {
				...cell.font,
				size: 12,
			};
		};
		setTableTitle(ws.getCell(rowStartIdx, 3), 'Comparable Company');
		setTableTitle(ws.getCell(rowStartIdx, 4), 'Ticker');
		setTableTitle(ws.getCell(rowStartIdx, 5), 'Description');

		ws.eachRow((row) => {
			row.height = 22;
		});

		peers.forEach((peer, idx) => {
			const peerRowIdx = rowStartIdx + idx + 1;
			const nameCell = ws.getCell(peerRowIdx, 3);
			nameCell.value = peer.companyName;
			nameCell.border = border;
			nameCell.alignment = {
				horizontal: 'center',
				vertical: 'middle',
			};
			nameCell.font = {
				bold: true,
			};

			const tickerCell = ws.getCell(peerRowIdx, 4);
			tickerCell.value = peer.ticker;
			tickerCell.border = border;
			tickerCell.alignment = {
				horizontal: 'center',
				vertical: 'middle',
			};
			tickerCell.font = {
				bold: true,
			};

			const descCell = ws.getCell(peerRowIdx, 5);
			descCell.value = peer.description;
			descCell.border = border;
			descCell.alignment = {
				wrapText: true,
				vertical: 'top',
				horizontal: 'left',
			};

			ws.getRow(peerRowIdx).height = 50;

			if (idx % 2 === 1) {
				const fill: ExcelJS.Fill = {
					type: 'pattern',
					pattern: 'solid',
					fgColor: {
						argb: 'D9D9D9',
					},
				};
				nameCell.fill = fill;
				tickerCell.fill = fill;
				descCell.fill = fill;
			}
		});
	})();
};

const buildMultipleSheet = (
	wb: ExcelJS.Workbook,
	{ multiples, multiplesData, projectName, valuationDate }: Pick<ExportVolatilityProps, 'projectName' | 'valuationDate'> & PeersMultipleProps
) => {
	const worksheetName = 'Peers multiple';
	const ws = wb.addWorksheet(worksheetName, defaultWorkSheetProperties);
	ws.getColumn(1).width = 25;
	ws.getColumn(2).width = 33;
	ws.getColumn(3).width = 25;
	ws.getColumn(4).width = 15;
	ws.getColumn(5).width = 25;
	ws.getColumn(6).width = 17;
	ws.getColumn(7).width = 15;
	ws.getColumn(8).width = 15;

	(() => {
		const mainTitle = ws.getCell(1, 1);
		mainTitle.value = "Peers' multiple";
		mainTitle.font = {
			bold: true,
			size: 12,
		};
		mainTitle.alignment = {
			vertical: 'middle',
		};

		const nameTitleCell = ws.getCell(3, 1);
		nameTitleCell.value = 'Project name:';
		nameTitleCell.font = {
			bold: true,
		};
		nameTitleCell.alignment = {
			horizontal: 'right',
		};

		const nameValueCell = ws.getCell(3, 2);
		nameValueCell.value = projectName;

		const valuationDateCell = ws.getCell(4, 1);
		valuationDateCell.value = 'Valuation date:';
		valuationDateCell.font = {
			bold: true,
		};
		valuationDateCell.alignment = {
			horizontal: 'right',
		};

		const valuationDateValueCell = ws.getCell(4, 2);
		valuationDateValueCell.value = formatDate(valuationDate);
		valuationDateValueCell.numFmt = DateFormatsEnum.LABEL_SHORT;

		const tableTitles = ['Peers data', 'EV/ Sales', 'EV/ EBITDA', 'Market cap / Net income', 'EBITDA margin', 'Market cap ($M)'];
		tableTitles.forEach((title, idx) => {
			setCellWithPrimaryBackground(ws.getCell(5, 2 + idx), title, border);
		});

		multiples.forEach((multiple, idx) => {
			const isTotal = multiple.companyName === 'median' || multiple.companyName === 'average';

			const rowIdx = 6 + idx;
			const multipleDataCellRef = rowIdx + multiples.length + 6;
			const nameCell = ws.getCell(rowIdx, 2);
			nameCell.border = border;

			if (isTotal) {
				setCellWithPrimaryBackground(nameCell, multiple.companyName.slice(0, 1).toUpperCase() + multiple.companyName.slice(1), border);
				['C', 'D', 'E'].forEach((col, idx) => {
					const cell = ws.getCell(rowIdx, 3 + idx);
					setCellWithPrimaryBackground(
						cell,
						{
							date1904: true,
							formula: `=AVERAGE(${col}6:${col}${rowIdx - 1})`,
						},
						border
					);
				});

				setCellWithPrimaryBackground(
					ws.getCell(rowIdx, 6),
					'',
					idx === multiples.length - 1 ? { bottom: borderStyle, left: borderStyle } : { top: borderStyle, left: borderStyle }
				);
				setCellWithPrimaryBackground(
					ws.getCell(rowIdx, 7),
					'',
					idx === multiples.length - 1 ? { bottom: borderStyle, right: borderStyle } : { top: borderStyle, right: borderStyle }
				);
				ws.getRow(rowIdx).eachCell((cell, cellIdx) => {
					if (cellIdx > 2) {
						cell.numFmt = '#,##0.00';
					}
					cell.alignment = {
						horizontal: 'left',
					};
				});
			} else {
				nameCell.value = multiple.companyName;
				[
					{ from: 'C', to: 'F' },
					{ from: 'D', to: 'F' },
					{ from: 'E', to: 'G' },
				].forEach((col, idx) => {
					ws.getCell(rowIdx, 3 + idx).value = {
						date1904: true,
						formula: `=IF(AND(${col.to}${multipleDataCellRef}>0,${col.from}${multipleDataCellRef}>0),${col.to}${multipleDataCellRef}/${col.from}${multipleDataCellRef},IF(AND(${col.to}${multipleDataCellRef}<>0,${col.from}${multipleDataCellRef}<>0),"(NEG)",""))`,
					};
				});

				ws.getCell(rowIdx, 6).value = {
					date1904: true,
					formula: `=IF(AND(D${multipleDataCellRef}<>0,C${multipleDataCellRef}<>0),D${multipleDataCellRef}/C${multipleDataCellRef},"")`,
				};
				ws.getCell(rowIdx, 7).value = {
					date1904: true,
					formula: `=IF(G${multipleDataCellRef}<>0,G${multipleDataCellRef},"")`,
				};
				ws.getRow(rowIdx).eachCell((cell, cellIdx) => {
					if (cellIdx > 2 && !cell.address.startsWith('F')) {
						setNumberFormat(cell);
					} else if (cell.address.startsWith('F')) {
						setNumberFormat(cell, '0.00%');
					}
					cell.border = {
						left: borderStyle,
						right: borderStyle,
					};
					cell.alignment = {
						horizontal: 'left',
					};
				});
			}
		});
	})();

	(() => {
		const dataStartRowIdx = 10 + multiples.length;

		const valuationDateCell = ws.getCell(dataStartRowIdx, 1);
		valuationDateCell.value = 'Valuation date:';
		valuationDateCell.font = {
			bold: true,
		};
		valuationDateCell.alignment = {
			horizontal: 'right',
		};

		const valuationDateValueCell = ws.getCell(dataStartRowIdx, 2);
		valuationDateValueCell.value = formatDate(valuationDate);
		valuationDateValueCell.numFmt = DateFormatsEnum.LABEL_SHORT;
		const dataTableTitles = ['Peer Company', 'Sales ($M TTM)', 'EBITDA ($M TTM)', 'Net income ($M TTM)', 'EV ($M)', 'Market cap ($M)', 'Ticker'];
		dataTableTitles.forEach((title, idx) => {
			setCellWithPrimaryBackground(ws.getCell(dataStartRowIdx + 1, 2 + idx), title, border);
		});

		multiplesData.forEach((data, idx) => {
			const rowIdx = dataStartRowIdx + 2 + idx;
			const nameCell = ws.getCell(rowIdx, 2);
			nameCell.value = data.companyName;
			ws.getCell(rowIdx, 3).value = data.sales / 1000;
			ws.getCell(rowIdx, 4).value = data.ebitda / 1000;
			ws.getCell(rowIdx, 5).value = data.netIncome;
			ws.getCell(rowIdx, 6).value = data.enterpriseValue / 1000;
			ws.getCell(rowIdx, 7).value = data.marketCap;
			ws.getCell(rowIdx, 8).value = data.ticker;
			ws.getRow(rowIdx).eachCell((cell, cellIdx) => {
				if (cellIdx > 2) {
					setNumberFormat(cell, '#,##0.00');
				}
				cell.border = {
					left: borderStyle,
					right: borderStyle,
					bottom: idx === multiplesData.length - 1 ? borderStyle : undefined,
					top: idx === 0 ? borderStyle : undefined,
				};
				cell.alignment = { horizontal: cellIdx === 8 ? 'center' : 'left' };
			});
		});
	})();
};

const buildValuationSummarySheet = (wb: ExcelJS.Workbook, { shareClasses, valuationDate, commonShareFairValue }: ValuationSummaryProps) => {
	const worksheetName = 'Valuation summary';
	const ws = wb.addWorksheet(worksheetName, defaultWorkSheetProperties);

	ws.getColumn(1).width = 45;
	ws.getColumn(2).width = 22;
	ws.getColumn(3).width = 22;
	ws.getColumn(4).width = 15;
	ws.getColumn(5).width = 22;
	ws.getColumn(6).width = 22;
	ws.getColumn(7).width = 22;
	ws.getColumn(8).width = 15;
	ws.getColumn(9).width = 15;

	const mainTitleCell = ws.getCell(1, 1);
	mainTitleCell.value = 'Valuation Summary';
	setBold(mainTitleCell);
	setFontSize(mainTitleCell, 12);
	mainTitleCell.border = {
		bottom: borderSecondaryStyle,
	};

	const borders = { top: borderSecondaryStyle, bottom: borderSecondaryStyle };
	setCellWithSecondaryBackground(ws.getCell(3, 1), 'Company’s common share fair value ($)', borders);
	setCellWithSecondaryBackground(ws.getCell(3, 2), commonShareFairValue, borders);
	setNumberFormat(ws.getCell(3, 2));
	setCellWithSecondaryBackground(ws.getCell(3, 3), '', borders);
	setCellWithSecondaryBackground(ws.getCell(3, 4), 'Valuation Date', borders);
	setCellWithSecondaryBackground(ws.getCell(3, 5), formatDate(valuationDate), borders);
	setNumberFormat(ws.getCell(3, 5), DateFormatsEnum.LABEL_SHORT);
	setCellWithSecondaryBackground(ws.getCell(3, 6), '', borders);
	setCellWithSecondaryBackground(ws.getCell(3, 7), '', borders);

	const generalDataRow = ws.getRow(3);
	generalDataRow.eachCell((cell) => {
		setFontSize(cell, 12);
		cell.alignment = {
			vertical: 'middle',
			horizontal: 'left',
		};
	});
	generalDataRow.height = 28;

	const tableSectionInitialIdx = 7;

	const tableTitle = ws.getCell(tableSectionInitialIdx, 1);
	tableTitle.value = 'Share Class Valuation Table';
	setBold(tableTitle);
	setFontSize(tableTitle, 12);
	tableTitle.border = {
		bottom: borderSecondaryStyle,
	};

	const primaryBorders = { top: borderStyle, bottom: borderStyle };

	const tableTitles = [
		'Share Class',
		'Number of Shares Outstanding',
		'Amount of Shares as Converted Ratio',
		'%',
		'Issue Price per Share ($)',
		'Liquidation Preference Component ($M)',
		'Common Share Component ($M)',
		'Total Share Class Value ($M)',
		'Value per One Share Class ($)',
	];
	tableTitles.forEach((title, idx) => setCellWithPrimaryBackground(ws.getCell(tableSectionInitialIdx + 2, idx + 1), title, primaryBorders));

	const tableTitlesCells = ws.getRow(tableSectionInitialIdx + 2);
	tableTitlesCells.height = 34;
	tableTitlesCells.eachCell((cell) => {
		cell.alignment = {
			wrapText: true,
			vertical: 'middle',
			horizontal: 'left',
		};
	});

	const tableInitialIdx = tableSectionInitialIdx + 3;

	shareClasses.forEach((sc, idx) => {
		const rowIdx = tableInitialIdx + idx;

		ws.getCell(rowIdx, 1).value = sc.shareClass;
		sc.isCommon && setBold(ws.getCell(rowIdx, 1));

		ws.getCell(rowIdx, 2).value = sc.numberOfSharesOutstanding;
		setNumberFormat(ws.getCell(rowIdx, 2), '0,000');

		ws.getCell(rowIdx, 3).value = sc.AmountOfSharesAsConvertedRatio;
		setNumberFormat(ws.getCell(rowIdx, 3), '0,000');

		ws.getCell(rowIdx, 4).value = {
			date1904: true,
			formula: `=C${rowIdx}/$C$${tableInitialIdx + shareClasses.length + 1}`,
		};
		setNumberFormat(ws.getCell(rowIdx, 4), '0.00%');

		ws.getCell(rowIdx, 5).value = sc.issuePricePerShare;
		setNumberFormat(ws.getCell(rowIdx, 5));

		ws.getCell(rowIdx, 6).value = sc.liquidationPreferenceComponent;
		setNumberFormat(ws.getCell(rowIdx, 6));

		ws.getCell(rowIdx, 7).value = sc.commonShareComponent;
		setNumberFormat(ws.getCell(rowIdx, 7));

		ws.getCell(rowIdx, 8).value = {
			date1904: true,
			formula: `=SUM(F${rowIdx}:G${rowIdx})`,
		};
		setNumberFormat(ws.getCell(rowIdx, 8));

		ws.getCell(rowIdx, 9).value = sc.valuePerOneShareClass;
		setNumberFormat(ws.getCell(rowIdx, 9));

		const row = ws.getRow(rowIdx);
		row.height = 20;
		row.eachCell((cell) => {
			cell.value = cell.value === 0 ? '' : cell.value;
			cell.alignment = {
				...cell.alignment,
				horizontal: 'left',
				vertical: 'middle',
			};
		});
	});

	const totalRowIdx = tableInitialIdx + shareClasses.length + 1;
	ws.getRow(totalRowIdx).height = 22;

	setCellWithPrimaryBackground(ws.getCell(totalRowIdx, 1), 'Total', primaryBorders);

	setCellWithPrimaryBackground(
		ws.getCell(totalRowIdx, 2),
		{
			date1904: true,
			formula: `=SUM(B${tableInitialIdx}:B${shareClasses.length + tableInitialIdx - 1})`,
		},
		primaryBorders
	);
	setNumberFormat(ws.getCell(totalRowIdx, 2), '0,000');

	setCellWithPrimaryBackground(
		ws.getCell(totalRowIdx, 3),
		{
			date1904: true,
			formula: `=SUM(C${tableInitialIdx}:C${shareClasses.length + tableInitialIdx - 1})`,
		},
		primaryBorders
	);
	setNumberFormat(ws.getCell(totalRowIdx, 3), '0,000');

	setCellWithPrimaryBackground(
		ws.getCell(totalRowIdx, 4),
		{
			date1904: true,
			formula: `=SUM(D${tableInitialIdx}:D${shareClasses.length + tableInitialIdx - 1})`,
		},
		primaryBorders
	);
	setNumberFormat(ws.getCell(totalRowIdx, 4), '0.00%');

	setCellWithPrimaryBackground(ws.getCell(totalRowIdx, 5), '', primaryBorders);

	setCellWithPrimaryBackground(
		ws.getCell(totalRowIdx, 6),
		{
			date1904: true,
			formula: `=SUM(F${tableInitialIdx}:F${shareClasses.length + tableInitialIdx - 1})`,
		},
		primaryBorders
	);
	setNumberFormat(ws.getCell(totalRowIdx, 6));

	setCellWithPrimaryBackground(ws.getCell(totalRowIdx, 7), '', primaryBorders);

	setCellWithPrimaryBackground(
		ws.getCell(totalRowIdx, 8),
		{
			date1904: true,
			formula: `=SUM(H${tableInitialIdx}:H${shareClasses.length + tableInitialIdx - 1})`,
		},
		primaryBorders
	);
	setNumberFormat(ws.getCell(totalRowIdx, 8));

	setCellWithPrimaryBackground(ws.getCell(totalRowIdx, 9), '', primaryBorders);

	ws.getRow(totalRowIdx).eachCell((cell) => {
		cell.alignment = {
			vertical: 'middle',
			horizontal: 'left',
		};
	});

	const dlomDataIdx = totalRowIdx + 5;
	setCellWithSecondaryBackground(ws.getCell(dlomDataIdx, 1), 'Value of Ordinary Shares', primaryBorders);
	setCellWithSecondaryBackground(ws.getCell(dlomDataIdx, 2), 500000000, { ...primaryBorders, right: borderStyle });
	ws.getCell(dlomDataIdx, 2).numFmt = '$#,##0';

	ws.getCell(dlomDataIdx + 1, 1).value = 'No. of Ordinary Shares';
	ws.getCell(dlomDataIdx + 1, 2).value = 6000000;
	ws.getCell(dlomDataIdx + 1, 2).border = { ...primaryBorders, right: borderStyle };
	ws.getCell(dlomDataIdx + 1, 2).numFmt = '#,##0';

	setCellWithSecondaryBackground(ws.getCell(dlomDataIdx + 2, 1), 'Value per Ordinary Share on a Marketable Basis', primaryBorders);
	setCellWithSecondaryBackground(ws.getCell(dlomDataIdx + 2, 2), 0.55, { ...primaryBorders, right: borderStyle });
	ws.getCell(dlomDataIdx + 2, 2).numFmt = '$#,##0.00';

	ws.getCell(dlomDataIdx + 3, 1).value = 'Discount for Lack of Marketability (DLOM)';
	ws.getCell(dlomDataIdx + 3, 2).value = '';
	ws.getCell(dlomDataIdx + 3, 2).numFmt = '#,##0';
	ws.getCell(dlomDataIdx + 3, 2).border = { ...primaryBorders, right: borderStyle };

	setCellWithSecondaryBackground(ws.getCell(dlomDataIdx + 4, 1), 'Value per Ordinary Share After DLOM', primaryBorders);
	setCellWithSecondaryBackground(ws.getCell(dlomDataIdx + 4, 2), 0.55, { ...primaryBorders, right: borderStyle });
	ws.getCell(dlomDataIdx + 4, 2).numFmt = '$#,##0.00';

	Array.from({ length: 5 }).forEach((_, idx) => {
		const row = ws.getRow(dlomDataIdx + idx);
		row.height = 20;
		row.eachCell((cell, cIdx) => {
			cell.alignment = {
				horizontal: cIdx === 1 ? 'left' : 'center',
				vertical: 'middle',
			};
			if (idx === 4) {
				cell.fill = {
					...cell.fill,
					type: 'pattern',
					pattern: 'solid',
					fgColor: {
						argb: appConfig.style.colors.color1.replace('#', ''),
					},
				};
			}
		});
	});
};

export const onExportVolatility = ({ volatility, peersMultiple, projectName, valuationDate }: ExportVolatilityProps) => {
	const wb = new ExcelJS.Workbook();
	const workbookName = `Altshare - 409A.xlsx`;

	volatility && buildVolatilitySheet(wb, volatility);
	peersMultiple && buildMultipleSheet(wb, { ...peersMultiple, projectName, valuationDate });

	wb.xlsx.writeBuffer({ useStyles: true }).then((buffer) => {
		saveAs(new Blob([buffer], { type: 'application/octet-stream' }), workbookName);
	});
};

export const onExportSummary = ({ summary }: ExportSummaryProps) => {
	const wb = new ExcelJS.Workbook();
	const workbookName = `Altshare - 409A Summary.xlsx`;

	buildValuationSummarySheet(wb, summary);

	wb.xlsx.writeBuffer({ useStyles: true }).then((buffer) => {
		saveAs(new Blob([buffer], { type: 'application/octet-stream' }), workbookName);
	});
};
