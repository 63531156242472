import { ClickAwayListener, Collapse, Fade } from '@mui/material';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
	IC_ADD_CERTIFICATE,
	IC_CANCEL_ROUND,
	IC_COMPANY_PROFILE_PURPLE,
	IC_EDIT2,
	IC_EDIT_GRAY,
	IC_EYE_ACTION,
	IC_IC_CONVERTIBLE_LOAN,
	IC_LETTER_CANCELLED,
	IC_LETTER_READY,
	IC_LETTER_SENT,
	IC_LETTER_SIGNED,
	IC_SAFE,
	IC_SEND_FOR_SIGNATURE,
	IC_TRASH2,
	IC_USER_PROFILE_PURPLE,
} from '../../../../../../Assets';
import { CapTableValuationType, eInterestType } from '../../../../../../Models/API/CapTable/convertible-loan-transaction-data';
import { IContact, IContactCreate } from '../../../../../../Models/API/Contact/contact';
import { PaymentPlanEnum, TransactionTypesEnum } from '../../../../../../Models/API/enums';
import { Transaction } from '../../../../../../Models/App/CapTable/Transaction';
import AddButton from '../../../../../../Shared/Components/Button/AddButton';
import Button from '../../../../../../Shared/Components/Button/Button';
import Clickable from '../../../../../../Shared/Components/Clickable/Clickable';
import Flex from '../../../../../../Shared/Components/Layout/Flex';
import Title from '../../../../../../Shared/Components/Layout/Title';
import Select from '../../../../../../Shared/Components/Select/Select';
import Spinner from '../../../../../../Shared/Components/Spinner/Spinner';
import Table, { TableColumn } from '../../../../../../Shared/Components/Table/Table';
import { ExtraAssetTypes, accessLvlOptionsCapTable, transactionTypes } from '../../../../../../Shared/Config';
import useContact from '../../../../../../Shared/Hooks/useContact';
import useModal from '../../../../../../Shared/Hooks/useModal';
import useRootStore from '../../../../../../Shared/Hooks/useRootStore';
import { formatDate, formatDecimal, formatNumber, isNullOrUndefined, isNumber } from '../../../../../../Shared/Utilities';
import { AddEditShareholderProps } from '../../../../types';
import { getAssetName } from '../../../../utils/transactions';
import LegalEntityForm from '../AddShareholder/Forms/LegalEntityForm';
import PersonForm from '../AddShareholder/Forms/PersonForm';
import EditShareholderStyle from './EditShareholder.Style';
import AddEditTransaction from './Transactions/AddEditTransaction';
import { ForwardedRef } from '../../../../../../Shared/Hooks/useMultiStepForm';
import Menu from '../../../../../../Shared/Components/Menu/index';
import { CapTablePermission } from '../../../../../../Models/API/UsersAndPermissions/permissions-enum';
import Image from '../../../../../../Shared/Components/Image';
import CreateShareCertificate from './CreateShareCertificate';
import useDocument from '../../../../../Documents/helpers/hooks/useDocument';
import { ElectronicSignatureStatus } from '../../../../../../Models/API/Document/eletronic-signature-status-enum';
import ContactsEmailFillTable from '../../../../../../Shared/Components/ContactsEmailFillTable';
import { TemplateTypeEnum } from '../../../../../../Models/API/Document/template-type-enum';
import DocumentPreviewInfo from '../../../../../Documents/Components/Preview/DocumentPreviewInfo';
import { ElectronicSignature } from '../../../../../../Models/API/Document/eletronics-signature';
import { DocumentInfoType } from '../../../../../../Models/API/CapTable/document-types';
import appConfig from '../../../../../../config/config';
import useGeneralModal from '../../../../../../Shared/Hooks/useGeneralModal';
import { toJS } from 'mobx';

interface TransactionsMemo {
	loans: Transaction[];
	table: Transaction[];
}

const EditShareholer = forwardRef<ForwardedRef, AddEditShareholderProps>((props, forwardedRef) => {
	const {
		contactStore: { getContact, editContactId, setEditContactId, updateContact, onChangeUserPermission },
		shareHolderStore,
		capTableStore: { reloadCurrentProject, project },
		currency,
		auth,
		documentsStore,
		contactStore,
		paymentStore,
	} = useRootStore();
	const [initialContact, setInitialContact] = useState<IContactCreate>({} as IContactCreate);
	const [initialPermission, setInitialPermission] = useState<CapTablePermission>(CapTablePermission.no_access);
	const [isViewMode, setIsViewMode] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isUpdating, setIsUpdating] = useState<boolean>(false);
	const [showAddTransaction, setShowAddTransaction] = useState<boolean>(false);
	const [reupdateContact, setReupdateContact] = useState<boolean>(false);
	const [editTransaction, setEditTransaction] = useState<Transaction>();
	const { showModal, showAsyncModal } = useModal();
	const { contact, setContact, formValidationState, onInputHandler, validateForm, inputRefs, contactInputRefs } = useContact({
		isEmailRequired: shareHolderStore.permissions !== CapTablePermission.no_access,
	});
	const { previewDocument, getPreviewInfo } = useDocument();
	const [tooltips, setTooltips] = useState<Record<string, ElectronicSignature>>({});
	const { onUpgradePlan } = useGeneralModal();

	const thisProjectIsPublished: boolean = project ? project.isPublished : false;

	const shName = initialContact.isLegalEntity ? initialContact.companyName : `${initialContact.firstName} ${initialContact.lastName}`;

	useEffect(() => {
		(async () => {
			if (!isNumber(editContactId)) return;
			setIsLoading(true);
			const res = await Promise.all([getContact(editContactId), shareHolderStore.loadShareholderData()]);
			if (res[0]) {
				const updatedContact: IContactCreate = {
					...res[0],
					contactDetails: {
						...res[0].contactDetails,
						// role: res[1].data?.person?.shareholder_type,
					},
				};
				setContact(updatedContact);
				setInitialContact(updatedContact);
			}
			setIsLoading(false);
			setInitialPermission(shareHolderStore.permissions);
		})();
	}, [editContactId, reupdateContact]);

	const splittedTransactions: TransactionsMemo = useMemo(() => {
		let loans: Transaction[] = [],
			table: Transaction[] = shareHolderStore.transactions || [];
		table = table.filter((transaction) => {
			const isLoanOrSafe = transaction.assetId === ExtraAssetTypes.ConvertibleLoan || transaction.assetId === ExtraAssetTypes.SAFE;

			if (isLoanOrSafe) {
				loans.push(transaction);
				return false;
			}
			return true;
		});
		return {
			loans,
			table,
		};
	}, [shareHolderStore.transactions]);

	const onUpdateContact = async () => {
		if (!contact.contactId || !validateForm()) return;

		setIsUpdating(true);
		// await new Promise((res) => setTimeout(res, 2000));
		const res = await updateContact(contact);

		if (res?.data) {
			const newContact = res.data;
			if (initialPermission !== shareHolderStore.permissions) {
				await onChangeUserPermission(newContact.contactId, newContact.userId, { capTablePermission: shareHolderStore.permissions });
				setInitialPermission(shareHolderStore.permissions);
			}

			// if (initialContact.contactDetails?.role !== contact.contactDetails?.role && contact.contactDetails?.role) {
			//     await shareHolderStore.updatePerson(contact.contactDetails?.role);
			// }
			reloadCurrentProject();
			setInitialContact(contact);
			setIsViewMode(true);
		}
		setIsUpdating(false);
	};

	const onToggleUpdate = () => {
		setContact(initialContact);
		setIsViewMode(!isViewMode);
	};

	const onSuggestionSelected = (contact: IContact) => {
		setEditContactId(contact.contactId);
	};

	const clearEdit = () => {
		setShowAddTransaction(false);
		setEditTransaction(undefined);
	};

	const onDeleteShareholder = async () => {
		showModal({
			type: 'delete',
			title: 'Delete this shareholder',
			body: (
				<>
					<div style={{ marginBottom: '0.4rem' }}>Are you sure you want to delete this shareholder?</div>
					<div className="bold">{shName}</div>
				</>
			),
			onConfirm: async () => {
				await shareHolderStore.removeShareholder();
				props.removeModal?.();
			},
		});
	};

	const onDeleteTransaction = async (transaction: Transaction) => {
		showModal({
			type: 'delete',
			title: 'Delete this transaction',
			body: 'Are you sure you want to delete this transaction?',
			async onConfirm() {
				if (transaction.typeId === TransactionTypesEnum.IssueTransaction) {
					await shareHolderStore.removeIssueTransaction(transaction);
				} else if (transaction.typeId === TransactionTypesEnum.SecondaryTransaction) {
					await shareHolderStore.removeSecondaryTransaction(transaction.transactionId);
				} else if (transaction.typeId === TransactionTypesEnum.ExerciseWarrantsTransaction) {
					await shareHolderStore.removeWarrantsTransaction(transaction.transactionId);
				}
				editTransaction?.transactionId === transaction.transactionId && clearEdit();
			},
		});
	};

	const onCreateLetter = async (obj: Transaction, value: any) => {
		if (!paymentStore.currentPlan.documents) {
			return onUpgradePlan();
		}

		props.hideModal?.();
		await showAsyncModal({
			background: appConfig.style.colors.background2Opacity70,
			isFrameless: true,
			width: '70rem',
			body: (props) => (
				<CreateShareCertificate obj={obj} contact={contact} updateContact={updateContact} setReupdateContact={setReupdateContact} {...props} />
			),
		});
		props.displayModal?.();
		shareHolderStore.loadShareholderData();
	};

	const onEditTransaction = (obj: Transaction) => {
		const objToEdit =
			obj.typeId === TransactionTypesEnum.IssueTransaction
				? shareHolderStore.getEditIssueTransaction(obj)
				: obj.typeId === TransactionTypesEnum.SecondaryTransaction
				? shareHolderStore.getEditSecondaryTransaction(obj)
				: obj.typeId === TransactionTypesEnum.ExerciseWarrantsTransaction
				? shareHolderStore.getEditExerciseWarransTransaction(obj)
				: obj;
		setEditTransaction(objToEdit as Transaction);
		setShowAddTransaction(true);
	};

	const onCloseHandler = () => {
		props.removeModal?.();
	};

	const onSendForSignature = async (obj: Transaction, templateId: string) => {
		const lastUnsignedDoc = obj.documents?.find((doc) => doc.documentStatus === ElectronicSignatureStatus.DocumentCreated);
		if (lastUnsignedDoc?.documentStatus !== ElectronicSignatureStatus.DocumentCreated) return;

		const templates = (await documentsStore.getTemplates()).data?.data;
		if (!templates || !obj.documents) return;

		const contacts = obj.documents.reduce(
			(acc, doc) => {
				if (!obj.projectPersonId) return acc;

				const contact = contactStore.getContactById(obj.projectPersonId);
				if (contact) {
					!contact?.email && acc.editableContacts.push(contact.contactId);
					acc.contacts.push(contact);
				}

				return acc;
			},
			{ editableContacts: [] as number[], contacts: [] as IContact[] }
		);

		if (contacts.editableContacts.length) {
			const isCompleted = await showAsyncModal({
				body: (props) => (
					<ContactsEmailFillTable
						title="Please confirm email addresses for all beneficiaries you send grant letter to:"
						actionLabel="Send for signature"
						{...props}
						{...contacts}
					/>
				),
				width: '90%',
				maxWidth: '100rem',
				maxHeight: '95rem',
				isFrameless: true,
			});

			if (!isCompleted) return;
		}

		const template = templates.find((template) => template.templateId === templateId);
		if (!template) return;

		const signatures = [
			{
				rule: template.signinRule,
				document: {
					documentName: lastUnsignedDoc.documentName,
					id: lastUnsignedDoc.documentId,
					recordId: obj.recordId || 0,
				},
				signerDetailsList: template.signers.map((signer) => {
					let contactId = signer.contactId;

					if (contactId === null) {
						contactId = contact.contactId ?? 0;
					}

					return {
						contactId,
						isDesignatedCompanySignatory: signer.isDesignatedCompanySignatory,
						positionForSignature: signer.positionForSignature,
					};
				}),
			},
		];

		await documentsStore.sendForSignature({ type: TemplateTypeEnum.ShareCertificate, electronicSignatureDetailsList: signatures });
		await shareHolderStore.loadShareholderData();
	};

	const onTooltipIntersection = async (latestDocument: DocumentInfoType) => {
		if (!latestDocument) return;

		if (latestDocument.documentStatus === ElectronicSignatureStatus.DocumentCreated) return;

		const res = await documentsStore.getDocumentSignatures(latestDocument.documentId);
		if (res.data) {
			setTooltips((prev) => ({ ...prev, [latestDocument.documentId]: res.data.data }));
		}
	};

	let tableColumns: TableColumn<Transaction>[] = [
		{
			name: 'date',
			label: 'captable.date',
			format: (val) => formatDate(val),
			align: 'center',
			justify: 'center',
			textAlign: 'center',
			defaultCellSize: '12.5rem',
		},
		{
			name: 'assetId',
			label: 'Asset Class',
			format: (val, obj) => obj?.shareClassName || getAssetName(val),
			align: 'center',
			justify: 'center',
			textAlign: 'center',
		},
		{
			name: 'typeId',
			label: 'Type',
			format(val, obj) {
				return transactionTypes().find((type) => type.value === obj?.typeId)?.label;
			},
			align: 'center',
			justify: 'center',
			textAlign: 'center',
		},
		{
			name: 'seller',
			label: 'Seller',
			align: 'center',
			justify: 'center',
			textAlign: 'center',
		},
		{
			name: 'buyer',
			label: 'Buyer',
			align: 'center',
			justify: 'center',
			textAlign: 'center',
		},
		{
			name: 'numberOfShares',
			label: 'Number of shares',
			format: (val) => formatNumber(val),
			align: 'center',
			justify: 'center',
			textAlign: 'center',
		},
		{
			name: 'numberOfWarrants',
			label: 'Number of warrants',
			format: (val, obj) => {
				if (!val) return '';
				return !obj?.canceledWarrants ? formatNumber(val) : `${val} (canceled: ${formatNumber(obj.canceledWarrants)})`;
			},
			align: 'center',
			justify: 'center',
			textAlign: 'center',
		},
		{
			name: 'numberOfBsa',
			label: 'Number of BSA',
			format: (val) => formatNumber(val),
			align: 'center',
			justify: 'center',
			textAlign: 'center',
		},
		{
			name: 'pricePerShare',
			label: `Price per share (${currency?.symbol})`,
			render(obj, value) {
				if (isNullOrUndefined(obj?.discount) || isNullOrUndefined(obj?.pricePerShare)) return formatNumber(value);
				return (
					<div>
						{formatNumber(obj.pricePerShare - (obj.pricePerShare * obj.discount) / 100)}
						<span className={`${EditShareholderStyle}__discount`}>(-{obj.discount}%)</span>
					</div>
				);
			},
			align: 'center',
			justify: 'center',
			textAlign: 'center',
		},
		{
			name: 'moneyInvested',
			label: `Money invested (${currency?.symbol})`,
			format: (val) => formatNumber(val),
			align: 'center',
			justify: 'center',
			textAlign: 'center',
		},
		{
			name: 'exPrice',
			label: `Exercise price (${currency?.symbol})`,
			format: (val) => formatNumber(val),
			align: 'center',
			justify: 'center',
			textAlign: 'center',
		},
		{
			name: 'documents',
			label: 'Share certificate',
			align: 'center',
			justify: 'center',
			textAlign: 'center',
			isOverflow: true,
			// isDisabled: !thisProjectIsPublished,
			isSticky: true,
			render(obj, value) {
				const isForbidden = obj.typeId === TransactionTypesEnum.SecondaryTransaction && obj.projectPersonId === shareHolderStore.personId;
				if (isForbidden) return <></>;

				const latestDocument = obj.documents?.find((doc) => !doc.isCanceled);
				const canceledDocuments = obj.documents?.filter((doc) => doc.isCanceled);
				const lastCanceled = canceledDocuments && canceledDocuments[canceledDocuments.length - 1];
				const isCancelledStatus = obj.documents?.every((doc) => doc.isCanceled);
				const tooltipData = latestDocument && tooltips[latestDocument.documentId];
				const empteDocuments = obj.documents?.length === 0;

				const getMenuItems = () => {
					const items = [
						latestDocument
							? {
									qaid: 'shareCertificate.Button.CreateShareCertificateLetter',
									icon: IC_EYE_ACTION,
									label: 'View letter',
									props: { width: '3rem' },
									onClick: () => {
										previewDocument(latestDocument.documentId, latestDocument.templateId, latestDocument.documentName);
									},
							  }
							: undefined,

						latestDocument?.documentStatus === ElectronicSignatureStatus.DocumentCreated
							? {
									qaid: 'shareCertificate.Button.SendForSignature',
									icon: IC_SEND_FOR_SIGNATURE,
									label: 'Send for signature',
									props: { width: '4rem' },
									onClick: () => onSendForSignature(obj, latestDocument.templateId),
							  }
							: undefined,

						isCancelledStatus
							? {
									qaid: 'shareCertificate.Button.CreateGrantLetter',
									icon: IC_ADD_CERTIFICATE,
									label: 'Create share certificate letter',
									props: { width: '3rem' },
									onClick: () => onCreateLetter(obj, value),
							  }
							: undefined,

						isCancelledStatus
							? undefined
							: {
									qaid: 'shareCertificate.Button.CancelGrantLetter',
									icon: latestDocument?.documentStatus === ElectronicSignatureStatus.DocumentCreated ? IC_TRASH2 : IC_CANCEL_ROUND,
									label:
										latestDocument?.documentStatus === ElectronicSignatureStatus.DocumentCreated
											? 'Delete this share certificate'
											: 'Cancel share certificate',
									props: { width: '2rem' },
									disabled: !latestDocument,
									onClick: () => {
										const documentId = latestDocument?.documentId;
										const projectID = obj.projectID || 0;
										const transactionId = obj.transactionId;
										const transactionType: TransactionTypesEnum = obj.typeId;

										if (!documentId) return;

										const isSent = latestDocument?.documentStatus === ElectronicSignatureStatus.ElectronicSignatureCreated;

										showModal({
											title: 'Are you sure?',
											body: `Do you really want to ${isSent ? 'cancel' : 'delete'} this Share certificate Letter?`,
											type: 'warning',
											confirmButton: {
												label: 'Continue',
											},
											onConfirm: async () => {
												const res = await (isSent
													? shareHolderStore.cancelCertificateLetter(documentId, projectID)
													: shareHolderStore.deleteCertificateLetter(documentId, projectID, transactionId, transactionType));
												if (res.data) {
													shareHolderStore.loadShareholderData();
												}
											},
										});
									},
							  },
					];
					return items.filter((data) => !isNullOrUndefined(data));
				};

				return (
					<>
						{thisProjectIsPublished && empteDocuments ? <Image
							className="clickable"
							onClick={() => onCreateLetter(obj, value)}
							width="3.5rem"
							src={IC_ADD_CERTIFICATE}
							tooltip={'Create share certificate'}
						/>
							: !thisProjectIsPublished && !obj.documents ? undefined : !obj.documents ? (
								<Image
									className="clickable"
									onClick={() => onCreateLetter(obj, value)}
									width="3.5rem"
									src={IC_ADD_CERTIFICATE}
									tooltip={'Create share certificate'}
								/>
							) : (
								<>
									{lastCanceled && (
										<Image
											className="clickable"
											key={lastCanceled.documentId}
											onClick={() =>
												!obj.documents
													? onCreateLetter(obj, value)
													: previewDocument(lastCanceled.documentId, lastCanceled.templateId, lastCanceled.documentName)
											}
											width="3.5rem"
											onMouseOver={() => onTooltipIntersection(lastCanceled)}
											src={
												!lastCanceled
													? IC_ADD_CERTIFICATE
													: lastCanceled.isCanceled
														? IC_LETTER_CANCELLED
														: lastCanceled.documentStatus === ElectronicSignatureStatus.DocumentCreated
															? IC_LETTER_READY
															: lastCanceled.documentStatus === ElectronicSignatureStatus.ElectronicSignatureCreated
																? IC_LETTER_SENT
																: lastCanceled.documentStatus === ElectronicSignatureStatus.ElectronicSignatureSigned
																	? IC_LETTER_SIGNED
																	: undefined
											}
											tooltip={
												lastCanceled.isCanceled ? (
													'Canceled'
												) : lastCanceled.documentStatus === ElectronicSignatureStatus.DocumentCreated ? (
													'Not sent yet'
												) : lastCanceled.documentStatus === ElectronicSignatureStatus.ElectronicSignatureCreated ? (
													<DocumentPreviewInfo {...getPreviewInfo(tooltipData, lastCanceled.templateId, true)} />
												) : lastCanceled.documentStatus === ElectronicSignatureStatus.ElectronicSignatureSigned ? (
													<DocumentPreviewInfo {...getPreviewInfo(tooltipData, lastCanceled.templateId, true)} />
												) : (
													'Create share certificate'
												)
											}
										/>
									)}
									{latestDocument && latestDocument !== lastCanceled && (
										<Image
											onMouseOver={() => onTooltipIntersection(latestDocument)}
											className="clickable"
											key={latestDocument.documentId}
											onClick={() =>
												!obj.documents
													? onCreateLetter(obj, value)
													: previewDocument(latestDocument.documentId, latestDocument.templateId, latestDocument.documentName)
											}
											width="3.5rem"
											src={
												!latestDocument
													? IC_ADD_CERTIFICATE
													: latestDocument.isCanceled
														? IC_LETTER_CANCELLED
														: latestDocument.documentStatus === ElectronicSignatureStatus.DocumentCreated
															? IC_LETTER_READY
															: latestDocument.documentStatus === ElectronicSignatureStatus.ElectronicSignatureCreated
																? IC_LETTER_SENT
																: latestDocument.documentStatus === ElectronicSignatureStatus.ElectronicSignatureSigned
																	? IC_LETTER_SIGNED
																	: undefined
											}
											tooltip={
												latestDocument.isCanceled ? (
													'Canceled'
												) : latestDocument.documentStatus === ElectronicSignatureStatus.DocumentCreated ? (
													'Not sent yet'
												) : latestDocument.documentStatus === ElectronicSignatureStatus.ElectronicSignatureCreated ? (
													<DocumentPreviewInfo {...getPreviewInfo(tooltipData, latestDocument.templateId, true)} />
												) : latestDocument.documentStatus === ElectronicSignatureStatus.ElectronicSignatureSigned ? (
													<DocumentPreviewInfo {...getPreviewInfo(tooltipData, latestDocument.templateId, true)} />
												) : (
													'Create share certificate'
												)
											}
										/>
									)}
								</>
							)}
						{thisProjectIsPublished && obj.documents && obj.documents[0] && (
							<Menu position={{ top: '-5.2rem', right: '-1rem' }} className="menu" isHorizontal items={getMenuItems()} />
						)}
					</>
				);
			},
		},
	];

	if (!props.isView) {
		tableColumns.push({
			label: '',
			name: 'menu',
			menuItems: [
				{
					onClick: onEditTransaction,
					element: <img src={IC_EDIT_GRAY} alt="edit" />,
					qaid: 'EditShareholder.Button.EditTransaction',
				},
				{
					onClick: onDeleteTransaction,
					element: <img src={IC_TRASH2} alt="delete" />,
					qaid: 'EditShareholder.Button.DeleteTransaction',
				},
			],
		});
	}

	const permissionsOptions =
		auth.permissions?.capTablePermission === CapTablePermission.editor
			? accessLvlOptionsCapTable.filter((opt) => opt.value !== CapTablePermission.editor)
			: accessLvlOptionsCapTable;

	const renderSafeRow = (t: Transaction) => {
		return (
			<>
				S.A.F.E ({formatNumber(t.moneyInvested)}) – Issued as of {formatDate(t.date)}
				{' | '}
				{t.valuationCapType !== CapTableValuationType.None && (
					<>
						{t.valuationCapType === CapTableValuationType.PostMoney ? 'Post-Money Cap ' : 'Pre-Money Cap '}
						{`${currency.symbol}${formatDecimal(t.valuationCap)}`}
						{' | '}
					</>
				)}
				Discount {t.discount}%
			</>
		);
	};

	const renderLoanRow = (t: Transaction) => {
		return (
			<>
				Convertible loan ({formatNumber(t.moneyInvested)}) – Issued as of {formatDate(t.date)}
				{' | '}
				{t.valuationCapType !== CapTableValuationType.None && (
					<>
						{t.valuationCapType === CapTableValuationType.PostMoney ? 'Post-Money Cap ' : 'Pre-Money Cap '}
						{`${currency.symbol}${formatDecimal(t.valuationCap)}`}{' '}
					</>
				)}
				{t.interest && (
					<>
						{' | '}Interest {formatNumber(t.interest)}% |{t.interestType === eInterestType.Compunded ? 'Compunded' : 'Annual'}
					</>
				)}
			</>
		);
	};

	return (
		<div className={EditShareholderStyle}>
			{isLoading ? (
				<Spinner attachParent incorporated center />
			) : (
				<>
					<div className={`${EditShareholderStyle}__title-container`}>
						<img className="img-sh-type" src={contact.isLegalEntity ? IC_COMPANY_PROFILE_PURPLE : IC_USER_PROFILE_PURPLE} alt={shName} />
						<Title>{shName}</Title>
						{!props.isView && (
							<Menu
								items={[
									{
										label: 'Edit',
										icon: IC_EDIT2,
										qaid: 'EditShareholder.Button.Edit',
										onClick: onToggleUpdate,
									},
									{
										label: 'Delete',
										icon: IC_TRASH2,
										qaid: 'EditShareholder.Button.DeleteShareholder',
										onClick: onDeleteShareholder,
									},
								]}
							/>
						)}
					</div>
					<div className={classNames(`${EditShareholderStyle}__contact-section`, { expanded: !isViewMode })}>
						{isUpdating && <Spinner attachParent center />}
						<div className="data-container">
							<div className="form">
								{contact.isLegalEntity ? (
									<LegalEntityForm
										expand
										isViewMode={isViewMode}
										contact={contact}
										setContact={setContact}
										formValidationState={formValidationState}
										onSuggestionSelected={onSuggestionSelected}
										onInputHandler={onInputHandler}
										disabled={isLoading}
										inputRefs={inputRefs}
										contactInputRefs={contactInputRefs}
									/>
								) : (
									<PersonForm
										expand
										isViewMode={isViewMode}
										contact={contact}
										setContact={setContact}
										formValidationState={formValidationState}
										onSuggestionSelected={onSuggestionSelected}
										onInputHandler={onInputHandler}
										disabled={isLoading}
										inputRefs={inputRefs}
										contactInputRefs={contactInputRefs}
										isEmailRequired={shareHolderStore.permissions !== CapTablePermission.no_access}
									/>
								)}
							</div>
							<div className="permissions">
								<Select
									label="Permissions"
									qaid="AddShareholder.Select.Permissions"
									options={permissionsOptions}
									value={shareHolderStore.permissions}
									onChange={(value) => isNumber(value) && shareHolderStore.setPermissions(value)}
									required
									isViewMode={isViewMode}
								/>
							</div>
						</div>
						{!isViewMode && (
							<Flex justify="end" gap="1rem" margin="6rem 0 0 0">
								<Button
									qaid="EditShareholder.Button.CancelEdit"
									autoWidth
									onClick={() => {
										onToggleUpdate();
										shareHolderStore.setPermissions(initialPermission);
									}}
									cancel
									label="Cancel"
								/>
								<Button qaid="EditShareholder.Button.SaveChanges" label="Save Changes" onClick={onUpdateContact} />
							</Flex>
						)}
					</div>
					<div className={`${EditShareholderStyle}__transactions-section mt-5 mb-5`}>
						<Collapse in={showAddTransaction} className="mb-4" unmountOnExit>
							<AddEditTransaction onCancel={clearEdit} transaction={editTransaction} />
						</Collapse>
						<div className="bold mb-4">All Transactions</div>
						<Table
							rows={splittedTransactions.table}
							columns={tableColumns}
							scrollAfterRows={5}
							renderBottom={
								!props.isView ? (
									<AddButton
										qaid="EditShareholder.Button.AddTransaction"
										label="Add transaction"
										onClick={() => {
											setShowAddTransaction(!showAddTransaction);
											setEditTransaction(undefined);
										}}
									/>
								) : undefined
							}
						/>
						{!!splittedTransactions.loans.length && (
							<Flex className={`${EditShareholderStyle}__loans-transactions-section mt-5 mb-5`} justify="start" direction="column" gap="2rem">
								{splittedTransactions.loans.map((transaction) => (
									<Flex key={transaction.transactionId} margin={0} justify="start" gap="1.2rem">
										<>
											{transaction.assetId === ExtraAssetTypes.ConvertibleLoan ? (
												<>
													<img className="mr-2" src={IC_IC_CONVERTIBLE_LOAN} alt="" />
													{renderLoanRow(transaction)}
												</>
											) : (
												<>
													<img className="mr-2" src={IC_SAFE} alt="" />
													{renderSafeRow(transaction)}
												</>
											)}
											{!props.isView && (
												<>
													<Clickable
														flex="0 0 2rem"
														qaid="EditShareholder.Button.EditTransaction"
														onClick={() => onEditTransaction(transaction)}
													>
														<img src={IC_EDIT_GRAY} alt="edit" width="100%" />
													</Clickable>
													<Clickable
														flex="0 0 2rem"
														qaid="EditShareholder.Button.DeleteTransaction"
														onClick={() => onDeleteTransaction(transaction)}
													>
														<img src={IC_TRASH2} alt="edit" width="100%" />
													</Clickable>
												</>
											)}
										</>
									</Flex>
								))}
							</Flex>
						)}
					</div>
					<Flex flex={0} justify="end" margin="auto 0 0 0">
						<Button qaid="EditShareholder.Button.Done" isLoading={isLoading} onClick={onCloseHandler} label="Done" />
					</Flex>
				</>
			)}
		</div>
	);
});

export default observer(EditShareholer);
