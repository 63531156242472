import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { EquityPlan } from '../../../../../../Models/App/EquityPlans/Company';
import Flex from '../../../../../../Shared/Components/Layout/Flex';
import NumberInput from '../../../../../../Shared/Components/Input/NumberInput';
import Button from '../../../../../../Shared/Components/Button/Button';
import { MultiStepElementProps, ForwardedRef } from '../../../../../../Shared/Hooks/useMultiStepForm';
import { NavButtons } from '../../Registration.Style';
import NewEquityPlanStyle from './NewEquityPlan.Style';
import useRootStore from '../../../../../../Shared/Hooks/useRootStore';
import { observer } from 'mobx-react-lite';
import { formatDate } from '../../../../../../Shared/Utilities';
import NewEquityPlan from '../../../Settings/Plan/NewEquityPlan/NewEquityPlan';
import useEsopModals from '../../../../useEsopModals';
import Spinner from '../../../../../../Shared/Components/Spinner/Spinner';
import SelectDataSource from '../../../Settings/SelectDataSource';
import { Routes } from '../../../../../../Routes';
import { useHistory } from 'react-router-dom';

const NewEquityPlanStep = forwardRef<ForwardedRef, MultiStepElementProps>((props, forwardedRef) => {
	const { equityPlansStore, companyStore, trusteeStore } = useRootStore();
	const { showErrorModal } = useEsopModals();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isCreated, setIsCreated] = useState<boolean>(false);
	const [loadedPlan, setLoadedPlan] = useState<EquityPlan>();
	const ref = useRef<ForwardedRef>(null);
	const history = useHistory();

	useImperativeHandle(forwardedRef, () => ({
		async onValidate() {
			return true;
		},
	}));

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const res = await equityPlansStore.GetEquitySettings();
			if (res?.plans?.[0]) {
				setLoadedPlan(res.plans[0]);
				setIsCreated(true);
			}
			setIsLoading(false);
		})();
	}, []);

	const createPlanHandler = async () => {
		if (companyStore.company.pivotalEquityPlans) return window.location.replace(Routes.trusteeData.index);
		if (isCreated) return props.onNextStep?.();
		const isFormValid = await ref.current?.onValidate();
		if (!isFormValid) return;

		const res = await equityPlansStore.AddEquityPlan();
		if (res?.error) return showErrorModal(res.errorMessage);
		props.onNextStep?.();
	};

	const plan = equityPlansStore.newEquityPlan;

	return (
		<div className={NewEquityPlanStyle}>
			{isLoading ? (
				<div className={`${NewEquityPlanStyle}__loading-spinner`}>
					<Spinner incorporated center />
				</div>
			) : (
				// TODO: check if already registered to Pivotal from cap temble
				//   registerd ? shot trustee dashboard : keep on registering
				<>
					<SelectDataSource />
					{companyStore.company.pivotalEquityPlans ? (
						<>
							<div className={`${NewEquityPlanStyle}__settingSection`}>
								<p>
									<span style={{ fontWeight: 600 }}>Summary:</span> as of {formatDate(trusteeStore.dataAsOfDate)}
								</p>
								<div style={{ display: 'flex', flexDirection: 'row' }}>
									<NumberInput qaid="trusteeTotalPool" value={trusteeStore.poolSummary.totalPool ?? 0} label="Pool" isViewMode />
									<NumberInput qaid="trusteeUnallocated" value={trusteeStore.poolSummary.unallocated ?? 0} label="Unallocated" isViewMode />
									<NumberInput qaid="trusteeAllocated" value={trusteeStore.poolSummary.allocated ?? 0} label="Allocated" isViewMode />
								</div>
							</div>
						</>
					) : (
						<NewEquityPlan registration data={loadedPlan} ref={ref} />
					)}
				</>
			)}
			<div className="buttonsContainer">
				<Flex justify="end" gap="1rem" className={NavButtons}>
					<Button qaid="Registration.Button.Next" className={'buttonsContainer__button'} onClick={createPlanHandler}>
						Next
					</Button>
				</Flex>
			</div>
		</div>
	);
});

export default observer(NewEquityPlanStep);
