import { IPaymenPlan } from '../../../../Models/App/PaymentModels';
import { PlanStyle } from './Plan.Style';
import Flex from '../../../../Shared/Components/Layout/Flex';
import { PaymentPlanEnum } from '../../../../Models/API/enums';
import Clickable from '../../../../Shared/Components/Clickable/Clickable';
import classNames from 'classnames';
import { IC_BRAND_LOGO_BLACK, IC_PAYMENT_PLAN_CHECK, STAKEHOLDERS } from '../../../../Assets';
import Image from '../../../../Shared/Components/Image';
import { isNullOrUndefined } from '../../../../Shared/Utilities';
import { getPlanData } from '../utils/utils';
import Tooltip from '../../../../Shared/Components/Tooltip';
import useRootStore from '../../../../Shared/Hooks/useRootStore';
import useRecaptcha from '../../../../Shared/Hooks/useRecaptcha';
import React from 'react';

interface IProps {
	readonly plan: IPaymenPlan;
	readonly focus?: boolean;
	readonly disabled?: boolean;
	readonly carouselView?: boolean;
	readonly onSelectPlan: (plan: IPaymenPlan, recaptchaKey: string) => Promise<void>;
	maxNumOfStakeholders?: number;
}

const Plan = (props: IProps) => {
	const { execute } = useRecaptcha();
	const { auth, companyStore } = useRootStore();
	const planData = getPlanData(props.plan.code);

	const getQaid = () => {
		switch (props.plan.code) {
			case PaymentPlanEnum.Starter:
				return 'Plan.Button.Starter';
			case PaymentPlanEnum.Growth:
				return 'Plan.Button.Growth';
			case PaymentPlanEnum.Scale:
				return 'Plan.Button.Scale';
			case PaymentPlanEnum.Custom:
				return 'Plan.Button.Custom';
			case PaymentPlanEnum.Enterprise:
				return 'Plan.Button.Enterprise';
			default:
				return '';
		}
	};

	const onSelectPlan = async (plan: IPaymenPlan) => {
		execute(async (recaptchaKey) => {
			await props.onSelectPlan(plan, recaptchaKey);
		});
	};

	return (
		<div
			className={classNames(PlanStyle, { focus: props.focus, disabled: props.disabled, carousel: props.carouselView })}
			onClick={props.carouselView || props.disabled ? () => null : () => onSelectPlan(props.plan)}
		>
			{props.focus && (
				<span className="focusHeader">{!auth.isUserActive || companyStore.isInCompanyCreationProcess ? 'Most Popular' : 'Current Plan'}</span>
			)}
			<Flex className="topContainer">
				<span className="topContainer__title">{planData.name}</span>
				<span className="topContainer__description">{planData.desc}</span>

				{props.plan.code === PaymentPlanEnum.Custom ? (
					<Image className="topContainer__brandLogo" src={IC_BRAND_LOGO_BLACK} alt="brand logo" />
				) : (
					<Flex flex={0} align="center" margin="0.7rem 0 0 0">
						<span className="topContainer__price">$ {props.plan.code === PaymentPlanEnum.Starter ? 0 : props.plan.price}</span>
						<span className="topContainer__month">/month</span>
					</Flex>
				)}
				<span className="topContainer__chargeType">
					{props.plan.code === PaymentPlanEnum.Starter ? 'Free' : props.plan.code === PaymentPlanEnum.Custom ? '' : 'Annual Subscription'}
				</span>
			</Flex>
			<Flex className="middleContainer" flex={0}>
				<Clickable
					qaid={getQaid()}
					className={classNames('buttonContainer', { disabled: props.disabled })}
					width={'fit-content'}
					flex={0}
					onClick={props.carouselView && !props.disabled ? () => onSelectPlan(props.plan) : () => null}
				>
					<span className="buttonContainer__linearBorder">
						{props.plan.code === PaymentPlanEnum.Starter
							? 'Start For Free'
							: props.plan.code === PaymentPlanEnum.Custom
							? 'Contact Us'
							: 'Get Started'}
					</span>
					<span>
						{props.plan.code === PaymentPlanEnum.Starter
							? 'Start For Free'
							: props.plan.code === PaymentPlanEnum.Custom
							? 'Contact Us'
							: 'Get Started'}
					</span>
				</Clickable>
			</Flex>
			<hr className="divider" />
			<Flex className="bottomContainer">
				{props.maxNumOfStakeholders && (
					<div className="stakeholdersContainer">
						<Image className="stakeholdersContainer__icon" src={STAKEHOLDERS} alt="stakeholders" />
						<span>{`${props.maxNumOfStakeholders} stakeholders included`}</span>
						<Tooltip className="stakeholdersContainer__tooltip" title={'Any holder of shares, options, RSU, warrants, SAFE and convertible note'} />
					</div>
				)}
				<span className="bottomContainer__title">{planData.title}</span>
				<Flex justify="start" className="innerFeatures" direction="column">
					{planData.features.map((feature, i) =>
						feature.custom ? (
							<React.Fragment key={i}>{feature.custom()}</React.Fragment>
						) : (
							<Flex key={i} className="featureContainer" flex={0}>
								<Image src={IC_PAYMENT_PLAN_CHECK} alt="check" className="featureContainer__icon" />
								<span className="featureContainer__text">{feature.label}</span>
								{!isNullOrUndefined(feature.tooltip) && <Tooltip className="featureContainer__tooltip" title={feature.tooltip} />}
							</Flex>
						)
					)}
				</Flex>
			</Flex>
			<span className="bottomContainer__footerText">{planData.footerText}</span>
		</div>
	);
};

export default Plan;
