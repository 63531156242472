export type SummaryData = {
	waterfallName: string;
	businessValue: number;
	totalShareClasses: TotalShareClasses;
	DLOM: number;
	commonShareFairValuePerShare: number;
	commonShareFairValueAfterDiscount: number;
	commonShareFairValues: VolatilityChart[];
	shareClassValuation: ShareClassValuation[];
	shareClassValuationTable: ShareClassValuationTable[];
};

export type ShareClassValuationTable = {
	shareClass: string;
	numberOfSharesOutstanding: number;
	AmountOfSharesAsConvertedRatio: number;
	totalShareClassValue: number;
	valuePerOneShareClass: number;
	liquidationPreferenceComponent: number;
	issuePricePerShare: number;
	commonShareComponent: number;
	isCommon: boolean;
};

export type ShareClassValuation = {
	totalShareClassValuation: number;
	breakPoints: Breakpoint[];
	id: number;
	name: string;
	preferenceSummary: string;
	totalShareClassOutOfClassesTotal_CommonShareEquivalentPercentage: number;
	shares: number;
	originalInvestment: number;
	isoption: boolean;
	seniorityLevel: number;
	issuePricePerShare: number;
	liquidationPreferenceComponent: number;
	commonShareComponent: number;
};

export type TotalShareClasses = {
	commonShareEqivalent: number;
	investment: number;
	numberOfSharesOutstanding: number;
	numberOfWarrants: number;
	optionPlansOutstandingOptions: number;
	shares_Warrants_Options_FullyDiluted: number;
	commonShareEquivalentNoWarrants: number;
	shareTotalSummaryCalculations: ShareTotalSummaryCalculations[];
};

export type ShareTotalSummaryCalculations = {
	exitValue: number;
	irrValue: null | number;
	isOptions: boolean;
	shareClassID: number;
	shares: number;
	totalShareValue: number;
	investmentMultiple: number;
};

export type Breakpoint = {
	rangeFrom: number;
	rangeTo: number;
	commonShareComponent: number | null;
	currentPayoff: number;
	isBinaryOption: boolean;
	isLPPart: boolean;
	joinedLPAndCommonComponent: null;
	liquidationPreferenceComponent: number | null;
	proRataValue: number;
	shareClassID: number;
	waterfallDescription: string;
	isEndOfRange: boolean;
	percent: string;
	shareClassValue: number;
	allShareClassesValue: number;
};

export type VolatilityChart = {
	volatility: number;
	commonShareFairValue: number;
	isDashed: null;
	type: VolatilityChartPointType;
};

export enum VolatilityChartPointType {
	Min,
	Max,
	ChosenVolatility,
	None,
}
