import { IC_LOOKUP } from '../../../../../../../Assets';
import appConfig from '../../../../../../../config/config';
import { NumberOptionDescription } from '../../../../../../../Models/API/All/NumberOption';
import { VolatilityAndMultiple, WaterfallPeer } from '../../../../../../../Models/API/Waterfall/IForm';
import { HigherLevelResponse, HighLevelErrorResponse } from '../../../../../../../Services/Axios';
import AutoCompleteInput from '../../../../../../../Shared/Components/Input/AutoCompleteInput';
import Tooltip from '../../../../../../../Shared/Components/Tooltip';
import { isString } from '../../../../../../../Shared/Utilities';
import useVolatilityTooltip from '../../../../../utils/useVolatilityTooltip';

type Props = {
	peers?: WaterfallPeer[];
	addPeer: (identifier: string, isExists?: boolean) => Promise<HighLevelErrorResponse | HigherLevelResponse<VolatilityAndMultiple> | undefined>;
};

const PeerSearch = ({ peers, addPeer }: Props) => {
	const options: NumberOptionDescription[] =
		peers?.map((peer) => ({
			value: peer.identifier,
			label: <PeerTooltip {...peer} />,
			description: [peer.ticker, peer.companyName],
		})) || [];

	return (
		<AutoCompleteInput
			qaid="Volatility.Input.SearchPeer"
			options={options}
			onChange={(value) => {
				isString(value) && addPeer(value);
			}}
			value={undefined}
			placeholder="Search..."
			label="Type peer ticker or company name"
			endIcon={IC_LOOKUP}
			containerClassName="search-input"
			sort={(a, b, text) => {
				text = text.toLocaleLowerCase();
				const aTicker = a.description?.[0]?.toLowerCase() ?? '';
				const bTicker = b.description?.[0]?.toLowerCase() ?? '';
				const aName = a.description?.[1]?.toLowerCase() ?? '';
				const bName = b.description?.[1]?.toLowerCase() ?? '';

				const isFirstTickerStarts = aTicker.startsWith(text);
				const isSecondTickerStarts = bTicker.startsWith(text);

				if (isFirstTickerStarts && isSecondTickerStarts) return aTicker < bTicker ? -1 : 1;

				if (isFirstTickerStarts) return -1;
				if (isSecondTickerStarts) return 1;

				const isFirstCompanyStarts = aName.startsWith(text);
				const isSecondCompanyStarts = bName.startsWith(text);

				if (isFirstCompanyStarts && isSecondCompanyStarts) return aName < bName ? -1 : 1;

				if (isFirstCompanyStarts) return -1;
				if (isSecondCompanyStarts) return 1;

				const isFirstTickerContains = aTicker.includes(text);
				const isSecondTickerContains = bTicker.includes(text);

				if (isFirstTickerContains && isSecondTickerContains) return aTicker < bTicker ? -1 : 1;

				if (isFirstTickerContains) return -1;
				if (isSecondTickerContains) return 1;

				const isFirstCompanyContains = aName.includes(text);
				const isSecondCompanyContains = bName.includes(text);

				if (isFirstCompanyContains && isSecondCompanyContains) return aName < bName ? -1 : 1;

				if (isFirstCompanyContains) return -1;
				if (isSecondCompanyContains) return 1;

				return 0;
			}}
		/>
	);
};

const PeerTooltip = (peer: WaterfallPeer) => {
	const { getTooltip, onTooltipEnter, onTooltipLeave, tooltipData } = useVolatilityTooltip();
	return (
		<Tooltip
			title={getTooltip(peer, tooltipData)}
			placement="right"
			onMouseEnter={() => {
				onTooltipLeave(peer.stockId);
				onTooltipEnter(peer.stockId);
			}}
		>
			<div className="w-100 flex justify-between text-line-0">
				<span className="text-ellipsis">
					{peer.companyName} ({peer.ticker})
				</span>
				<span>Add</span>
			</div>
		</Tooltip>
	);
};

export default PeerSearch;
