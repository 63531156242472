export enum OptionsPermission {
    NotAllowed = 0, // no access
    admin = 1,
    editor = 2, // editor
    Viewer = 3, // viewer
}

export enum CapTablePermission {
    admin = 1,
    editor = 2, // editor
    full_access = 3, // viewer
    see_only_mine = 4,
    no_access = 5, // no access
}

export enum FundingPermission {
    NotAllowed = 0,
    admin = 1,
    Owner = 2,
    Contributer = 3,
    Downloader = 4,
    Viewer = 5,
    General = 6,
}

export enum WaterfallPermission {
    admin = 1,
    editor = 2, // editor
    full_access = 3, // viewer
    see_only_mine = 4,
    no_access = 5, // no access
}

export enum DataRoomsPermission {
    NotAllowed = 0,
    admin = 1,
    Owner = 2,
    Contributer = 3,
    Downloader = 4,
    Viewer = 5,
    General = 6,
}

export enum FourONinePermissions {
	NotAllowed = 0,
	Admin = 1,
	Editor = 2,
	FullAccess = 3,
	SeeOnlymMine = 4,
	NoAccess = 5,
	Chief = 6,
	Valuation = 7,
}

export enum ExpensingPermission {
    NotAllowed = 0,
    Admin = 1,
    Editor = 2,
    Viewer = 3,
}

export enum EmployeeManagementPermission {
    NotAllowed = 0,
    admin = 1,
    Owner = 2,
    Contributer = 3,
    Downloader = 4,
    Viewer = 5,
    General = 6,
}

export enum CfoPermission {
    NotAllowed = 0,
    admin = 1,
    Owner = 2,
    Contributer = 3,
    Downloader = 4,
    Viewer = 5,
    General = 6,
}

export enum ShareRepPermission {
    NotAllowed = 0,
    Viewer = 1,
}
