import CloseIcon from '@mui/icons-material/Close';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ProjectsUserInfo } from '../../../../../Models/API/CapTable';
import { ProductTypeEnum } from '../../../../../Models/API/enums';
import { CapTableData, CapTableProjectResponse, CapTableRequest } from '../../../../../Models/API/Waterfall/IForm';
import { Routes } from '../../../../../Routes';
import { WaterfallStep } from '../../../../../Screens/Waterfall/Components/AddEditWaterfall/index.style';
import { HigherLevelResponse, HighLevelErrorResponse } from '../../../../../Services/Axios';
import useGeneralModal from '../../../../Hooks/useGeneralModal';
import useModal from '../../../../Hooks/useModal';
import useRootStore from '../../../../Hooks/useRootStore';
import Spinner from '../../../Spinner/Spinner';
import ShareClassWarning from './ShareClassWarning';
import ImportModalWrapper from './index.style';
import SelectImportSource from './SelectImportSource';
import WaterfallTable from './WaterfallTable';

interface CapTableProps {
	waterfallId: number;
	getCapTableBases: (companyId: number) => Promise<HighLevelErrorResponse | HigherLevelResponse<CapTableProjectResponse>>;
	getSavedCapTableBase: (companyId: number, waterfallId: number) => Promise<HighLevelErrorResponse | HigherLevelResponse<CapTableData>>;
	getSingleCapTableData: (payload: CapTableRequest) => Promise<HighLevelErrorResponse | HigherLevelResponse<CapTableData>>;
	isViewMode?: boolean;
	isCurrentStep: boolean;
}

const CapTable = React.forwardRef<any, CapTableProps>((props, forwardedRef) => {
	const { companyStore } = useRootStore();
	const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const { showErrorModal } = useGeneralModal();
	const { showAsyncModal } = useModal();
	const [selectedCapTableBase, setSelectedCapTableBase] = useState<ProjectsUserInfo>();
	const [capTableBases, setCapTableBases] = useState<ProjectsUserInfo[]>();
	const [showSelectImportSource, setShowSelectImportSource] = useState<boolean>();
	const [showChangeModal, setShowChangeModal] = useState<boolean>();
	const [singleCapTableData, setSingleCapTableData] = useState<CapTableData>();
	const history = useHistory();

	useImperativeHandle(forwardedRef, () => ({
		async onValidate() {
			const shareClassWarningTreshhold = 13;
			return props.isCurrentStep && singleCapTableData && singleCapTableData.shareClass.length >= shareClassWarningTreshhold
				? showAsyncModal({
						body: (props) => (
							<ShareClassWarning
								treshhold={shareClassWarningTreshhold}
								onContinue={props.onConfirmHandler!}
								onConfirm={() => history.push(Routes.capTable.index + `?projectId=${singleCapTableData?.projectId}`)}
							/>
						),
						width: '65rem',
						showClose: true,
				  })
				: !!singleCapTableData;
		},
	}));

	useEffect(() => {
		const fetchData = async () => {
			const { companyId } = companyStore.company;
			if (!companyId || companyStore.isCreatingCompanyFromCompany) return;

			setIsLoading(true);
			const res = await Promise.all([props.getCapTableBases(companyId), props.getSavedCapTableBase(companyStore.companyId, props.waterfallId)]);
			setIsLoading(false);

			if (res[0].data && res[0].data.projectsUserList?.length) {
				setSelectedCapTableBase(res[0].data?.projectsUserList.find((proj) => proj.isPublished));
				setCapTableBases(res[0].data?.projectsUserList);
			}

			if (res[0].error) {
				const errorMsg = 'Failed to fetch Cap Table Bases';
				setErrorMessage(errorMsg);
				showErrorModal(errorMessage, 'Error');
			}

			setSingleCapTableData(res[1].data);
			setShowSelectImportSource(res[1].data?.captableImportDate === null);
			if (!res[1].isSuccess) {
				const errorMsg = 'Failed to fetch Saved Cap Table Base';
				setErrorMessage(errorMsg);
				showErrorModal(errorMessage, 'Error');
			}
		};

		fetchData();
		return () => setIsLoading(false);
	}, [companyStore.company.companyId]);

	const handleImportBase = async () => {
		setIsLoading(true);
		if (!selectedCapTableBase) return;
		setShowChangeModal(false);
		const res = await props.getSingleCapTableData({
			companyId: companyStore.company.companyId,
			waterfallId: props.waterfallId,
			projectId: selectedCapTableBase.projectID,
		});
		setSingleCapTableData(res.data);
		setIsLoading(false);
		if (res?.error) {
			showErrorModal('An error occurred while trying to import cap. table data');
		} else {
			setShowSelectImportSource(false);
		}
	};

	const drafts = capTableBases?.reduce(
		(acc, proj) => {
			if (proj.isPublished) return acc;
			if (proj.productType === ProductTypeEnum.CapTable) {
				acc.capTable.push(proj);
			} else if (proj.productType === ProductTypeEnum.NextRound) {
				acc.funding.push(proj);
			}
			return acc;
		},
		{ capTable: [], funding: [] } as { capTable: ProjectsUserInfo[]; funding: ProjectsUserInfo[] }
	);

	const getImportSourceElement = useCallback(() => {
		return (
			<SelectImportSource
				drafts={drafts}
				selected={selectedCapTableBase}
				publishedCapTable={capTableBases?.find((proj) => proj.isPublished)}
				onSelect={(data) => setSelectedCapTableBase(data)}
				onImport={handleImportBase}
				isViewMode={props.isViewMode}
			/>
		);
	}, [selectedCapTableBase, capTableBases]);

	return (
		<WaterfallStep ref={forwardedRef}>
			{showChangeModal && (
				<ImportModalWrapper>
					<ImportModalWrapper.Body>
						{getImportSourceElement()}
						<ImportModalWrapper.Close onClick={() => setShowChangeModal(false)}>
							<CloseIcon style={{ width: '2rem', height: '2rem' }} />
						</ImportModalWrapper.Close>
					</ImportModalWrapper.Body>
				</ImportModalWrapper>
			)}
			{isLoading ? (
				<Spinner incorporated center />
			) : showSelectImportSource ? (
				<SelectImportSource
					drafts={drafts}
					selected={selectedCapTableBase}
					publishedCapTable={capTableBases?.find((proj) => proj.isPublished)}
					onSelect={(data) => setSelectedCapTableBase(data)}
					onImport={handleImportBase}
					isViewMode={props.isViewMode}
				/>
			) : (
				<WaterfallTable
					onChangeSource={() => setShowChangeModal(true)}
					singleCapTableData={singleCapTableData}
					baseProductType={capTableBases?.find((base) => base.projectID === singleCapTableData?.projectId)?.productType}
				/>
			)}
		</WaterfallStep>
	);
});

export default observer(CapTable);
