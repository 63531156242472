import { useEffect, useState } from 'react';
import { SummaryStatus, SummaryStatusCode } from './types';

type Props = {
	onSetSummary: () => void;
	onGetSummaryStatus: () => Promise<SummaryStatus | undefined>;
	onRunSummary: () => Promise<unknown>;
	onGetSummary: () => Promise<unknown>;
};

const INTERVAL_MS = 6000;

export const useSummary = ({ onSetSummary, onGetSummaryStatus, onRunSummary, onGetSummary }: Props) => {
	const [isTimeout, setIsTimeout] = useState<boolean>(false);
	const [status, setStatus] = useState<SummaryStatus>();

	useEffect(() => {
		handleSummaryStatus();
		return () => {
			setStatus(undefined);
			setIsTimeout(false);
		};
	}, []);

	const handleSummaryStatus = async (isRetry: boolean = false) => {
		setIsTimeout((isError) => {
			// inside setIsTimeout to get the current value
			(async () => {
				if (isError && !isRetry) return;
				onSetSummary();

				const summaryStatus = await onGetSummaryStatus();
				if (!summaryStatus) return;
				setStatus({ ...summaryStatus, timeStamp: summaryStatus.timeStamp || +new Date() / 1000 });

				switch (summaryStatus.status) {
					case SummaryStatusCode.PENDING:
						onRunSummary();
						setTimeout(handleSummaryStatus, INTERVAL_MS);
						break;
					case SummaryStatusCode.RUNNING:
						setTimeout(handleSummaryStatus, INTERVAL_MS);
						break;
					case SummaryStatusCode.FINISHED:
						setTimeout(onGetSummary, 1000);
						break;
					case SummaryStatusCode.ERROR:
						break;
					default:
						break;
				}
			})();
			return isError && !isRetry;
		});
	};

	return {
		status,
		setIsTimeout,
		handleSummaryStatus,
	};
};
