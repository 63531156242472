import { ReactChild, ReactElement, cloneElement, isValidElement } from "react";
import StyledSelect from "./Select.Style";
import classNames from "classnames";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import Flex from "../Layout/Flex";
import { isArray } from "../../Utilities";

interface Props {
	isOpen: boolean;
	children: ReactChild;
	onClick: (e: React.MouseEvent) => void;
	qaid: string;
	className?: string;
	isLoading?: boolean;
}

const CustomSelect = ({ isOpen, children, onClick, qaid, className = "", isLoading }: Props) => {
	return (
		<StyledSelect.Select
			data-qaid={qaid}
			onClick={onClick}
			className={classNames({ [className]: !!className, open: isOpen })}
		>
			<div className="wrapper h-100">
				<div className="value">
					{isValidElement(children) &&
						cloneElement(children as ReactElement, { className: "h-100 flex align-center" })}
				</div>
				{isLoading ? (
					<CircularProgress className="loading-spinner" size={14} />
				) : (
					<div className={`arrow ${isOpen ? "up" : "down"}`}></div>
				)}
			</div>
		</StyledSelect.Select>
	);
};

export default CustomSelect;
