import { css } from '@emotion/css';
import appConfig from '../../../../config/config';

const MainMenuStyle = css({
	label: 'DataCollectionMenu',
	'&__version-select': {
		maxWidth: '37rem',
		'*': {
			color: appConfig.style.colors.text1,
		},
		'.published': {
			color: appConfig.style.colors.orange,
		},
		'.draft': {
			color: appConfig.style.colors.text4,
		},
	},
	'&__menu': {
		height: '7.1rem',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		padding: '0 4rem',
		boxShadow: `3px 3px 3px 0px ${appConfig.style.colors.boxShadowColor1}`,
		position: 'relative',
	},
	'&__menu-side-a': {
		display: 'flex',
		flexDirection: 'row',
		flex: 1,
		gap: '2.6rem',
		position: 'relative',
	},
	'&__projects-container': {
		position: 'absolute',
		zIndex: 5,
		top: 'calc(100% - 4px)',
		minWidth: '90rem',
	},
});

export default MainMenuStyle;
