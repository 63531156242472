import { useEffect, useState } from 'react';
import { Reviewer, ReviewersResponse, ToggleReviewerRequest, WaterfallDefaultParams } from '../../../../../Models/API/Waterfall/IForm';
import { HigherLevelResponse, HighLevelErrorResponse } from '../../../../../Services/Axios';
import useRootStore from '../../../../Hooks/useRootStore';
import Reviewers from '.';

type ApiResponse = Promise<HighLevelErrorResponse | HigherLevelResponse<ReviewersResponse>>;

type Props = {
	waterfallId: number;
	getProjectReviewers: (payload: WaterfallDefaultParams) => ApiResponse;
	toggleProjectReviewer: (payload: ToggleReviewerRequest) => ApiResponse;
};

export const useReviewers = ({ waterfallId, getProjectReviewers, toggleProjectReviewer }: Props) => {
	const {
		companyStore: { companyId },
	} = useRootStore();
	const [reviewers, setReviewers] = useState<Reviewer[]>();
	const [isTogglingReviewer, setIsTogglingReviewer] = useState<boolean>(false);

	useEffect(() => {
		if (!waterfallId) return;
		fetchReviewers();
		return () => setReviewers(undefined);
	}, [waterfallId]);

	const fetchReviewers = async () => {
		const res = await getProjectReviewers({
			companyId,
			waterfallId,
		});
		res.data && setReviewers(res.data.users);
	};

	const onToggle = async (id: number) => {
		if (isTogglingReviewer) return;
		setIsTogglingReviewer(true);
		const res = await toggleProjectReviewer({
			waterfallId,
			companyId,
			participantUserId: id,
		});
		res.data && setReviewers(res.data.users);
		setIsTogglingReviewer(false);
	};

	return {
		ReviewersComponent: <Reviewers reviewers={reviewers} onToggle={onToggle} isLoading={isTogglingReviewer} />,
	};
};
