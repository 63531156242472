import _ from 'underscore';
import { OperationSaveMode } from '../Models/API/All/operation-save-mode';
import {
	CapTableProjectDuplicateSubmitInfo,
	IssueTransactionSubmitInfo,
	LoadPersonSubmitInfo,
	OptionTransactionInfo,
	OptionTransactionSubmitInfo,
	ProjectPersonInfo,
	ProjectSubmitInfo,
	ProjectsUserInfo,
	SafeTransactionSubmitInfo,
	SecondaryTransactionSubmitInfo,
	ShareClassInfo,
	ShareClassSubmitInfo,
} from '../Models/API/CapTable';
import { AvailableAmountsByShareClassSubmitInfo } from '../Models/API/CapTable/available-amounts-by-share-class-submit-info';
import { AvailableAmountsResultInfo } from '../Models/API/CapTable/available-amounts-result-info';
import { AvailableAmountsSubmitInfo } from '../Models/API/CapTable/available-amounts-submit-info';
import { AvailableAwardsSubmitInfo } from '../Models/API/CapTable/available-awards-submit-info';
import { LoadOptionTransactionResultInfo } from '../Models/API/CapTable/load-option-transaction-result-info';
import { SaveUserPermissionSubmitInfo } from '../Models/API/CapTable/save-user-permission-submit-info';

import {
	AppProducts,
	CapTableProductType,
	ePreRoundDataType,
	OptionTransactionTypesEnum,
	OptionUpdateModeTypeEnum,
	PersonTypesEnum,
	TransactionTypesEnum,
	WarrantsType,
} from '../Models/API/enums';

import { ProjectPreviewInfo } from '../Models/API/CapTable/project-preview-info';
import { ProjectResultInfo } from '../Models/API/CapTable/project-result-info';
import { ProjectUserResultInfo } from '../Models/API/CapTable/project-user-result-info';

import { rootStore } from '..';
import ExistingUser, { ExistingUserExtended } from '../Models/API/All/existing-user';
import { UserPersonalInformation } from '../Models/API/All/user-personal-information';
import { AddUserToOptionsAndCapTableRequest } from '../Models/API/CapTable/add-user-to-options-and-cap-table-request';
import { AssetsListResponse } from '../Models/API/CapTable/AssetsListResponse';
import { AvailableAwardsResultInfo } from '../Models/API/CapTable/available-awards-result-info';
import { AvailableOptionResultInfo } from '../Models/API/CapTable/available-option-result-info';
import { AvailableOptionSubmitInfo } from '../Models/API/CapTable/available-option-submit-info';
import { CapTableUserPermissions } from '../Models/API/CapTable/captable-user-permissions';
import { ConvertibleLoanTransactionSubmitInfo } from '../Models/API/CapTable/convertible-loan-transaction-submit-info';
import { FileModel } from '../Models/API/CapTable/file-model';
import { FinancialRound, FinancialRoundResponse, ProjectPreviewInfo_Funding } from '../Models/API/CapTable/financial-round-response';
import { FundingSaveProductType } from '../Models/API/CapTable/funding-save-draft-request';
import { GetUserProjectPersonResponse } from '../Models/API/CapTable/get-user-project-person-response';
import { GrantNumberResultInfo } from '../Models/API/CapTable/grant-number-result-info';
import { LoadPersonResultInfo } from '../Models/API/CapTable/load-person-result-info';
import { OptionTransactionResultInfo } from '../Models/API/CapTable/OptionTransactionResultInfo';
import { SplitTableData } from '../Models/API/CapTable/split-table-data';
import { WarrantsTransactionSubmitInfo } from '../Models/API/CapTable/warrants-transaction-submit-info';
import { IPivotalCompany } from '../Models/App';
import { AddShareholderResult2 } from '../Models/App/CapTable/AddShareHolderState';
import { ICommonApiRequestValues } from '../Models/App/DataRoom/ICommonApiRequestValue';
import { BaseService } from './BaseService';
import { GetInternalDashBoardCapTableReq } from '../Models/App/CapTable/dashboard';
import { IExternalCapTableDashboard } from '../Models/API/CapTable/dashboard-cap-table';
import { ICapTableUpdates } from '../Models/API/CapTable/cap-table-updates';
import { ContentTypeEnum, loginApi } from './Axios';
import { ImportCapTable } from '../Models/App/CapTable/ImportCapTable';
import { CapTablePermission } from '../Models/API/UsersAndPermissions/permissions-enum';
import { CreateShareCertificateType } from '../Models/API/CapTable/document-types';
import { CreateShareCertificateResult } from '../Models/API/CapTable/share-certificate';

export interface ExistingUserAction {
	createUser?: ExistingUser;
	mergeUser?: ExistingUserExtended;
}

export default class CapTableService extends BaseService {
	endpoints = {
		CreateNewProject: 'CapTable/CreateNewProject',
		GetInternalDashBoardCapTable: 'CapTable/InternalDashBoardCapTable',
		GetExternalDashBoardCapTable: 'CapTable/ExternalDashBoardCapTable',
		GetFundingDashboard: 'CapTable/DashboardFunding',
		GetFundingSnapshot: 'CapTable/GetFundingSnapshot',

		SaveCompanGetAllProjectsByUseryData: 'CapTable/SaveCompanGetAllProjectsByUseryData',
		SilentRegisterSecondaryUser: 'CapTable/SilentRegisterSecondaryUser',
		UpdateProjectBasic: 'CapTable/UpdateProjectBasic',

		AddShareClass: 'CapTable/AddShareClass2',
		UpdateShareClass: 'CapTable/UpdateShareClass2',
		RemoveShareClass: 'CapTable/RemoveShareClass2',

		AddPerson: 'CapTable/AddPerson2',
		UpdatePerson: 'CapTable/UpdatePerson2',
		RemovePerson: 'CapTable/RemovePerson2',
		LoadProjectPersonPreview: 'CapTable/LoadProjectPersonPreview',
		LoadProjectPerson: 'CapTable/LoadProjectPerson',

		AddShareHolderIssueTransaction: 'CapTable/AddShareHolderIssueTransaction2',
		UpdateShareHolderIssueTransaction: 'CapTable/UpdateShareHolderIssueTransaction2',
		RemoveShareHolderIssueTransaction: 'CapTable/RemoveShareHolderIssueTransaction2',

		AddShareHolderSecondaryTransaction: 'CapTable/AddShareHolderSecondaryTransaction2',
		UpdateShareHolderSecondaryTransaction: 'CapTable/UpdateShareHolderSecondaryTransaction2',
		RemoveShareHolderSecondaryTransaction: 'CapTable/RemoveShareHolderSecondaryTransaction2',

		AddShareHolderWarrantsTransaction: 'CapTable/AddShareHolderWarrantsTransaction2',
		UpdateShareHolderWarrantsTransaction: 'CapTable/UpdateShareHolderWarrantsTransaction2',
		RemoveShareHolderWarrantsTransaction: 'CapTable/RemoveShareHolderWarrantsTransaction2',

		AddSafeTransaction: 'CapTable/AddSafeTransaction2',
		UpdateSafeTransaction: 'CapTable/UpdateSafeTransaction2',
		RemoveSafeTransaction: 'CapTable/RemoveSafeTransaction2',

		AddConvertableLoanTransaction: 'CapTable/AddConvertableLoanTransaction2',
		UpdateConvertableLoanTransaction: 'CapTable/UpdateConvertableLoanTransaction2',
		RemoveConvertableLoanTransaction: 'CapTable/RemoveConvertableLoanTransaction2',

		LoadSingleUserPermissions: 'CapTable/LoadSingleUserPermissions',
		LoadCompanyAllUserPermissions: 'CapTable/LoadCompanyAllUserPermissions',
		SaveUserPermissions: 'CapTable/SaveUserPermissions',
		PreRegisterSecondaryUser: 'CapTable/PreRegisterSecondaryUser',
		GetRanges: 'CapTable/GetRanges',

		FinancingRound: 'CapTable/FinancingRound',

		GetAllProjectsByUser: 'CapTable/GetAllProjectsByUser',
		LoadCapTableProject: 'CapTable/LoadCapTableProjectPreview',
		DeleteProject: 'CapTable/DeleteProject',
		GetUserPermission: 'CapTable/GetUserPermission',
		DuplicateCapTableAsCapTable: 'CapTable/DuplicateCapTableAsCapTable',
		DuplicateCapTableAsNextRound: 'CapTable/DuplicateCapTableAsNextRound',
		LoadCapTableForFundingRound: 'CapTable/LoadCapTableForFundingRound',
		SaveSnapshotAsCaptable: 'CapTable/SaveSnapshotAsCaptable',
		SaveCapTableResultAsDraft: 'CapTable/SaveCapTableResultAsDraft',

		// OverrideOptionTransaction: "CapTable/OverrideOptionTransaction2",
		AddOptionTransaction: 'CapTable/AddOptionTransaction2',
		UpdateOptionTransaction: 'CapTable/UpdateOptionTransaction2',
		RemoveOptionTransaction: 'CapTable/RemoveOptionTransaction2',
		LoadOptionTransaction: 'CapTable/LoadOptionTransaction',

		AvailableAmounts: 'CapTable/AvailableAmounts',
		AvailableAmountsByShareClass: 'CapTable/AvailableAmountsByShareClass',
		AvailableAwards: 'CapTable/AvailableAwards',
		AvailableOptionTransactionByType: 'CapTable/AvailableOptionTransactionByType',
		GetGrantNumberList: 'CapTable/GetGrantNumberList',
		GetUserProjectPerson: 'CapTable/GetUserProjectPerson',
		LoadOptionTransactionTotals: 'CapTable/LoadOptionTransactionTotals',
		OverrideOptionManualTransaction: 'CapTable/OverrideOptionManualTransaction',

		GetAssetsSold: 'CapTable/GetAssertSold',

		RemoveSplitCapTable: 'CapTable/RemoveSplitCapTable',
		SplitCapTable: 'CapTable/SplitCapTable',
		UpdateSplitCapTable: 'CapTable/UpdateSplitCapTable',

		LoadShareClassDocumentLink: 'CapTable/LoadShareClassDocumentLink',

		getcompanySync: (companyId: number) => `Company/sync/${companyId}`,
		UpdatePivotalcompany: (companyId: number) => `Company/${companyId}`,
		UpdateProjectOptionMode: 'CapTable/UpdateProjectOptionMode',
		GetHistoryCapTablePublished: 'CapTable/GetHistoryCapTablePublished',

		import: 'Import',
		saveImportedCapTable: 'CapTable/ImportCapTable',
	};
	commonValues: ICommonApiRequestValues;

	constructor(companyID: number) {
		super();
		this.commonValues = {
			companyID: companyID,
		};
	}
	// public getUsersPermission(projectID: number) {
	//   return this.safeExecuteAsync(async () => {
	//     return (
	//       await this.httpLogin.post<CapTableUserPermissions>(this.endpoints.GetUsersPermission, {
	//         ...this.commonValues,

	//         projectID: projectID,
	//       })
	//     ).data;
	//   });
	// }

	public updateProjectDetails(projectID: number, projectName: string) {
		return this.safeExecuteAsync(async () => {
			return (
				await this.httpLogin.post<GetUserProjectPersonResponse>(this.endpoints.UpdateProjectBasic, {
					...this.commonValues,
					projectID,
					projectName,
				})
			).data;
		});
	}

	// Returns prject person by user id
	public getUserProjectPerson(sourceUserID: number, projectID: number) {
		return this.safeExecuteAsync(async () => {
			return (
				await this.httpLogin.post<GetUserProjectPersonResponse>(this.endpoints.GetUserProjectPerson, {
					...this.commonValues,
					person: {
						sourceUserId: sourceUserID,
					},
					projectID: projectID,
				})
			).data;
		});
	}
	public GetAllProjectsByUser(sourceUserID: number, productType?: CapTableProductType) {
		return this.safeExecuteAsync(async () => {
			return (
				await this.httpLogin.post<ProjectUserResultInfo>(this.endpoints.GetAllProjectsByUser, {
					...this.commonValues,
					sourceUserID: sourceUserID,
					productType: productType,
				})
			).data;
		});
	}

	getSingleProject(projectId: number) {
		return loginApi.get<ProjectsUserInfo>(`/CapTable/GetProjectInfo/${projectId}`);
	}

	async setPivotalcompany(companyId: number, company: IPivotalCompany, token: string, reCaptchaToken: string) {
		return await this.safeExecuteAsync(async () => {
			const data = await this.httpPivotal.put(this.endpoints.UpdatePivotalcompany(companyId), {
				companyId,
				trusteeCompanyId: company.companyId,
				token,
				reCaptchaToken,
				productsSchema: AppProducts.CapTable,
			});

			return data;
		});
	}

	async disconnectPivotalcompany(companyId: number) {
		return await this.safeExecuteAsync(async () => {
			const data = await this.httpPivotal.patch(this.endpoints.UpdatePivotalcompany(companyId), {
				companyId,
			});

			return data;
		});
	}

	public loadCapTableProject(projectID: number, filteredDate: Date) {
		return this.safeExecuteAsync(async () => {
			return (
				await this.httpLogin.post<ProjectPreviewInfo>(this.endpoints.LoadCapTableProject, {
					...this.commonValues,
					projectID: projectID,
					filteredDate: filteredDate,
				})
			).data;
		});
	}

	public getUserPermissions() {
		return this.safeExecuteAsync(async () => {
			return (
				await this.httpLogin.post<CapTableUserPermissions>(this.endpoints.GetUserPermission, {
					...this.commonValues,
				})
			).data;
		});
	}
	public deleteProject(projectID: number) {
		return this.safeExecuteAsync(async () => {
			return (
				await this.httpLogin.post<any>(this.endpoints.DeleteProject, {
					...this.commonValues,
					projectID: projectID,
				})
			).data;
		});
	}

	public addOptionTransaction(body: OptionTransactionInfo, projectID: number, saveMode: OperationSaveMode) {
		return this.safeExecuteAsync(async () => {
			const data: OptionTransactionSubmitInfo = {
				saveMode: saveMode,
				optionTransaction: { ...body },
				projectID: projectID,
				...this.commonValues,
			};
			return (await this.httpLogin.post<OptionTransactionResultInfo>(this.endpoints.AddOptionTransaction, data)).data;
		});
	}

	public updateOptionTransaction(body: OptionTransactionInfo, projectID: number, saveMode: OperationSaveMode) {
		return this.safeExecuteAsync(async () => {
			const data: OptionTransactionSubmitInfo = {
				saveMode: saveMode,
				optionTransaction: { ...body },
				projectID: projectID,
				...this.commonValues,
			};

			return (await this.httpLogin.post<OptionTransactionResultInfo>(this.endpoints.UpdateOptionTransaction, data)).data;
		});
	}

	public removeOptionTransaction(body: OptionTransactionInfo, projectID: number, saveMode: OperationSaveMode) {
		return this.safeExecuteAsync(async () => {
			const data: OptionTransactionSubmitInfo = {
				saveMode: saveMode,
				optionTransaction: { ...body },
				projectID: projectID,
				...this.commonValues,
			};

			return (await this.httpLogin.post<OptionTransactionResultInfo>(this.endpoints.RemoveOptionTransaction, data)).data;
		});
	}

	public getGrantNumberList(date: Date, projectID: number) {
		return this.safeExecuteAsync(async () => {
			return (
				(
					await this.httpLogin.post<GrantNumberResultInfo>(this.endpoints.GetGrantNumberList, {
						date: date,
						projectID: projectID,
						...this.commonValues,
					})
				).data.grantNumberList ?? []
			);
		});
	}
	public getAssetsSold(personId: number, date: Date, projectID: number) {
		return this.safeExecuteAsync(async () => {
			return (
				(
					await this.httpLogin.post<AssetsListResponse>(this.endpoints.GetAssetsSold, {
						selectedDate: date,
						projectID: projectID,
						seller_ProjectPersonId: personId,
						...this.commonValues,
					})
				).data.shareClassList ?? []
			);
		});
	}
	public availableOptionTransactionByType(grantNumber: string | null, projectID: number, transactionType: OptionTransactionTypesEnum, selectedDate: Date) {
		return this.safeExecuteAsync(async () => {
			const data: AvailableOptionSubmitInfo = {
				grantNumber: grantNumber,
				projectID: projectID,
				...this.commonValues,
				optionTransactionTypeEnum: transactionType,
				transactionDate: selectedDate,
			};
			return (await this.httpLogin.post<AvailableOptionResultInfo>(this.endpoints.AvailableOptionTransactionByType, data)).data.availableAmount ?? 0;
		});
	}

	public availableAmounts(seller_ShareClassId: number, seller_ProjectPersonId: number, selectedDate: Date, projectID: number) {
		return this.safeExecuteAsync(async () => {
			const req_data: AvailableAmountsSubmitInfo = {
				selectedDate: selectedDate,
				seller_ShareClassId: seller_ShareClassId,
				seller_ProjectPersonId: seller_ProjectPersonId,

				projectID: projectID,
				...this.commonValues,
			};
			return (await this.httpLogin.post<AvailableAmountsResultInfo>(this.endpoints.AvailableAmounts, req_data)).data;
		});
	}

	public availableAmountsByShareClass(shareClassID: number, selectedDate: Date, projectID: number) {
		return this.safeExecuteAsync(async () => {
			const req_data: AvailableAmountsByShareClassSubmitInfo = {
				selectedDate: selectedDate,
				shareClassID: shareClassID,
				projectID: projectID,
				...this.commonValues,
			};
			return (await this.httpLogin.post<AvailableAmountsResultInfo>(this.endpoints.AvailableAmountsByShareClass, req_data)).data;
		});
	}

	public shareHolderAvailableAwardsByTypeAndExrPrice(
		shareClassId: number,
		projectPersonId: number,
		selectedDate: Date,
		projectID: number,
		warrantsType: WarrantsType,
		exPrice: number
	) {
		return this.safeExecuteAsync(async () => {
			const req_data: AvailableAwardsSubmitInfo = {
				selectedDate: selectedDate,
				shareClassID: shareClassId,
				projectPersonID: projectPersonId,
				warrantsTypeID: warrantsType,
				warrantsTypeEnum: warrantsType,
				projectID: projectID,
				warrantsExercisePrice: exPrice,
				...this.commonValues,
			};
			return (await this.httpLogin.post<AvailableAwardsResultInfo>(this.endpoints.AvailableAwards, req_data)).data;
		});
	}

	public AddPerson(userData: UserPersonalInformation, projectId: number, permission: CapTablePermission) {
		return this.safeExecuteAsync(async () => {
			const data: AddUserToOptionsAndCapTableRequest = {
				firstName: userData.firstName,
				lastName: userData.lastName,
				//personalIdnumber: "1234567", // FIXME: This is currently required by the server to have minimum 6 chars
				workMail: userData.email?.replace(/\s/g, '') || null,
				companyName: userData.organizationName,
				corporateNumber: userData.corporateNumber ?? '',
				// creditDetails: "creditDetails",
				// expiredDate: new Date("2025-01-01"),
				// plan: 0,
				id: userData.id,
				isLegalEntity: userData.isLegalEntity || userData.type === PersonTypesEnum.LegalCompany,
				mobileNumber: userData.phoneNumber,
				nationality: userData.nationality,
				organization: userData.organizationName,
				employeeNumber: userData.employeeNumber,

				role: userData.role,
				userID: 0,
				...this.commonValues,
				projectID: projectId,
				idImage: userData.photo,
				permission: permission,
				contactId: userData.contactId,
			};
			return (await this.httpLogin.post<{ userID: number; projectPersonID: number }>(this.endpoints.AddPerson, data)).data;
		});
	}

	// /**
	//  *
	//  * @param {PersonSubmitInfo} [body]
	//  * @param {*} [options] Override http request option.
	//  * @throws {RequiredError}
	//  * @memberof CapTableApi
	//  */

	public UpdatePerson(type: number, projectID: number, sourceUserID: number, projectPersonId: number) {
		return this.safeExecuteAsync(async () => {
			this.httpLogin.post(this.endpoints.UpdatePerson, {
				projectID,
				// sourceUserID,
				personUser: {
					shareholder_type: type,
					projectPersonId,
					projectID,
					sourceUserID,
				},
				...this.commonValues,
			});
		});
	}

	public RemovePerson(projectPersonID: number, projectID: number) {
		return this.safeExecuteAsync(async () => {
			const data = {
				projectID: projectID,
				...this.commonValues,
				personUser: {
					projectPersonID: projectPersonID,
					...this.commonValues,
				},
			};
			return await this.httpLogin.post(this.endpoints.RemovePerson, data);
		});
	}

	public AddSafeTransaction(body?: SafeTransactionSubmitInfo) {
		return this.safeExecuteAsync(async () => {
			return (
				await this.httpLogin.post(this.endpoints.AddSafeTransaction, {
					...body,
					...this.commonValues,
					projectId: body?.transaction?.projectId,
				})
			).data;
		});
	}

	public UpdateSafeTransaction(body?: SafeTransactionSubmitInfo) {
		return this.safeExecuteAsync(async () => {
			return (
				await this.httpLogin.post(this.endpoints.UpdateSafeTransaction, {
					...body,
					...this.commonValues,
					projectId: body?.transaction?.projectId,
				})
			).data;
		});
	}

	public RemoveSafeTransaction(txId: number, projectId: number) {
		return this.safeExecuteAsync(async () => {
			const body = {
				...this.commonValues,
				transaction: {
					projectId: projectId,
					transactionId: txId,
				},
				projectId: projectId,
			};
			return (await this.httpLogin.post(this.endpoints.RemoveSafeTransaction, body)).data;
		});
	}

	public AddConvertibleLoanTransaction(body?: ConvertibleLoanTransactionSubmitInfo) {
		return this.safeExecuteAsync(async () => {
			return (
				await this.httpLogin.post(this.endpoints.AddConvertableLoanTransaction, {
					...body,
					...this.commonValues,
					projectId: body?.transaction?.projectId,
				})
			).data;
		});
	}

	public UpdateConvertibleLoanTransaction(body?: ConvertibleLoanTransactionSubmitInfo) {
		return this.safeExecuteAsync(async () => {
			return (
				await this.httpLogin.post(this.endpoints.UpdateConvertableLoanTransaction, {
					...body,
					...this.commonValues,
					projectId: body?.transaction?.projectId,
				})
			).data;
		});
	}

	public RemoveConvertibleLoanTransaction(txId: number, projectId: number) {
		return this.safeExecuteAsync(async () => {
			const body = {
				...this.commonValues,
				transaction: {
					projectId: projectId,
					transactionId: txId,
				},
				projectId: projectId,
			};
			return (await this.httpLogin.post(this.endpoints.RemoveConvertableLoanTransaction, body)).data;
		});
	}
	public runFinancingRound(body: FinancialRound) {
		return this.safeExecuteAsync(async () => {
			const data: FinancialRound = {
				...body,
				...this.commonValues,
				projectID: 0,
			};
			const res = (await this.httpLogin.post<FinancialRoundResponse>(this.endpoints.FinancingRound, data)).data;

			const project = this.mapFundingRoundProject(res);

			return project;
		});
	}

	public loadCapTableForFundingRound(projectID: number, snapshotID?: number) {
		return this.safeExecuteAsync(async () => {
			const res = (
				await this.httpLogin.post<FinancialRoundResponse>(this.endpoints.LoadCapTableForFundingRound, {
					...this.commonValues,
					projectID,
					snapshotID,
				})
			).data;

			const project = this.mapFundingRoundProject(res);

			return project;
		});
	}

	private mapFundingRoundProject(res: FinancialRoundResponse) {
		if (res.previousFundingRound && res.previousFundingRound.fundingRoundData) {
			if (res.previousFundingRound.preRoundDataList) {
				res.previousFundingRound.preRoundDataList.forEach((x) => {
					if (x.preRoundDataType === ePreRoundDataType.Safe || x.preRoundDataType === ePreRoundDataType.convertible_loan) {
						if (res.previousFundingRound.fundingRoundData.includeSafes?.find((z) => z === x.id)) {
							x.include = true;
						} else {
							x.include = false;
						}
					} else {
						if (res.previousFundingRound.fundingRoundData.includeAntiDilutionClasses?.find((z) => z === x.id)) {
							x.include = true;
						} else {
							x.include = false;
						}
					}
				});
			}

			if (res.previousFundingRound.fundingRoundData.holders) {
				res.previousFundingRound.fundingRoundData.holders.forEach((x) => {
					const sh = res.fundingCapTable.personsList?.find((z) => z.sourceUserID === x.sourceUserID);
					if (sh) {
						x.firstName = sh.firstName as string;
						x.lastName = sh.lastName as string;
						x.organizationName = sh.firstName as string;
						x.personTypeEnum = sh.personType as any;
					}
				});
			}
		}

		const project: ProjectPreviewInfo_Funding = {
			...res.fundingCapTable,
			preFunding_ShareClassHolderList: res.preFunding?.shareClassHolderList ?? [],
			preRoundDataList: res.preRoundDataList,
			preFunding_options: res.preFunding?.options,
			previousFundingRound: res.previousFundingRound,
			originalProjectId: res.fundingCapTable.projectID,
			projectID: res.snapshotID, // Override project id with the current snapshotID so all dependencies on projectID will reffer to snapshop and not the actual project
			isValid: res.isValid,
			errorMessageList: res.errorMessageList,
			snapshotID: res.snapshotID,
			productType: res.fundingCapTable.productType,
		};

		return project;
	}
	public addShareClass(body: ShareClassInfo) {
		const _body = { ...body };
		delete _body.documents;

		return this.safeExecuteAsync(async () => {
			const data: ShareClassSubmitInfo = {
				saveMode: OperationSaveMode.SaveAnyway,
				shareClass: _body,
				projectID: body?.projectID ?? 0,
				...this.commonValues,
				documents: body?.documents,
			};
			return await this.httpLogin.post(this.endpoints.AddShareClass, data);
		});
	}

	addShareClassFromImport(shareClass: ShareClassInfo) {
		return loginApi.post<any>(this.endpoints.UpdateShareClass, {
			saveMode: OperationSaveMode.SaveAnyway,
			shareClass: shareClass,
			projectID: shareClass.projectID ?? 0,
			...this.commonValues,
			documents: shareClass?.documents,
		});
	}

	// TODO: check BSA undefined
	public removeShareClass(id: number, projectId: number) {
		return this.safeExecuteAsync(async () => {
			const data: ShareClassSubmitInfo = {
				saveMode: OperationSaveMode.SaveAnyway,
				projectID: projectId,
				shareClass: {
					shareClassID: id,
					projectID: projectId,
					numberOfWarrants: undefined as any,
					numberOfBSA: undefined as any,
				},
				...this.commonValues,
			};
			return await this.httpLogin.post(this.endpoints.RemoveShareClass, data);
		});
	}
	public updateShareClass(body: ShareClassInfo) {
		const _body = { ...body };
		delete _body.documents;

		return this.safeExecuteAsync(async () => {
			const data: ShareClassSubmitInfo = {
				saveMode: OperationSaveMode.SaveAnyway,
				shareClass: _body,
				projectID: body?.projectID ?? 0,
				documents: body?.documents,
				...this.commonValues,
			};
			return this.httpLogin.post(this.endpoints.UpdateShareClass, data);
		});
	}

	/**
	 *
	 * @param {IssueTransactionSubmitInfo} [body]
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 * @memberof CapTableApi
	 */

	public AddShareHolderIssueTransaction(body: IssueTransactionSubmitInfo) {
		return this.safeExecuteAsync(async () => {
			return (
				await this.httpLogin.post(this.endpoints.AddShareHolderIssueTransaction, {
					...body,
					...this.commonValues,
				})
			).data;
		});
	}

	public UpdateShareHolderIssueTransaction(body: IssueTransactionSubmitInfo) {
		return this.safeExecuteAsync(async () => {
			return (
				await this.httpLogin.post(this.endpoints.UpdateShareHolderIssueTransaction, {
					...body,
					...this.commonValues,
				})
			).data;
		});
	}

	public RemoveShareHolderIssueTransaction(txId: number, projectId: number) {
		return this.safeExecuteAsync(async () => {
			const body: IssueTransactionSubmitInfo = {
				...this.commonValues,
				projectID: projectId,
				issueTransaction: {
					shareHolderIssueTransactionID: txId,
					shareClassID: 0,
				},
			};
			return (await this.httpLogin.post(this.endpoints.RemoveShareHolderIssueTransaction, body)).data;
		});
	}

	public AddShareHolderWarrantsTransaction(body: WarrantsTransactionSubmitInfo) {
		return this.safeExecuteAsync(async () => {
			return (
				await this.httpLogin.post(this.endpoints.AddShareHolderWarrantsTransaction, {
					...body,
					...this.commonValues,
				})
			).data;
		});
	}

	public UpdateShareHolderWarrantsTransaction(body: WarrantsTransactionSubmitInfo) {
		return this.safeExecuteAsync(async () => {
			return (
				await this.httpLogin.post(this.endpoints.UpdateShareHolderWarrantsTransaction, {
					...body,
					...this.commonValues,
				})
			).data;
		});
	}

	public RemoveShareHolderWarrantsTransaction(txId: number, projectId: number) {
		return this.safeExecuteAsync(async () => {
			const body: WarrantsTransactionSubmitInfo = {
				...this.commonValues,
				projectID: projectId,
				warrantsTransaction: {
					shareHolderWarrantsTransactionID: txId,
				},
			};
			return (await this.httpLogin.post(this.endpoints.RemoveShareHolderWarrantsTransaction, body)).data;
		});
	}

	public AddShareHolderSecondaryTransaction(body?: SecondaryTransactionSubmitInfo) {
		return this.safeExecuteAsync(async () => {
			return (
				await this.httpLogin.post(this.endpoints.AddShareHolderSecondaryTransaction, {
					...body,
					...this.commonValues,
				})
			).data;
		});
	}

	public RemoveShareHolderSecondaryTransaction(txId: number, projectId: number) {
		return this.safeExecuteAsync(async () => {
			const body: SecondaryTransactionSubmitInfo = {
				...this.commonValues,
				projectID: projectId,
				secondaryTransaction: {
					shareHolderSecondaryTransactionID: txId,
				},
			};
			return (await this.httpLogin.post(this.endpoints.RemoveShareHolderSecondaryTransaction, body)).data;
		});
	}

	public UpdateShareHolderSecondaryTransaction(body?: SecondaryTransactionSubmitInfo) {
		return this.safeExecuteAsync(async () => {
			return (
				await this.httpLogin.post(this.endpoints.UpdateShareHolderSecondaryTransaction, {
					...body,
					...this.commonValues,
				})
			).data;
		});
	}

	public CreateNewProject(body?: ProjectSubmitInfo) {
		return this.safeExecuteAsync(async () => {
			return (
				await this.httpLogin.post<ProjectResultInfo>(this.endpoints.CreateNewProject, {
					...body,
					...this.commonValues,
				})
			).data;
		});
	}

	public async GetInternalDashBoardCapTable(body?: GetInternalDashBoardCapTableReq) {
		return await this.safeExecuteAsync(async () => {
			const data = await this.httpLogin.post(this.endpoints.GetInternalDashBoardCapTable, { ...this.commonValues, ...body });
			return data;
		});
	}

	public async GetExternalDashBoardCapTable() {
		return await this.safeExecuteAsync(async () => {
			const result = await this.httpLogin.post<IExternalCapTableDashboard>(this.endpoints.GetExternalDashBoardCapTable, {
				...this.commonValues,
				ProjectID: 0,
			});
			return result;
		});
	}

	public async GetFundingDashboard(body?: any) {
		return await this.safeExecuteAsync(async () => {
			const result = await this.httpLogin.post(this.endpoints.GetFundingDashboard, { ...this.commonValues, ...body });
			return result;
		});
	}

	public DuplicateCapTableAsCapTable(body?: CapTableProjectDuplicateSubmitInfo) {
		return this.safeExecuteAsync(async () => {
			return (
				await this.httpLogin.post<{ projectID: number }>(this.endpoints.DuplicateCapTableAsCapTable, {
					...body,
					...this.commonValues,
				})
			).data;
		});
	}

	// public SaveCapTableResultAsDraft(body?: FundingSaveDraftRequest) {
	//   return this.safeExecuteAsync(async () => {
	//     return (
	//       await this.httpLogin.post<{ projectID: number }>(this.endpoints.SaveCapTableResultAsDraft, {
	//         ...body,
	//         ...this.commonValues,
	//       })
	//     ).data;
	//   });
	// }

	SaveSnapshotAsCaptable(SnapshotID: number, name: string, productType: FundingSaveProductType) {
		return this.safeExecuteAsync(async () => {
			return (
				await this.httpLogin.post<{ projectID: number }>(this.endpoints.SaveSnapshotAsCaptable, {
					snapshotID: SnapshotID,
					projectName: name,
					productType: productType,
					...this.commonValues,
				})
			).data;
		});
	}
	// DuplicateCapTableAsNextRound(projectID: number) {
	//   return this.safeExecuteAsync(async () => {
	//     return (
	//       await this.httpLogin.post<{ projectID: number }>(this.endpoints.DuplicateCapTableAsNextRound, {
	//         projectID: projectID,
	//         ...this.commonValues,
	//       })
	//     ).data;
	//   });
	// }

	public addSplit(body: SplitTableData, projectId: number) {
		return this.safeExecuteAsync(async () => {
			return (
				await this.httpLogin.post(this.endpoints.SplitCapTable, {
					...this.commonValues,
					projectID: projectId,
					splitTableData: body,
				})
			).data;
		});
	}
	public updateSplit(body: SplitTableData, projectId: number) {
		return this.safeExecuteAsync(async () => {
			return (
				await this.httpLogin.post(this.endpoints.UpdateSplitCapTable, {
					...this.commonValues,
					projectID: projectId,
					splitTableData: body,
				})
			).data;
		});
	}

	public removeSplit(id: number, projectId: number) {
		return this.safeExecuteAsync(async () => {
			return (
				await this.httpLogin.post(this.endpoints.RemoveSplitCapTable, {
					...this.commonValues,
					projectID: projectId,
					splitTableID: id,
				})
			).data;
		});
	}

	public loadOptionTransactions(projectID: number, filterDate: Date) {
		return this.safeExecuteAsync(async () => {
			const data = (
				await this.httpLogin.post<LoadOptionTransactionResultInfo>(this.endpoints.LoadOptionTransactionTotals, {
					projectID,
					...this.commonValues,
					filterDate: filterDate,
				})
			).data;

			if (data.optionTransactionList) {
				data.optionTransactionList.forEach((x) => {
					x.key = _.uniqueId('opt_tx_');
					x.isLegalEntity = x.personType === PersonTypesEnum.LegalCompany;
					//x.date = new Date(x.date as any)
				});
			}
			return data;
		});
	}

	public LoadProjectPerson(body: LoadPersonSubmitInfo) {
		return this.safeExecuteAsync(async () => {
			const data = (
				await this.httpLogin.post<LoadPersonResultInfo>(this.endpoints.LoadProjectPerson, {
					...body,
					...this.commonValues,
				})
			).data;

			return data;
		});
	}

	createDocumentCertificate(body: CreateShareCertificateType) {
		return loginApi.post<CreateShareCertificateResult>('CapTable/CreateDocumentCertificate', {
			...body,
		});
	}

	deleteDocumentCertificate(documentId: string, projectID: number, transactionId: number, transactionType: TransactionTypesEnum) {
		return loginApi.post('CapTable/DeleteDocumentCertificate', {
			documentId,
			projectID,
			transactionId,
			transactionType,
		});
	}

	cancelDocumentCertificate(documentId: string, projectID: number) {
		return loginApi.post('CapTable/CancelDocumentCertificate', {
			documentId,
			projectID,
		});
	}

	public LoadShareClassDocumentLink(documentLinkID: number, shareClassID: number, projectID: number) {
		return this.safeExecuteAsync(async () => {
			const data = (
				await this.httpLogin.post<{ fileModel: FileModel }>(this.endpoints.LoadShareClassDocumentLink, {
					shareClassDocumentLinkID: documentLinkID,
					shareClassID: shareClassID,
					projectID: projectID,
					...this.commonValues,
				})
			).data;

			return data;
		});
	}

	public async checkCompanySync(companyId: number) {
		return await this.safeExecuteAsync(async () => {
			const data = (await this.httpPivotal.get<IPivotalCompany>(this.endpoints.getcompanySync(companyId))).data;
			return data;
		});
	}

	public UpdateProjectOptionMode(optionUpdateMode: OptionUpdateModeTypeEnum, projectID: number) {
		return this.safeExecuteAsync(async () => {
			const data = (
				await this.httpLogin.post<any>(this.endpoints.UpdateProjectOptionMode, {
					optionUpdateMode: optionUpdateMode,

					projectID: projectID,
					...this.commonValues,
				})
			).data;

			return data;
		});
	}

	public SaveUserPermissions(userId: number, email: string | undefined, permission: CapTablePermission, projectId: number) {
		return this.safeExecuteAsync(async () => {
			const data: SaveUserPermissionSubmitInfo = {
				...this.commonValues,
				projectID: projectId,
				userUpdate: {
					auth: permission,
					companyID: this.commonValues.companyID,
					email: email,
					sourceUserID: userId,
				},
			};
			return await this.httpLogin.post(this.endpoints.SaveUserPermissions, data);
		});
	}

	static isInRoleGroup(roleToCheck: CapTablePermission | undefined | null, group: CapTablePermission) {
		if (!roleToCheck) {
			return false;
		}
		const permissions = new Map<CapTablePermission, Array<CapTablePermission>>([
			[
				CapTablePermission.admin,
				[
					CapTablePermission.admin,
					CapTablePermission.editor,
					CapTablePermission.full_access,
					CapTablePermission.no_access,
					CapTablePermission.see_only_mine,
				],
			],
			[
				CapTablePermission.editor,
				[CapTablePermission.editor, CapTablePermission.full_access, CapTablePermission.no_access, CapTablePermission.see_only_mine],
			],
			[CapTablePermission.full_access, [CapTablePermission.full_access, CapTablePermission.no_access, CapTablePermission.see_only_mine]],
			[CapTablePermission.see_only_mine, [CapTablePermission.no_access, CapTablePermission.see_only_mine]],
			[CapTablePermission.no_access, [CapTablePermission.no_access]],
		]);

		const groupPermissions = permissions.get(roleToCheck);
		if (groupPermissions) {
			return groupPermissions?.indexOf(group) > -1;
		}

		return false;
	}

	static getPermissionsDescription(roleToCheck?: CapTablePermission) {
		switch (roleToCheck) {
			case CapTablePermission.admin:
				return 'Admin';
			case CapTablePermission.editor:
				return 'Editor';
			case CapTablePermission.full_access:
				return 'Full Acsess';
			case CapTablePermission.no_access:
				return 'No Access';
			case CapTablePermission.see_only_mine:
				return 'Only Mine';

			default:
				return 'No Access';
		}
	}

	// getOrAddShareHolderAdHoc = async (shareholder: UserPersonalInformation, ignoreExitingUserCheck: boolean, projectId: number): Promise<AddShareholderResult> => {

	//   const userService = new UserService(this.commonValues.companyID as number);

	//   const promise = new Promise<AddShareholderResult>(async (resolve, reject) => {
	//     if (shareholder) {
	//       if (shareholder.userID > 0) {

	//         //await this.userService.SaveUserDetailesByUserId(shareholder);

	//         const personData = await this.getUserProjectPerson(shareholder.userID, projectId);

	//         const personId = personData.data.person.projectPersonID;

	//         resolve({ state: AddShareholderState.Updated, userId: shareholder.userID, projectPersonId: personId });

	//       } else {

	//         if (ignoreExitingUserCheck === false) {
	//           // let userToCheck = {
	//           //   email: shareholder.email,
	//           //   firstName: shareholder.firstName,
	//           //   lastName: shareholder.lastName
	//           // }

	//           let userToCheck = {
	//             email: shareholder.email,
	//             firstName: shareholder.firstName,
	//             lastName: shareholder.lastName
	//           } as any;

	//           if (shareholder.isLegalEntity) {
	//             userToCheck = {
	//               organizationName: shareholder.organizationName,
	//               isLegalEntity: true,

	//             }
	//           }

	//           const resExist = await userService.CheckIfUserExists(userToCheck);

	//           if (resExist.isSuccess) {
	//             if (resExist.data.usersList.length > 0) {
	//               if (resExist.data.isExact) {
	//                 // Found exact match by email
	//                 // Need to treat it as update
	//                 const exactUserID = resExist.data.usersList[0].userId ?? 0;

	//                 //Get project person id
	//                 const projectPersonId = await (await this.getUserProjectPerson(exactUserID, projectId)).data.person.projectPersonID;
	//                 shareholder.userID = exactUserID;

	//                 // Update user
	//                 //await this.userService.SaveUserDetailesByUserId(shareholder);

	//                 resolve({ state: AddShareholderState.Updated, userId: exactUserID, projectPersonId: projectPersonId });

	//               }
	//               else {
	//                 // Found existing users
	//                 resolve({ state: AddShareholderState.UserExists, existingUsers: resExist.data.usersList, userId: null, projectPersonId: null });

	//               }
	//             }
	//             else {

	//               if (shareholder.isLegalEntity) {
	//                 shareholder.firstName = 'Not provided';
	//                 shareholder.lastName = 'Not provided';
	//               }

	//               const res = await this.AddPerson(shareholder, projectId, CapTablePermission.no_access);
	//               if (res.isSuccess) {

	//                 resolve({ state: AddShareholderState.Added, userId: res.data.userID, projectPersonId: res.data.projectPersonID });
	//               }
	//               else {
	//                 resolve({ state: AddShareholderState.Fail, userId: null, projectPersonId: null });
	//               }
	//             }
	//           }
	//           else {
	//             // reject({ error: "Missing userPersonalInformation" });
	//             resolve({ state: AddShareholderState.Fail, userId: null, projectPersonId: null });
	//           }
	//         }
	//         else {
	//           const res = await this.AddPerson(shareholder, projectId, CapTablePermission.no_access);
	//           if (res.isSuccess) {

	//             resolve({ state: AddShareholderState.Added, userId: res.data.userID, projectPersonId: res.data.projectPersonID });
	//           }
	//           else {
	//             resolve({ state: AddShareholderState.Fail, userId: null, projectPersonId: null });
	//           }
	//         }

	//       }

	//     }
	//     else {
	//       reject({ error: "Missing userPersonalInformation" });
	//     }

	//   });

	//   return promise;

	// };

	getOrAddShareHolderAdHoc2 = async (shareholder: UserPersonalInformation, projectId: number): Promise<AddShareholderResult2> => {
		const promise = new Promise<AddShareholderResult2>(async (resolve, reject) => {
			if (shareholder) {
				if (shareholder.userID > 0) {
					//await this.userService.SaveUserDetailesByUserId(shareholder);

					const personData = await this.getUserProjectPerson(shareholder.userID, projectId);

					const personId = personData.data.person.projectPersonID;

					resolve({ userID: shareholder.userID, projectPersonID: personId });
				} else {
					let userToCheck = {
						email: shareholder.email,
					} as any;

					if (shareholder.isLegalEntity) {
						userToCheck = {
							organizationName: shareholder.organizationName,
							isLegalEntity: true,
						};
					}

					const res = await this.AddPerson(shareholder, projectId, CapTablePermission.no_access);

					if (res.isSuccess) {
						const projectPersonId = await (
							await this.getUserProjectPerson(rootStore.contactStore.contactId, projectId)
						).data.person.projectPersonID;
						shareholder.userID = rootStore.contactStore.contactId;

						if (projectPersonId || projectPersonId === 0) {
							resolve({
								userID: rootStore.contactStore.contactId,
								projectPersonID: projectPersonId,
							});
						}
					}
				}
			} else {
				reject({ error: 'Missing userPersonalInformation' });
			}
		});

		return promise;
	};

	public async GetCapTableUpdates(page: number, size: number = 10) {
		return await this.safeExecuteAsync(async () => {
			return (
				await this.httpLogin.post<ICapTableUpdates>(this.endpoints.GetHistoryCapTablePublished, {
					...this.commonValues,
					pageIndex: page,
					pageSize: size,
				})
			).data;
		});
	}

	public async getFundingSnapshot(snapshotID: number) {
		return this.safeExecuteAsync(async () => {
			const res = (
				await this.httpLogin.post<FinancialRoundResponse>(this.endpoints.GetFundingSnapshot, {
					...this.commonValues,
					snapshotID,
				})
			).data;

			const project = this.mapFundingRoundProject(res);
			return project;
		});
	}

	uploadCapTableImport(data: FormData) {
		return loginApi.post<ImportCapTable>(this.endpoints.import, data, { 'Content-Type': ContentTypeEnum.FormData });
	}

	saveImportedCapTable(data: ImportCapTable) {
		return loginApi.post<{ shareClasses: ShareClassInfo[] }>(this.endpoints.saveImportedCapTable, data);
	}
}
