import { observer } from 'mobx-react-lite';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import Spinner from '../../../../../../Shared/Components/Spinner/Spinner';
import { ForwardedRef } from '../../../../../../Shared/Hooks/useMultiStepForm';
import useRootStore from '../../../../../../Shared/Hooks/useRootStore';
import { WaterfallStep } from '../../../../../Waterfall/Components/AddEditWaterfall/index.style';
import ValuationSummary from './ValuationSummary';
import ValuationTable from './ValuationTable';
import { useSummary } from '../../../../../Waterfall/Components/AddEditWaterfall/Steps/Summary/deps/useSummary';
import ProgressCard from '../../../../../Waterfall/Components/AddEditWaterfall/Steps/Summary/components/ProgressCard';
import { SummaryStatusCode } from '../../../../../Waterfall/Components/AddEditWaterfall/Steps/Summary/deps/types';

type Props = {
	onEditVolatility: () => void;
};

const Summary = forwardRef<ForwardedRef, Props>(({ onEditVolatility }, forwardedRef) => {
	const { valuationStore } = useRootStore();

	const { setIsTimeout, handleSummaryStatus, status } = useSummary({
		onGetSummary: () => valuationStore.getSummary(),
		onGetSummaryStatus: async () => {
			const res = await valuationStore.getSummaryStatus();
			return res.data;
		},
		onSetSummary: () => valuationStore.setSummary(undefined),
		onRunSummary: async () => {
			const res = await valuationStore.runSummary();
			return res.data;
		},
	});

	useImperativeHandle(forwardedRef, () => ({
		onValidate: () => {
			return true;
		},
	}));

	return (
		<WaterfallStep small>
			{valuationStore.summaryData ? (
				<>
					<ValuationSummary onEditVolatility={onEditVolatility} />
					<ValuationTable />
				</>
			) : (
				<ProgressCard
					startTime={(status?.timeStamp ?? 0) * 1000}
					estimated={status?.estimatedTimeDTO}
					isDataReceived={status?.status === SummaryStatusCode.FINISHED}
					setIsTimeout={setIsTimeout}
					onRetry={() => handleSummaryStatus(true)}
					onCancel={() => valuationStore.cancelSummary()}
				/>
			)}
		</WaterfallStep>
	);
});

export default observer(Summary);
