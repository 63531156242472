import { ServerResponseApi } from '../Models/API/All/server-response';
import { UploadReportPayload } from '../Models/API/DataCollection/upload-report-payload';
import { UploadReportResponse } from '../Models/API/DataCollection/upload-report-response';
import { ChangeValuatorOwnerResponse } from '../Models/API/Valuation/change-valuator-owner';
import { EconomicUser } from '../Models/API/Valuation/economic-user';
import { GeneralFormRequest, GeneralFormResponse } from '../Models/API/Valuation/general-request';
import { SummaryData } from '../Models/API/Valuation/summary';
import {
	CapTableData,
	CapTableProjectResponse,
	CapTableRequest,
	CreateProjectRequest,
	DataPlanResponse,
	DefaultStepResponse,
	Industry,
	PeerSearchParams,
	PreferenceShareClassRequest,
	PreferenceShareClassRight,
	ReviewersResponse,
	RiskFreeRate,
	ShareClassRightIssueRequest,
	ShareClassRightRequest,
	ShareClassRightResponse,
	ShareClassRightsResponse,
	StepsResponse,
	ToggleReviewerRequest,
	UploadOptionsRequest,
	ValuationProject,
	VolatilityAndMultiple,
	Volatility,
	VolatilityPayload,
	VolatilityTableData,
	VolatilityTablePreviewData,
	WaterfallAwardType,
	WaterfallDefaultParams,
	WaterfallPeer,
} from '../Models/API/Waterfall/IForm';
import { SummaryStatus } from '../Screens/Waterfall/Components/AddEditWaterfall/Steps/Summary/deps/types';
import { dataCollectionApi, valuationApi } from './Axios';

export class ValuationService {
	getSteps({ companyId, waterfallId }: WaterfallDefaultParams) {
		return valuationApi.get<StepsResponse>(`/globalComponents/getSteps/${companyId}/${waterfallId}`);
	}

	getProjects(companyId: number) {
		return valuationApi.get<ValuationProject[]>(`/mainScreen/Projects/${companyId}`);
	}

	getEconomicUsers() {
		return valuationApi.get<{ users: EconomicUser[] }>('/mainScreen/economicdepartmentusers');
	}

	changeProjectOwner(userId: number, valuationProjectId: string) {
		return valuationApi.post<ChangeValuatorOwnerResponse>(`/mainScreen/valuation/${userId}/${valuationProjectId}`);
	}

	deleteProject(waterfallId: number) {
		return valuationApi.delete<ValuationProject[]>(`mainScreen/${waterfallId}`);
	}

	duplicateProject(payload: CreateProjectRequest) {
		return valuationApi.post<number>('/globalComponents/duplicate', payload);
	}

	getProject(waterfallId: number) {
		return valuationApi.get<GeneralFormResponse>(`mainScreen/Project/${waterfallId}`);
	}

	updateLastModified(payload: WaterfallDefaultParams) {
		return valuationApi.post('/globalComponents', payload);
	}

	uploadReport(data: UploadReportPayload) {
		return dataCollectionApi.post<ServerResponseApi<UploadReportResponse>>('/report', data);
	}

	// step 1

	createProject(payload: CreateProjectRequest) {
		return valuationApi.post<DefaultStepResponse>('project', payload);
	}

	getSingleCapTableData(payload: CapTableRequest) {
		return valuationApi.post<CapTableData>('/captable', payload);
	}

	getProjectReviewers(payload: WaterfallDefaultParams) {
		return valuationApi.get<ReviewersResponse>(`/project/users/${payload.companyId}/${payload.waterfallId}`);
	}

	updateScenarioStep({ waterfallId, ...payload }: GeneralFormRequest) {
		return valuationApi.post<DefaultStepResponse>(`project/update/${waterfallId}`, payload);
	}

	toggleProjectReviewer(payload: ToggleReviewerRequest) {
		return valuationApi.post<ReviewersResponse>('/project/addRemoveParticipant', payload);
	}

	getRiskFreeRate(valuationDate: Date) {
		return valuationApi.get<{ data: RiskFreeRate[] }>('/valotility/riskFreeRateCurveTable', { valuationDate });
	}

	getRiskFreeRateValue(valuationDate: Date, timeToExit: number) {
		return valuationApi.get<{
			expectedTerm: number;
			valuationDate: string;
			riskFreeRate: number;
		}>('/valotility/rfr', { valuationDate, expectedTerm: timeToExit });
	}

	// step 2

	getSectorAndIndstryList() {
		return valuationApi.get<{ industries: Industry[] }>('/valotility/industry');
	}

	getVolatilityAndMultiple(waterfallId: number) {
		return valuationApi.get<VolatilityAndMultiple>(`/valotility/volatilityAndMultiple/${waterfallId}`);
	}

	getAvailablePeers(params?: PeerSearchParams) {
		return valuationApi.get<WaterfallPeer[]>('/valotility/peers', params);
	}

	getVolatilityProject(waterfallId: number) {
		return valuationApi.get<VolatilityTableData>(`/valotility/project/${waterfallId}`);
	}

	updateVolatilityProject(data: VolatilityPayload, waterfallId: number) {
		return valuationApi.post<StepsResponse>('/valotility', { ...data, waterfallId });
	}

	addPeer(identifier: string, waterfallId: number) {
		return valuationApi.post<VolatilityAndMultiple>(`/valotility/peer/${identifier}/${waterfallId}`);
	}

	deletePeer(identifier: string, waterfallId: number) {
		return valuationApi.delete<VolatilityAndMultiple>(`/valotility/peer/${identifier}/${waterfallId}`);
	}

	// getAddedPeers(waterfallId: number) {
	// 	return valuationApi.get<VolatilityTableData>(`/valotility/project/${waterfallId}`);
	// }

	calculateAddedPeers(data: VolatilityTablePreviewData, waterfallId: number) {
		return valuationApi.post<VolatilityAndMultiple>(`/valotility/averageVolatility/${waterfallId}`, data);
	}

	submitAddedPeers(data: VolatilityTablePreviewData) {
		return valuationApi.post<{ volatilities: Volatility[] }>('/valotility/project', data);
	}

	getPeerInfo(peerId: number, waterfallId: number) {
		return valuationApi.get<WaterfallPeer>(`/valotility/peer/${peerId}/${waterfallId}`);
	}

	// step 3

	getCapTableBases(companyId: number) {
		return valuationApi.get<CapTableProjectResponse>(`/captable/all/${companyId}`);
	}

	getSavedCapTableBase(companyId: number, waterfallId: number) {
		return valuationApi.get<CapTableData>(`captable/one/${companyId}/${waterfallId}`);
	}

	// step 4

	uploadOptions(payload: UploadOptionsRequest) {
		let { isUnallocated, ...rst } = payload;
		return valuationApi.post<DataPlanResponse>('/options/uploadOptions', {
			...rst,
			withUnallocated: isUnallocated ? 1 : 0,
		});
	}

	reimportOptions(companyId: number, waterfallId: number) {
		return valuationApi.post<DataPlanResponse>('/options/reimport', { companyId, waterfallId });
	}

	getOptions = (waterfallId: number) => {
		return valuationApi.get<DataPlanResponse | null>(`/options/getOptions/${waterfallId}`);
	};

	updateOptionsMode(payload: UploadOptionsRequest) {
		let { isUnallocated, ...rst } = payload;
		return valuationApi.post<DataPlanResponse>('/options/updateOptionMode', {
			...rst,
			withUnallocated: isUnallocated ? 1 : 0,
		});
	}

	getValuationDate(waterfallId: number) {
		return valuationApi.get<string>(`/project/valuationDate/${waterfallId}`);
	}

	addAwardType(payload: WaterfallAwardType, waterfallId: number) {
		return valuationApi.post<DataPlanResponse>('/options/one', {
			...payload,
			waterfallId,
		});
	}

	updateAwardType(waterfallId: number, { shareClassId, ...data }: WaterfallAwardType) {
		return valuationApi.patch<DataPlanResponse>('/options/one/' + shareClassId, { waterfallId, ...data });
	}

	deleteAwardType(waterfallId: number, shareClassId: number) {
		return valuationApi.delete<unknown>('/options/one/' + shareClassId, { waterfallId });
	}

	// step 5

	getShareClassRights(waterfallId: number) {
		return valuationApi.get<ShareClassRightsResponse>(`/rights/${waterfallId}`);
	}

	getShareClassRight = ({ waterfallId, shareClassId }: WaterfallDefaultParams) => {
		return valuationApi.get<ShareClassRightResponse>(`/rights/shareclass/${waterfallId}/${shareClassId}`);
	};

	updateClassRight(payload: ShareClassRightRequest | ShareClassRightIssueRequest) {
		return valuationApi.patch('/rights', payload);
	}

	deleteClassRight({ shareClassId, waterfallId, companyId }: WaterfallDefaultParams) {
		return valuationApi.delete(`/rights/${shareClassId}`, {
			waterfallId,
			companyId,
		});
	}

	// step 6

	getPreferenceClassRights = (waterfallId: number) => {
		return valuationApi.get<PreferenceShareClassRight[]>(`/preference_terms/${waterfallId}`);
	};

	addPreferenceClassRight = (payload: PreferenceShareClassRequest) => {
		return valuationApi.post<PreferenceShareClassRight>('/preference_terms', payload);
	};

	deletePreferenceClassRight = (payload: WaterfallDefaultParams) => {
		return valuationApi.delete('/preference_terms', payload);
	};

	updatePreferenceClassRights = ({ companyId, waterfallId }: WaterfallDefaultParams) => {
		return valuationApi.get<DefaultStepResponse>(`/preference_terms/next/${companyId}/${waterfallId}`);
	};

	// step 7

	runSummary = (payload: WaterfallDefaultParams) => {
		return valuationApi.post<SummaryData>('/summary', payload);
	};

	cancelSummary(waterfallId: number) {
		return valuationApi.delete(`/summary/${waterfallId}`);
	}

	getSummary(waterfallId: number) {
		return valuationApi.get<SummaryData>(`/summary/${waterfallId}`);
		// return new Promise((res) =>
		// 	setTimeout(() => {
		// 		res({
		// 			data: summaryRes,
		// 			error: undefined,
		// 			isSuccess: true,
		// 			statusCode: 200,
		// 			errorCode: undefined,
		// 			errorMessage: undefined,
		// 		});
		// 	}, 500)
		// );
	}

	getSummaryStatus(waterfallId: number) {
		return valuationApi.get<SummaryStatus>(`/summary/status/${waterfallId}`);
	}
}
