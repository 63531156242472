import { css } from '@emotion/css';
import { DataCollectionStatusEnum } from '../../../../Models/API/DataCollection/project-preview';
import appConfig from '../../../../config/config';
import Image from '../../../../Shared/Components/Image';
import { IC_CHECK_WHITE2 } from '../../../../Assets';
import { formatDate } from '../../../../Shared/Utilities';
import classNames from 'classnames';

export type ProjectStatusList = Array<{
	status: DataCollectionStatusEnum;
	label: string;
	date: string | null;
}>;

type Props = {
	statusList: ProjectStatusList;
	currentStatus: DataCollectionStatusEnum;
};

const Style = css({
	label: 'ProjectStatus',
	display: 'flex',
	gap: '2.8rem',
	padding: '0.8rem 1.2rem',
	background: appConfig.style.colors.background2,
	overflow: 'hidden',
	'&__status-container': {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
		'.name': {
			color: appConfig.style.colors.cancel,
			fontWeight: 400,
		},
		'.date': {
			color: appConfig.style.colors.table,
			fontWeight: 400,
			fontSize: '1.1rem',
			height: '3rem',
		},
		'.status-circle': {
			width: '2.5rem',
			height: '2.5rem',
			borderRadius: '50%',
			background: '#ffffff',
			position: 'relative',
			zIndex: 1,
			border: `2px solid ${appConfig.style.colors.text4}`,
		},
		'&:first-child, &:last-child': {
			'.status-circle::after': {
				content: '""',
				position: 'absolute',
				height: 1,
				zIndex: 0,
				background: '#ffffff',
				width: 99999,
				top: '50%',
				left: 'calc(100% + 2px)',
			},
		},
		'&:first-child': {
			'.status-circle::after': {
				background: '#ffffff',
			},
		},
		'&:last-child': {
			'.status-circle::after': {
				background: appConfig.style.colors.background2,
			},
		},
		'&.current': {
			'.name': {
				color: appConfig.style.colors.orange,
			},
			'.status-circle': {
				border: `2px solid ${appConfig.style.colors.orange}`,
			},
		},
		'&.completed': {
			'.name': {
				color: '#ffffff',
				fontWeight: 400,
			},
			'.status-circle': {
				background: appConfig.style.colors.color1,
				border: `2px solid ${appConfig.style.colors.color1}`,
				position: 'relative',
				img: {
					position: 'absolute',
					top: '50%',
					right: '50%',
					transform: 'translate(50%, -50%)',
				},
			},
		},
	},
});

const ProjectStatus = ({ currentStatus, statusList }: Props) => {
	return (
		<div className={Style}>
			{statusList.map((status) => (
				<div
					key={status.status}
					className={classNames(`${Style}__status-container`, { completed: status.status < currentStatus, current: status.status === currentStatus })}
				>
					<span className="name">{status.label}</span>
					<span className="date">
						{status.status < currentStatus ? formatDate(status.date) : status.status === currentStatus ? 'In process' : ''}
					</span>
					<span className="status-circle">{status.status < currentStatus && <Image src={IC_CHECK_WHITE2} width="1.4rem" />}</span>
				</div>
			))}
		</div>
	);
};

export default ProjectStatus;
