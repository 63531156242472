import { Collapse } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { IC_EYE_DARK } from '../../../../../../Assets';
import { NumberOption } from '../../../../../../Models/API/All/NumberOption';
import { ProjectsUserInfo } from '../../../../../../Models/API/CapTable';
import { DataCollectionFieldTypeEnum } from '../../../../../../Models/API/DataCollection/comment-response';
import TextInput from '../../../../../../Shared/Components/Input/TextInput';
import RadioButton from '../../../../../../Shared/Components/RadioButton/RadioButton';
import Select from '../../../../../../Shared/Components/Select/Select';
import Spinner from '../../../../../../Shared/Components/Spinner/Spinner';
import useGeneralModal from '../../../../../../Shared/Hooks/useGeneralModal';
import useModal from '../../../../../../Shared/Hooks/useModal';
import { ForwardedRef } from '../../../../../../Shared/Hooks/useMultiStepForm';
import useRootStore from '../../../../../../Shared/Hooks/useRootStore';
import { formatDate, isNumber } from '../../../../../../Shared/Utilities';
import CapTableExcelImport from '../../../../../CapTable/Components/Import';
import MainCapTable from '../../../../../CapTable/Components/MainCapTable/MainCapTable';
import { generateCapTableData } from '../../../../../CapTable/utils/CapTableDataGenerator';
import { WaterfallStep } from '../../../../../Waterfall/Components/AddEditWaterfall/index.style';
import { RefsType } from '../../../../types';
import Question from '../../../Question';
import importCapTableStyle from './index.style';
import { validateDataCollectionBeforeSubmit } from '../../../../helpers/utils';
import { Routes } from '../../../../../../Routes';
import { useHistory } from 'react-router-dom';
import { FourONinePermissions } from '../../../../../../Models/API/UsersAndPermissions/permissions-enum';
import useEffectOnce from '../../../../../../Shared/Hooks/useEffectOnce';

const ImportCapTable = forwardRef<ForwardedRef, unknown>((_, forwardedRef) => {
	const { capTableStore, appState, dataCollectionStore } = useRootStore();
	const isDisabled = dataCollectionStore.currentRole !== FourONinePermissions.Admin || dataCollectionStore.isProjectDisabled;
	const { showModal, showAsyncModal } = useModal();
	const [projects, setProjects] = useState<ProjectsUserInfo[] | null>();
	const [selectBy, setSelectBy] = useState<'published' | 'import' | 'upload'>();
	const questionsRef = useRef<RefsType>({} as RefsType);
	const history = useHistory();
	const publishedCapTable = projects?.find((project) => project.isPublished);

	const fetchCapTableProjects = async () => {
		if (dataCollectionStore.currentRole !== FourONinePermissions.Admin && dataCollectionStore.dataCollection.capTableId) {
			const res = await capTableStore.loadUserProject(dataCollectionStore.dataCollection.capTableId);
			if (!res.data) return [];
			const newProjects = [res.data];
			setProjects(newProjects);
			return newProjects;
		}

		const res = await capTableStore.loadUserProjects();
		setProjects(res.data.projectsUserList);
		return res.data.projectsUserList;
	};

	const setPublishedCapTable = (published: ProjectsUserInfo) => {
		if (dataCollectionStore.dataCollection.capTableId === published.projectID) return;
		dataCollectionStore.setDataCollection('capTableId', published.projectID ?? null, true);
		setSelectBy('published');
	};

	useImperativeHandle(forwardedRef, () => ({
		onValidate: async () => {
			// cannot go to step 3 if not selected 1 thing beside valuation date
			if (dataCollectionStore.dataCollectionProjectId === null) return false;
			const [firstStepValidation, secondStepValidation] = validateDataCollectionBeforeSubmit(dataCollectionStore.dataCollection);

			if (!firstStepValidation || !secondStepValidation) {
				const isConfirmed = await showAsyncModal({
					type: 'warning',
					title: 'Please note',
					body: () => (
						<>
							<div>
								It looks like you haven't filled out all the required fields in {!firstStepValidation ? 'step 1' : ''}{' '}
								{!secondStepValidation ? (!firstStepValidation ? ' and step 2' : 'step 2') : ''}.
							</div>
							<div>
								You can <span className="bold">submit an incomplete form</span> & altshare's valuation team will return to you with comments.
							</div>
						</>
					),
					width: '64rem',
					onConfirm: async () => {},
					confirmButton: {
						label: 'Submit',
					},
					cancelButton: {
						label: 'Cancel',
					},
				});

				if (isConfirmed) {
					const res = await dataCollectionStore.submitDataCollection(dataCollectionStore.dataCollectionProjectId);
					if (res.errorMessage) {
						showModal({
							type: 'error',
							title: 'Submit for review failed',
							body: res.error?.data.validationErrors?.join(', ') || res.errorMessage,
						});
						return false;
					}
					history.push(Routes.dataCollection.index);
					return true;
				}
			}

			// Assuming DataCollectionFieldTypeEnum is an enum and `RefsType` uses it as keys
			const promises = Object.keys(questionsRef.current || []).map((key) => {
				const typedKey = key as unknown as keyof RefsType;

				const comment = questionsRef.current?.[typedKey].getData();
				if (!comment || !dataCollectionStore.valuationProjectId) return;

				return dataCollectionStore.addComment({
					fieldType: +typedKey,
					commentDescription: comment,
					valuationProjectId: dataCollectionStore.valuationProjectId,
				});
			});

			await Promise.all(promises);

			return true;
		},
	}));

	useEffectOnce(() => {
		(async () => {
			const projects = await fetchCapTableProjects();
			const publishedCapTable = projects?.find((project) => project.isPublished);
			if (publishedCapTable && dataCollectionStore.dataCollection.capTableId === null) setPublishedCapTable(publishedCapTable);

			const selectedProject = projects?.find((project) => project.projectID === dataCollectionStore.dataCollection.capTableId);
			if (!selectedProject) return;
			setSelectBy(selectedProject.isPublished ? 'published' : 'import');
		})();
	}, [dataCollectionStore.dataCollection.capTableId]);

	const projectsMemo: NumberOption[] = useMemo(
		() =>
			(projects || [])?.reduce((acc, project) => {
				if (!project.isPublished) {
					acc.push({ value: project.projectID, label: project.projectName });
				}
				return acc;
			}, [] as NumberOption[]),
		[projects]
	);

	const selectedCapTable = useMemo(() => {
		return projects?.find((project) => project.projectID === dataCollectionStore.dataCollection.capTableId);
	}, [dataCollectionStore.dataCollection.capTableId, projects]);

	if (projects === undefined || dataCollectionStore.dataCollection === undefined) {
		return (
			<WaterfallStep>
				<Spinner center incorporated />
			</WaterfallStep>
		);
	}

	const onShowImportCapTable = () => {
		showModal({
			body: (
				<CapTableExcelImport
					onSubmit={async (projectId) => {
						dataCollectionStore.setDataCollection('capTableId', projectId, true);
						await fetchCapTableProjects();
						setSelectBy('upload');
					}}
				/>
			),
			maximize: true,
		});
	};

	const clearProjectId = () => isNumber(dataCollectionStore.dataCollection.capTableId) && dataCollectionStore.setDataCollection('capTableId', null, true);

	const showCapTable = async () => {
		if (!dataCollectionStore.dataCollection.valuationDate || dataCollectionStore.dataCollection.capTableId === null) return;

		appState.isLoading = true;
		const res = await capTableStore.getProject(dataCollectionStore.dataCollection.capTableId, dataCollectionStore.dataCollection.valuationDate);
		appState.isLoading = false;

		const capTableData = generateCapTableData({
			personsList: res.data.personsList ?? [],
			shareClassHolderList: res.data.shareClassHolderList || [],
			shareClasses: res.data.shareClassesList?.map((sc) => sc.shareClass) || [],
			optionsData: res.data.options,
			safeTransactions: res.data.safeTransactionList,
		});

		showModal({
			body: <MainCapTable data={capTableData} isSearchDisabled={true} />,
			width: '100%',
		});
	};

	const PreviewCapTableElement = (
		<TextInput
			qaid=""
			value={selectedCapTable?.projectName}
			endIcon={{ src: IC_EYE_DARK, onClick: showCapTable }}
			disabled
			isTotal
			containerStyle={{ marginTop: '2rem', maxWidth: '28rem' }}
		/>
	);

	return (
		<WaterfallStep className={importCapTableStyle} small>
			<div className={`${importCapTableStyle}__header`}>
				<span className="bold">Import cap table</span> (as of {formatDate(dataCollectionStore.dataCollection.valuationDate)})
			</div>
			<Question
				fieldType={DataCollectionFieldTypeEnum.CapTable}
				title="Please select where to import cap table from."
				headeredComment
				ref={(el) => {
					if (questionsRef.current && el) {
						questionsRef.current[DataCollectionFieldTypeEnum.CapTable] = el;
					}
				}}
				renderActions={
					<>
						<div className={`${importCapTableStyle}__radios`}>
							<div className="flex flex-column">
								<RadioButton
									qaid=""
									name="capTableId"
									value="published"
									label={
										publishedCapTable
											? `There is a published cap table as of ${
													formatDate(dataCollectionStore.dataCollection.valuationDate) || '<valuation date>'
											  }`
											: 'There is no published cap table'
									}
									disabled={!publishedCapTable || isDisabled}
									checked={selectBy === 'published'}
									onChange={() => publishedCapTable && setPublishedCapTable(publishedCapTable)}
								/>
								{selectBy === 'published' && PreviewCapTableElement}
							</div>
							<div className="flex flex-column">
								<RadioButton
									qaid=""
									name="capTableId"
									value="import"
									label="Import cap table from drafts"
									onChange={(value) => {
										selectBy !== 'import' && clearProjectId();
										setSelectBy(value);
									}}
									checked={selectBy === 'import'}
									disabled={isDisabled}
								/>
								<Collapse in={selectBy === 'import'} unmountOnExit timeout={100}>
									<Select
										qaid=""
										value={dataCollectionStore.dataCollection.capTableId ?? undefined}
										name="capTableId"
										options={projectsMemo}
										endIcon={dataCollectionStore.dataCollection.capTableId ? { src: IC_EYE_DARK, onClick: showCapTable } : undefined}
										onChange={(capTableId) => isNumber(capTableId) && dataCollectionStore.setDataCollection('capTableId', capTableId, true)}
										defaultValue="Select draft..."
										width="23.5rem"
										style={{ marginTop: '2rem', maxWidth: '28rem' }}
										disabled={isDisabled}
									/>
								</Collapse>
							</div>
							<div className="flex flex-column">
								<RadioButton
									qaid=""
									name="capTableId"
									value="upload"
									label="Upload cap table from excel"
									checked={selectBy === 'upload'}
									onChange={() => {
										onShowImportCapTable();
									}}
									disabled={isDisabled}
								/>
								{selectBy === 'upload' && PreviewCapTableElement}
							</div>
						</div>
					</>
				}
			/>
		</WaterfallStep>
	);
});

export default observer(ImportCapTable);
