import { EffectCallback, useEffect, useState } from 'react';
import { isBoolean } from '../Utilities';

function useEffectOnce(callback: EffectCallback, deps: any[] = [], condition?: boolean) {
	const [isOccured, setIsOccurred] = useState<boolean>(false);

	useEffect(() => {
		if (isOccured || (isBoolean(condition) && !condition)) return;
		callback();
		setIsOccurred(true);
	}, deps);
}

export default useEffectOnce;
