import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CapTablePermission } from '../../../../Models/API/UsersAndPermissions/permissions-enum';
import { WaterfallProject } from '../../../../Models/API/Waterfall/IForm';
import { Routes } from '../../../../Routes';
import Flex from '../../Layout/Flex';
import CustomSelect from '../../Select/CustomSelect';
import useModal from '../../../Hooks/useModal';
import useRootStore from '../../../Hooks/useRootStore';
import VersionsDropDown from './VersionsDropDown';
import MainMenuStyle from './index.style';
import appConfig from '../../../../config/config';
import useGeneralModal from '../../../Hooks/useGeneralModal';

interface Props {
	onCancel: (waterfallId?: number) => void;
	onOpen: (waterfall: WaterfallProject, step?: number) => void;
	onDelete: (waterfallId: number) => Promise<string | undefined>;
	projects?: WaterfallProject[] | null;
}

const MainMenu = ({ onOpen, onDelete, projects, onCancel }: Props) => {
	const {
		auth: { permissions },
	} = useRootStore();
	const history = useHistory();
	const { showModal } = useModal();
	const { showErrorModal } = useGeneralModal();
	const [showVersionsDialog, setShowVersionsDialog] = useState<boolean>(false);

	const onDeleteHandler = async (waterfallId: number, name: string) => {
		showModal({
			type: 'confirm',
			body: () => <div>Are you sure that you want to delete the project "{name}"?</div>,
			confirmButton: {
				label: 'general.delete',
			},
			title: 'general.warning',
			onConfirm: async () => {
				const res = await onDelete(waterfallId);
				if (res) showErrorModal(res);
			},
		});
	};

	return (
		<>
			<div className={classNames(`${MainMenuStyle}__backdrop`, { show: showVersionsDialog })}></div>
			<div className={`${MainMenuStyle}__menu`}>
				<Flex className={`${MainMenuStyle}__menu-side-a`} align="center" justify="start" gap="2.6rem">
					<CustomSelect
						qaid="CapTable.Select.Version"
						onClick={() => setShowVersionsDialog((state) => !state)}
						className={`${MainMenuStyle}__version-select`}
						isOpen={showVersionsDialog}
					>
						<Flex flex={0} align="center" justify="start" gap="0.4rem">
							<div style={{ color: appConfig.style.colors.text2 }}>Select analysis project</div>
							{/* <span className="draft">(draft)</span> */}
						</Flex>
					</CustomSelect>

					{showVersionsDialog && (
						<VersionsDropDown
							projects={projects}
							onHide={() => setShowVersionsDialog(false)}
							onDelete={onDeleteHandler}
							onLoad={onOpen}
							onCancel={onCancel}
							isViewMode={permissions?.capTablePermission === CapTablePermission.full_access}
						/>
					)}
				</Flex>
			</div>
		</>
	);
};

export default observer(MainMenu);
