import { ActionType, EntityType } from "./General";
import { PoolStepType } from "./equityEnums";

export interface CompanySite {
	key: number;
	value: string;
	companyId: number;
}

export interface CompanyDepartment {
	key: number;
	value: string;
	companyId: number;
	departmentType: number;
}

export interface EquityPlan {
	planId: number;
	name: string;
	planApprovalDateByBoard: Date;
	itaApprovalRequestDate: Date | string;
	itaAprovalReceivedDate: Date | string;
	allowSop: boolean;
	allowRsu: boolean;
	allowRsa: boolean;
	allowSarq: boolean;
	allowNet: boolean;
	planExpirationDate: Date | string;
	status: string;
	documentId: number;
	companyId: number;
	allowBspce: boolean;
	allowAga: boolean;
}

export interface IPlanVest {
	companyId: number;
	vestingId: number;
	isFixed: boolean;
	planId: number;
	vestingCompanyId: number;
}

export interface IPoolParams {
	companyId?: number;
	planId?: number;
	name?: string;
	amount?: number;
	date?: Date | string;
	boardApprovalDate?: Date | string;
	plans?: number[];
	stepType?: number;
	// 0 = InitialtAllocation
}

export interface IEquityPlan {
	name: string;
	planApprovalDateByBoard: Date | string;
	allowRsu: boolean;
	allowSop: boolean;
	allowBspce: boolean;
	allowAga: boolean;
	vestings: IPlanVest[];
	isCancellationsReturnToPool: boolean;
	cancellationsReturnToAnotherPool?: number;
	poolParams?: IPoolParams;
}

export type IEquityPlanTemp = {
	plan: IEquityPlan;
	poolSteps: IPoolStep[];
};

export interface IPool {
	planId: number;
	name: string;
	amount: number;
	date: Date | string;
	boardApprovalDate: Date | string;
	plans: [number];
	stepType: number;
}

export interface IPoolStep {
	amount: number;
	stepDate: Date;
	stepType: PoolStepType;
}

export interface ICompanyDetails {
	companyId: number;
	keyCompany: string;
	whoSignsFirst: number;
	autoGenerateGrantNumber: boolean;
	legalEntity: CompanyCustomData[];
	sites: CompanyCustomData[];
	departments: CompanyCustomData[];
	taxTracks: CompanyCustomData[];
	subDepartments: CompanyCustomData[];
	vestings: IVestingSchedule[];
	plans: EquityPlan[];
}

export interface CompanyCustomData {
	key: number;
	value: string;
	companyId?: number;
	date: Date | string;
}

export interface CompanyCustomDataCreate extends CompanyCustomData {
	endDate?: Date | string;
}

export interface ILog {
	logId: number;
	companyId: number;
	createdBy: number;
	createdOn: Date | string;
	entityType: EntityType;
	actionType: ActionType;
	entityName: string;
	actionName: string;
	oldData: string;
	newData: string;
	isSucceeded: boolean;
	requestData: string;
}






//  **** Enums *****

export enum DateType {
	Year,
	Month,
	Day,
	Quarter,
	Exact,
}

export interface IVestingSchedule {
	vestingId: number;
	vestingName: string;
	roundingMethod: RoundingFormat;
	// reminderPlacement: number;
	vestingFormat: VestingFormat;
	description: string;
	planId: number;
	active: boolean;
	companyId: number;
	isManual: boolean;
}

export interface IVestStep {
	id?: string;
	stepIndex?: number;
	percent: number;
	amount: number;
	dateType: DateType;
	vestingStepType: VestingStepType;
}

export interface IManualVestingSchedule extends IVestingSchedule {
	vestingSteps: IVestStep[];
}

export enum VestingStepType {
	After,
	ProRata,
	Exact,
}

export enum RoundingFormat {
	Up,
	Down,
	Standard,
}

export enum VestingFormat {
	GroupRemindersplaceonfirst,
	GroupRemindersplaceonLast,
	StandardGiveWholeShare,
	SplitReminderUpForntVestings,
}

// errors: {
//     errors: [
//         {
//             isValid: boolean;
//             errorMessege: string
//         }
//     ]
// }
