import styled from '@emotion/styled';
import appConfig from '../../config/config';

const Container = styled.div(
	{
		background: '#ffffff',
		boxShadow: '3px 3px 16px 0px #ABAFC661',
		padding: '2rem 1.2rem',
		paddingBottom: 0,
		borderRadius: 16,
		width: '100%',
	},
	() => ({})
);
const Row = styled.div(
	{
		display: 'flex',
		margin: '0 1.4rem',
		gap: '2.8rem',
	},
	({ isHeader, isChild, isParent }: { isHeader?: boolean; isChild?: boolean; isParent?: boolean }) => ({
		fontWeight: isHeader ? 500 : 400,
		padding: isHeader ? '1.6rem 0' : '1.94rem 0',
		...(isChild
			? {
					background: appConfig.style.colors.lightBg,
					'> div:first-of-type': {
						color: appConfig.style.colors.text4,
						paddingLeft: '1.94rem',
					},
			  }
			: {}),
		...(isParent ? {} : {}),
		...(!isChild && !isParent
			? {
					'&:not(:last-child)': {
						borderBottom: `${isHeader ? 3 : 1}px solid ${appConfig.style.colors.table}`,
					},
			  }
			: {}),
	})
);
const Cell = styled.div(
	{
		display: 'flex',
		alignItems: 'center',
		flex: 1,
		minWidth: '10rem',
		whiteSpace: 'nowrap',
		'&[data-col-type="status"]': {
			minWidth: '15rem',
		},
		'&[data-col-type="drafts"]': {
			minWidth: '15rem',
		},
		'&[data-col-type="model"]': {
			maxWidth: '6rem',
		},
		'&[data-col-type="final"], &[data-col-type="drafts"]': {
			img: {
				cursor: 'pointer',
			},
		},
		'&[data-col-type="valuator-name"]': {
			position: 'relative',
			userSelect: 'none',
		},
	},
	({ wide }: { wide?: boolean }) => ({
		...(wide ? { flex: '1 0 16rem' } : {}),
	})
);

const Scroll = styled.div({
	maxHeight: '29.7rem',
	overflowY: 'auto',
});

const ScrollableList = {
	Container,
	Scroll,
	Row,
	Cell,
};

export default ScrollableList;
